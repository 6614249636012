import {Component} from '@angular/core';

@Component({
    selector: 'rc-sign-in-actions',
    template: `
        <div class="actions">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./sign-in-actions.component.scss']
})
export class SignInActionsComponent {
    constructor() {}
}
