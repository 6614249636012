import {Route} from '@angular/router';

import {AccessDeniedComponent} from './components/access-denied/access-denied.component';
import {AgentDashboardComponent} from './components/amplify/agent-dashboard/agent-dashboard.component';
import {NewEnrollmentFormComponent} from './components/amplify/new-enrollment-form/new-enrollment-form.component';
import {AppComponent} from './components/app/app.component';
import {BootstrapComponent} from './components/app/bootstrap.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {ErrorComponent} from './components/error/error.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {RedirectComponent} from './components/sign-in/redirect/redirect.component';
import {SignInComponent} from './components/sign-in/sign-in.component';
import {SSOSignInComponent} from './components/sign-in/sso-sign-in.component';
import {SignOutComponent} from './components/sign-out/sign-out.component';
import {UploadsComponent} from './components/uploads/uploads/uploads.component';
import {UserComponent} from './components/user/user.component';
import {UsersComponent} from './components/users/users.component';
import {PERMISSIONS_ENUM} from './enum/permissions.enum';
import {CONNECT_ROUTES_ENUM} from './enum/routes.enum';
import {AuthGuard} from './guards/auth.guard';
import {PermissionGuard} from './guards/permission.guard';

export const connectLibRoutes: Route[] = [
    {
        path: '',
        component: BootstrapComponent,
        children: [
            {
                path: CONNECT_ROUTES_ENUM.error,
                pathMatch: 'full',
                component: ErrorComponent
            },
            {
                path: CONNECT_ROUTES_ENUM.notFound,
                pathMatch: 'full',
                component: NotFoundComponent
            },
            {
                path: CONNECT_ROUTES_ENUM.forbidden,
                pathMatch: 'full',
                component: AccessDeniedComponent
            },
            {
                path: '',
                component: AppComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: CONNECT_ROUTES_ENUM.dashboard,
                        component: DashboardComponent
                    },
                    {
                        path: CONNECT_ROUTES_ENUM.agentDashboard,
                        component: AgentDashboardComponent
                    },
                    {
                        path: CONNECT_ROUTES_ENUM.newEnrollment,
                        component: NewEnrollmentFormComponent
                    },
                    {
                        path: CONNECT_ROUTES_ENUM.users,
                        canActivate: [PermissionGuard],
                        component: UsersComponent,
                        data: {
                            permissionAndFeature: {
                                permission: PERMISSIONS_ENUM.readUserManagement
                            }
                        }
                    },
                    {
                        path: CONNECT_ROUTES_ENUM.addUser,
                        canActivate: [PermissionGuard],
                        component: UserComponent,
                        data: {
                            permissionAndFeature: {
                                permission: PERMISSIONS_ENUM.createUser
                            }
                        }
                    },
                    {
                        path: CONNECT_ROUTES_ENUM.editUser,
                        canActivate: [PermissionGuard],
                        component: UserComponent,
                        data: {
                            permissionAndFeature: {
                                permission: PERMISSIONS_ENUM.updateUser
                            }
                        }
                    },
                    {
                        path: CONNECT_ROUTES_ENUM.viewUser,
                        canActivate: [PermissionGuard],
                        component: UserComponent,
                        data: {
                            permissionAndFeature: {
                                permission: PERMISSIONS_ENUM.readUser
                            }
                        }
                    },
                    {
                        path: CONNECT_ROUTES_ENUM.uploads,
                        canActivate: [PermissionGuard],
                        component: UploadsComponent,
                        data: {
                            permissionAndFeature: {
                                permission: PERMISSIONS_ENUM.readUserManagement
                            }
                        }
                    }
                ]
            },
            {
                path: CONNECT_ROUTES_ENUM.auth0Login,
                pathMatch: 'full',
                component: RedirectComponent
            },
            {
                path: CONNECT_ROUTES_ENUM.signOut,
                pathMatch: 'full',
                component: SignOutComponent
            },
            {
                path: CONNECT_ROUTES_ENUM.wellcareSignIn,
                pathMatch: 'full',
                redirectTo: CONNECT_ROUTES_ENUM.signIn
            },
            {
                path: CONNECT_ROUTES_ENUM.signIn,
                component: SignInComponent
            },
            {
                path: CONNECT_ROUTES_ENUM.ssoSignIn,
                component: SSOSignInComponent
            },
            {
                path: CONNECT_ROUTES_ENUM.notFound,
                pathMatch: 'full',
                component: NotFoundComponent
            },
            {
                path: '**',
                redirectTo: CONNECT_ROUTES_ENUM.notFound
            }
        ]
    }
];
