import {Observable, Subscription} from 'rxjs';

import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ConfirmDialogService, IdleDialog} from '@clients/revel/confirm-dialog';
import {AuthProvider} from '@clients/shared/auth';
import {WINDOW} from '@clients/shared/utilities';
import {Idle} from '@ng-idle/core';

import {ConnectFacade} from '../../+state';
import {IAppState} from '../../+state/app/IAppState';
import {CONNECT_ROUTES_ENUM} from '../../enum/routes.enum';

@Component({
    selector: 'rc-app',
    template: `
        <rc-shell *ngIf="!displayProgressSpinner"></rc-shell>
        <rc-spinner
            class="example-margin"
            [color]="color"
            [mode]="spinnerMode"
            [value]="value"
            [backdropEnabled]="true"
            [positionGloballyCenter]="true"
            [displayProgressSpinner]="displayProgressSpinner"
        >
        </rc-spinner>
    `,
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends IdleDialog implements OnInit, OnDestroy {
    public color = 'primary';
    public spinnerMode = 'indeterminate';
    public value = 50;
    public displayProgressSpinner = false;
    public $appData: Observable<IAppState>;
    public appDataSubscription: Subscription;

    constructor(
        private connectFacade: ConnectFacade,
        idle: Idle,
        confirmDialogService: ConfirmDialogService,
        authProvider: AuthProvider,
        @Inject(WINDOW) private window: Window
    ) {
        super(idle, confirmDialogService, authProvider);
        this.$appData = this.connectFacade.getAppData();
    }

    public initiateSignOut() {
        this.authProvider.revokeSession({
            returnTo: `${this.window.location.origin}/${CONNECT_ROUTES_ENUM.signIn}`
        });
        return;
    }

    ngOnInit() {
        this.appDataSubscription = this.$appData.subscribe((appData) => {
            this.displayProgressSpinner = appData.isLoading;
        });

        if (!this.connectFacade.getCurrentProfile().isLoaded) {
            this.connectFacade.loadProfile();
        }
        this.connectFacade.setTitle({title: 'Revel Connect'});
    }

    public ngOnDestroy() {
        this.appDataSubscription.unsubscribe();
    }
}
