import {Component, OnInit} from '@angular/core';

import {ConnectFacade} from '../../../+state';
import {IUploadSummary} from '../../../models/upload';
import {ConnectApiService} from '../../../services/connect-api.service';
import {UploadDataSource} from './upload-data-source';

@Component({
    selector: 'rc-uploads',
    template: `
        <div *ngIf="dataSource.error$ | async" class="upload-errors" data-e2e="upload-errors">
            Oops! Something when wrong. Please try again.
        </div>
        <rc-spinner
            [mode]="'indeterminate'"
            [value]="50"
            [backdropEnabled]="true"
            [positionGloballyCenter]="true"
            [displayProgressSpinner]="dataSource.loading$ | async"
        ></rc-spinner>
        <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>File name </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div class="name">{{ row.fileName }}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="operation">
                <mat-header-cell *matHeaderCellDef>Upload Type</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    {{ row.operation }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                <mat-cell *matCellDef="let row"> <rc-upload-status [upload]="row"></rc-upload-status> </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef> Uploaded Date </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.createdOn | date : 'short' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="details">
                <mat-header-cell *matHeaderCellDef>Upload Size</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    {{ row.jobSize }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <rc-upload-details-dialog
                        [uploadId]="row.jobId"
                        [operation]="row.operation"
                        [fileName]="row.fileName"
                        >View</rc-upload-details-dialog
                    >
                </mat-cell>
            </ng-container>

            <mat-header-row
                *matHeaderRowDef="['name', 'operation', 'status', 'date', 'details', 'action']"
            ></mat-header-row>
            <mat-row
                *matRowDef="let row; columns: ['name', 'operation', 'status', 'date', 'details', 'action']"
            ></mat-row>
        </mat-table>
    `,
    styleUrls: ['./uploads.component.scss']
})
export class UploadsComponent implements OnInit {
    public dataSource: UploadDataSource;

    constructor(private facade: ConnectFacade, private connectApi: ConnectApiService) {}

    ngOnInit() {
        this.facade.setTitle({title: 'CSV Uploads', secondaryTitle: ''});
        const companyId = this.facade.getCurrentCompany().companyId;

        this.dataSource = new UploadDataSource(this.connectApi);
        this.dataSource.loadUploads(companyId);
    }

    public getStatus(upload: IUploadSummary) {
        //calculate Status
        return upload.status;
    }
}
