import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class TokenParserService {
    private readonly companyIdClaim = 'http://revel/companyId';
    private readonly userIdClaim = 'sub';
    private readonly firstName = 'http://revel/firstName';
    private readonly lastName = 'http://revel/lastName';
    private readonly rolesClaim = 'http://revel/roles';
    private readonly permissionsClaim = 'permissions';
    private readonly jwtHelper: JwtHelperService;
    constructor() {
        this.jwtHelper = new JwtHelperService();
    }

    public getCompanyId(tokenString: string): string {
        const token = this.jwtHelper.decodeToken(tokenString);
        return token[this.companyIdClaim];
    }

    public getUserId(tokenString: string): string {
        const token = this.jwtHelper.decodeToken(tokenString);
        return token[this.userIdClaim];
    }

    public getFirstName(tokenString: string): string {
        const token = this.jwtHelper.decodeToken(tokenString);
        return token[this.firstName];
    }

    public getLastName(tokenString: string): string {
        const token = this.jwtHelper.decodeToken(tokenString);
        return token[this.lastName];
    }

    public getPermissions(tokenString: string): string[] {
        const token = this.jwtHelper.decodeToken(tokenString);
        return token[this.permissionsClaim];
    }

    public getRoles(tokenString: string): string[] {
        const token = this.jwtHelper.decodeToken(tokenString);
        return token[this.rolesClaim];
    }
}
