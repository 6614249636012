import {BehaviorSubject, interval, Observable} from 'rxjs';
import {filter, switchMap, takeWhile, tap} from 'rxjs/operators';

import {Injectable, OnDestroy} from '@angular/core';

import {ExportJobStatus, IExportJobResponse} from '../models/export';
import {ConnectApiService} from './connect-api.service';

@Injectable({
    providedIn: 'root'
})
export class UserExportService implements OnDestroy {
    public errorMessage = '';
    public initMessage = 'User export initiated.';
    public progressMessage = 'User export in progress.';
    public completeMessage = 'User export process complete.';
    private userProcessingSubject = new BehaviorSubject<string>('');
    private errorSubject = new BehaviorSubject<boolean>(false);
    private pollingInterval = 1000;
    public userProcessingMessage$ = this.userProcessingSubject.asObservable(); //eslint-disable-line
    public error$ = this.errorSubject.asObservable(); //eslint-disable-line

    constructor(private connectApi: ConnectApiService) {}

    public startUserExport(companyId: string): Observable<IExportJobResponse> {
        this.userProcessingSubject.next(this.initMessage);

        return this.connectApi.startUserExport(companyId);
    }

    public getExportedUsers(jobId: string, companyId: string): Observable<Blob> {
        let isProcessing = true;
        return interval(this.pollingInterval).pipe(
            takeWhile(() => isProcessing),
            switchMap(() => this.connectApi.getUserExportJobStatus(jobId)),
            tap(() => this.userProcessingSubject.next(this.progressMessage)),
            filter((response: IExportJobResponse) => response.status === ExportJobStatus.completed),
            tap(() => (isProcessing = false)),
            switchMap((response: IExportJobResponse) => this.connectApi.getExportedUsers(response.location, companyId)),
            tap(() => this.userProcessingSubject.next(this.completeMessage))
        );
    }

    public addError(errorMessage: string) {
        this.errorSubject.next(true);
        this.errorMessage = errorMessage;
    }

    public closeUserProcessingError() {
        this.errorMessage = '';
        this.errorSubject.next(false);
        this.userProcessingSubject.next('');
    }

    public ngOnDestroy(): void {
        this.errorSubject.complete();
        this.userProcessingSubject.complete();
    }
}
