import {switchMap, take} from 'rxjs/operators';

import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {UntilComponentDestroyed} from '@clients/shared/common-forms';

import {IExportJobResponse} from '../../../models/export';
import {FileExportService} from '../../../services/file-export.service';
import {UserExportService} from '../../../services/user-export.service';

@Component({
    selector: 'rc-export-button',
    template: `
        <button type="button" mat-button color="primary" (click)="exportUsers()" data-e2e="export-users">
            Export Users
        </button>
    `,
    styleUrls: ['./export-button.component.scss']
})
export class ExportButtonComponent extends UntilComponentDestroyed {
    @Input()
    public userExportService: UserExportService;

    @Input()
    public companyId: string;

    @ViewChild('fileInput') fileInput: ElementRef;

    public errorMessage = 'An error occurred while processing the export.';

    constructor(public fileExportService: FileExportService) {
        super();
    }

    public exportUsers() {
        const fileName = `users_${this.fileExportService.buildExportFileName()}`;
        this.userExportService
            .startUserExport(this.companyId)
            .pipe(
                take(1),
                switchMap((response: IExportJobResponse) =>
                    this.userExportService.getExportedUsers(response.id, this.companyId)
                )
            )
            .subscribe(
                (userResponse: any) => {
                    this.fileExportService.generateExportFile(userResponse, fileName);
                },
                () => {
                    this.userExportService.addError(this.errorMessage);
                }
            );
    }
}
