import {ICompany, ICompanyDetails} from '@clients/connect/connect-common';

export interface IProfileState {
    isLoaded: boolean;
    companyId: string;
    userId: string;
    roles: string[];
    currentCompany: ICompanyDetails;
    companies: ICompany[];
    permissions: string[];
}

export const initialProfileState: IProfileState = {
    isLoaded: false,
    companyId: '',
    userId: '',
    currentCompany: {
        companyId: '',
        data: {
            name: '',
            features: {}
        }
    },
    roles: [],
    companies: [],
    permissions: []
};
