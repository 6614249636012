/*istanbul ignore file*/
export enum CONNECT_ROUTES_ENUM {
    home = '',
    dashboard = 'dashboard',
    auth0Login = 'login',
    signIn = 'sign-in',
    ssoSignIn = 'sign-in/:connection',
    signOut = 'sign-out',
    wellcareSignIn = 'wchra',
    agentDashboard = 'amplify',
    newEnrollment = 'amplify/new-enrollment',
    notFound = '404',
    forbidden = '403',
    error = 'error',
    users = 'users',
    addUser = 'user/add',
    editUser = 'user/:userId/edit',
    viewUser = 'user/:userId/view',
    uploads = 'uploads'
}
