import {Injectable} from '@angular/core';
import {PersistenceProvider} from './persistence.provider';

@Injectable()
export class LocalStorageService extends PersistenceProvider {
    readonly localStorageAvailable: boolean;
    private data: any = {};

    constructor() {
        super();

        try {
            localStorage.setItem('__test', 'true');
            this.localStorageAvailable = true;
        } catch (e) {
            this.localStorageAvailable = false;
        }
    }

    public get(key: string): any {
        if (this.localStorageAvailable) {
            return localStorage.getItem(key);
        }

        return this.data[key];
    }

    public set(key: string, value: any): void {
        if (this.localStorageAvailable) {
            localStorage.setItem(key, value);
        } else {
            this.data[key] = value;
        }
    }

    public remove(key: string): void {
        if (this.localStorageAvailable) {
            localStorage.removeItem(key);
        } else {
            delete this.data[key];
        }
    }

    public removeAll(): void {
        if (this.localStorageAvailable) {
            localStorage.clear();
        } else {
            this.data = {};
        }
    }
}
