import {Injectable} from '@angular/core';

import {
    IAuth0Env,
    IGoogleConfig,
    ILoggingConfig,
    // INewRelicConfig,
    ISumoConfig
} from './models/revel-env';

@Injectable({
    providedIn: 'root'
})
export class EnvService {
    // The values that are defined here are the default values that can
    // be overridden by env.js
    public rev_ver: string;
    public env: string;
    public production: boolean;
    public googleConfig?: IGoogleConfig;
    public logging: ILoggingConfig;
    public sumo: ISumoConfig;
    // public newRelic: INewRelicConfig;
    public auth0: IAuth0Env;
    public idvServiceApi: string;
}

export function getEnv() {
    const browserWindow = window || {};
    const browserWindowEnv = browserWindow['__env'] || {};
    return browserWindowEnv;
}
