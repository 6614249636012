import {Component, OnInit} from '@angular/core';
import {ConnectFacade} from '../../+state/connect.facade';

@Component({
    selector: 'rc-bootstrap',
    template: ` <router-outlet></router-outlet> `,
    styles: []
})
export class BootstrapComponent implements OnInit {
    constructor(private connectFacade: ConnectFacade) {}

    ngOnInit() {
        this.connectFacade.enableTracking();
        this.connectFacade.loadConnections();
    }
}
