import {TextMaskConfig} from 'angular2-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

const DASH = '-';
const DIGIT = /\d/;
const UNDERSCORE = '_';
const SPACE = ' ';

const C = /[1-9]/;
const N = /[0-9]/;
const A = /[acdefghjkmnpqrtuvwxyACDEFGHJKMNPQRTUVWXY]/;
const AN = /([acdefghjkmnpqrtuvwxyACDEFGHJKMNPQRTUVWXY]|[0-9])/;

const dateMask: TextMaskConfig = {
    guide: true,
    keepCharPositions: true,
    mask: [DIGIT, DIGIT, DASH, DIGIT, DIGIT, DASH, DIGIT, DIGIT, DIGIT, DIGIT],
    pipe: createAutoCorrectedDatePipe('mm-dd-yyyy'),
    placeholderChar: UNDERSCORE
};

const phoneMask: TextMaskConfig = {
    guide: true,
    keepCharPositions: true,
    mask: [DIGIT, DIGIT, DIGIT, DASH, DIGIT, DIGIT, DIGIT, DASH, DIGIT, DIGIT, DIGIT, DIGIT],
    placeholderChar: UNDERSCORE
};

//https://www.cms.gov/medicare/new-medicare-card/understanding-the-mbi.pdf
const mbiMask: TextMaskConfig = {
    mask: [C, A, AN, N, DASH, A, AN, N, DASH, A, A, N, N],
    placeholderChar: UNDERSCORE
};

const leanMaskfunction = function (rawValue) {
    const userInput = rawValue.trim();
    const mask = [
        /[eEtT]/,
        DASH,
        DIGIT,
        DIGIT,
        DIGIT,
        DIGIT,
        DIGIT,
        DIGIT,
        DIGIT,
        DIGIT,
        DIGIT,
        DIGIT,
        DIGIT,
        DIGIT,
        DIGIT,
        DIGIT
    ];

    if (userInput.length > 15 && userInput) mask.push(DIGIT);
    if (userInput.length > 16) mask.push(DIGIT);
    if (userInput.length > 17) mask.push(DIGIT);
    if (userInput.length > 18) mask.push(DIGIT);
    if (userInput.length > 19) mask.push(DIGIT);
    if (userInput.length > 20) mask.push(DIGIT);
    if (userInput.length > 21) mask.push(DIGIT);
    if (userInput.length > 22) mask.push(DIGIT);
    if (userInput.length > 23) mask.push(DIGIT);

    return mask;
};

const leanMask: TextMaskConfig = {
    guide: true,
    keepCharPositions: true,
    mask: leanMaskfunction,
    placeholderChar: SPACE
};

export {dateMask, phoneMask, leanMask, mbiMask};
