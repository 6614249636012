/* eslint-disable @typescript-eslint/no-unused-vars */
import {Injectable} from '@angular/core';

import {AnalyticsProvider} from './analytics.provider';

@Injectable()
export class RevelAnalyticsService extends AnalyticsProvider {
    public static service = 'revel';

    constructor() {
        super();
    }

    public pageTrack(path: string): void {}

    public eventTrack(eventName: string, properties: any = null): void {}

    public setUsername(userId: string | number): void {}

    public setUserProperties(props: any): void {}

    public setSuperProperties(props: any): void {}
}
