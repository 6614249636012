import {Injectable} from '@angular/core';

@Injectable()
export abstract class AnalyticsProvider {
    protected constructor() {}

    public abstract pageTrack(path: string);

    public abstract eventTrack(eventName: string, properties: any);

    public abstract setUsername(userId: string | number);

    public abstract setUserProperties(properties: any);

    public abstract setSuperProperties(properties: any);
}
