import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, NG_VALIDATORS, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';

import {AddressComponent} from './components/address/address.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {BannerComponent} from './elements/banner/banner.component';
import {ButtonComponent} from './elements/button/button.component';
import {DropdownComponent} from './elements/dropdown/dropdown.component';
import {InputComponent} from './elements/input/input.component';
import {LabelComponent} from './elements/label/label.component';
import {MaskedInputComponent} from './elements/masked-input/masked-input.component';
import {MaskedTextAreaComponent} from './elements/masked-textarea/masked-textarea.component';
import {SvgIconComponent} from './elements/svg-icon/svg-icon.component';
import {TrustHtmlPipe} from './pipes/trust-html.pipe';
import {validatePhoneNumber} from './validators/valid-phone-number.validator';
import {validSsn} from './validators/valid-ssn.validator';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        BrowserAnimationsModule.withConfig({disableAnimations: true}),
        NgxMaskDirective,
        NgxMaskPipe
    ],
    declarations: [
        ButtonComponent,
        InputComponent,
        LabelComponent,
        DropdownComponent,
        MaskedInputComponent,
        MaskedTextAreaComponent,
        BannerComponent,
        TrustHtmlPipe,
        AddressComponent,
        SvgIconComponent,
        PageNotFoundComponent
    ],
    exports: [
        ButtonComponent,
        InputComponent,
        LabelComponent,
        DropdownComponent,
        MaskedInputComponent,
        MaskedTextAreaComponent,
        BannerComponent,
        TrustHtmlPipe,
        FormsModule,
        NgxMaskDirective,
        NgxMaskPipe,
        ReactiveFormsModule,
        AddressComponent,
        SvgIconComponent,
        BrowserAnimationsModule,
        PageNotFoundComponent
    ],
    providers: [
        provideNgxMask(),
        {provide: NG_VALIDATORS, useValue: validatePhoneNumber, multi: true},
        {provide: NG_VALIDATORS, useValue: validSsn, multi: true}
    ]
})
export class CommonFormsModule {}
