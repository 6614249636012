import moment from 'moment';

import {Injectable} from '@angular/core';
import {AuthProvider} from '@clients/shared/auth';

import {TokenParserService} from './token-parser.service';

@Injectable({
    providedIn: 'root'
})
export class FileExportService {
    constructor(private authProvider: AuthProvider, private tokenParser: TokenParserService) {}

    public buildExportFileName() {
        const authToken = this.authProvider.getToken();
        const firstName = this.tokenParser.getFirstName(authToken);
        const lastName = this.tokenParser.getLastName(authToken);
        const currentDateTime = this.buildLocalDateTime();

        if (firstName && lastName) {
            return `${firstName}_${lastName}_${currentDateTime}.csv`;
        } else {
            return `${currentDateTime}.csv`;
        }
    }

    public buildLocalDateTime() {
        return moment().format('MMDDYYYY_HHmmss');
    }

    public generateExportFile(response: any, fileName: string): string {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);

        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();

        return downloadLink.href;
    }
}
