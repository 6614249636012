import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {LoggingActionTypes, LogInfoAction} from './logging.actions';
import {map, tap} from 'rxjs/operators';
import {Logger} from '../services/logger.service';
import {LogPayload} from '../models/LogPayload.interface';
import {LogSeverity} from '../models/Log.type';

@Injectable()
export class LoggingEffects {
    logInfo = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LoggingActionTypes.LOG_INFO),
                map((action: LogInfoAction) => action.payload),
                tap((payload: LogPayload) => {
                    this.logger.log(payload.message, payload.options, LogSeverity.INFO, payload.type);
                })
            ),
        {dispatch: false}
    );

    logWarning = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LoggingActionTypes.LOG_WARNING),
                map((action: LogInfoAction) => action.payload),
                tap((payload: LogPayload) => {
                    this.logger.log(payload.message, payload.options, LogSeverity.WARNING, payload.type);
                })
            ),
        {dispatch: false}
    );

    logError = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LoggingActionTypes.LOG_ERROR),
                map((action: LogInfoAction) => action.payload),
                tap((payload: LogPayload) => {
                    this.logger.log(payload.message, payload.options, LogSeverity.ERROR, payload.type);
                })
            ),
        {dispatch: false}
    );

    constructor(private actions$: Actions, private logger: Logger) {}
}
