import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {RevelConfirmDialogModule} from '@clients/revel/confirm-dialog';
import {AnalyticsModule, GoogleAnalyticsService, RevelAnalyticsService} from '@clients/shared/analytics';
import {AuthModule} from '@clients/shared/auth';
import {LayoutGridModule} from '@clients/shared/layout-grid';
import {PERSISTENCE_LOCAL_STORAGE, PersistenceModule} from '@clients/shared/persistence';
import {WINDOW_PROVIDERS} from '@clients/shared/utilities';
import {NgIdleModule} from '@ng-idle/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';

import {
    AppEffects,
    ConnectAuthEffects,
    connectCombinedReducer,
    ConnectFacade,
    initialConnecteState,
    ProfileEffects
} from './+state';
import {AccessDeniedComponent} from './components/access-denied/access-denied.component';
import {AgentDashboardComponent} from './components/amplify/agent-dashboard/agent-dashboard.component';
import {DateRangePickerComponent} from './components/amplify/agent-dashboard/date-range-picker/date-range-picker.component';
import {ProspectsGridComponent} from './components/amplify/agent-dashboard/prospects-grid/prospects-grid.component';
import {NewEnrollmentFormComponent} from './components/amplify/new-enrollment-form/new-enrollment-form.component';
import {AppComponent} from './components/app/app.component';
import {BootstrapComponent} from './components/app/bootstrap.component';
import {SpinnerComponent} from './components/app/spinner/spinner.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {ErrorComponent} from './components/error/error.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {ApplyPermissionAndFeatureDirective} from './components/shared/permission/apply-permission-and-feature.directive';
import {CompanySelectComponent} from './components/shell/company-select/company-select.component';
import {ListItemComponent} from './components/shell/list-item/list-item.component';
import {LogoComponent} from './components/shell/logo/logo.component';
import {MenuComponent} from './components/shell/menu/menu.component';
import {ShellComponent} from './components/shell/shell.component';
import {ToggleButtonComponent} from './components/shell/toggle-button/toggle-button.component';
import {BackButtonComponent} from './components/sign-in/back-button/back-button.component';
import {ConnectionComponent} from './components/sign-in/connection/connection.component';
import {ForgotPasswordComponent} from './components/sign-in/forgot-password/forgot-password.component';
import {PasswordComponent} from './components/sign-in/password/password.component';
import {RedirectComponent} from './components/sign-in/redirect/redirect.component';
import {SignInActionsComponent} from './components/sign-in/sign-in-actions/sign-in-actions.component';
import {SignInErrorComponent} from './components/sign-in/sign-in-error/sign-in-error.component';
import {SignInComponent} from './components/sign-in/sign-in.component';
import {SSOSignInComponent} from './components/sign-in/sso-sign-in.component';
import {UsernameComponent} from './components/sign-in/username/username.component';
import {SignOutComponent} from './components/sign-out/sign-out.component';
import {UploadDetailsDialogComponent} from './components/uploads/upload-details-dialog/upload-details-dialog.component';
import {UploadDetailsComponent} from './components/uploads/upload-details/upload-details.component';
import {UploadStatusComponent} from './components/uploads/upload-status/upload-status.component';
import {UploadsComponent} from './components/uploads/uploads/uploads.component';
import {UserComponent} from './components/user/user.component';
import {ExportButtonComponent} from './components/users/export-button/export-button.component';
import {ImportButtonComponent} from './components/users/import-button/import-button.component';
import {DeleteButtonComponent} from './components/users/delete-button/delete-button.component';
import {ExportProgressComponent} from './components/users/progress/export-progress.component';
import {ImportProgressComponent} from './components/users/progress/import-progress.component';
import {DeleteProgressComponent} from './components/users/progress/delete-progress.component';
import {UserGridComponent} from './components/users/user-grid/user-grid.component';
import {UsersComponent} from './components/users/users.component';
import {connectLibRoutes} from './connect-lib.routes';
import {ConnectMaterialModule} from './connect-material.module';
import {ConnectApiService} from './services/connect-api.service';
import {FeatureService} from './services/feature.service';
import {OverlayService} from './services/overlay.service';
import {PermissionAndFeatureAccessService} from './services/permission-and-feature-access.service';
import {PermissionsService} from './services/permissions.service';
import {RedirectService} from './services/redirect.service';
import {TokenParserService} from './services/token-parser.service';
import {MaskedInputComponent} from '@clients/shared/common-forms';

@NgModule({
    imports: [
        AuthModule,
        CommonModule,
        RouterModule.forChild(connectLibRoutes),
        StoreModule.forFeature('connect', connectCombinedReducer, {initialState: initialConnecteState}),
        EffectsModule.forFeature([ConnectAuthEffects, ProfileEffects, AppEffects]),
        PersistenceModule,
        BrowserAnimationsModule.withConfig({disableAnimations: true}),
        BrowserModule,
        ConnectMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        LayoutGridModule,
        RevelConfirmDialogModule,
        NgIdleModule.forRoot(),
        AnalyticsModule.forRoot([GoogleAnalyticsService, RevelAnalyticsService], {
            developerMode: false
        })
    ],
    declarations: [
        SignInComponent,
        SSOSignInComponent,
        ShellComponent,
        AppComponent,
        ListItemComponent,
        LogoComponent,
        ToggleButtonComponent,
        MenuComponent,
        SignOutComponent,
        DashboardComponent,
        CompanySelectComponent,
        AgentDashboardComponent,
        DateRangePickerComponent,
        ProspectsGridComponent,
        NewEnrollmentFormComponent,
        UserComponent,
        BootstrapComponent,
        NotFoundComponent,
        ErrorComponent,
        RedirectComponent,
        SpinnerComponent,
        ForgotPasswordComponent,
        SignInActionsComponent,
        UsernameComponent,
        PasswordComponent,
        ConnectionComponent,
        SignInErrorComponent,
        BackButtonComponent,
        ApplyPermissionAndFeatureDirective,
        UsersComponent,
        AccessDeniedComponent,
        UserGridComponent,
        ImportButtonComponent,
        DeleteButtonComponent,
        ExportButtonComponent,
        ImportProgressComponent,
        DeleteProgressComponent,
        ExportProgressComponent,
        UploadsComponent,
        UploadDetailsComponent,
        UploadDetailsDialogComponent,
        UploadStatusComponent,
        NewEnrollmentFormComponent,
        BootstrapComponent
    ],
    providers: [
        PERSISTENCE_LOCAL_STORAGE,
        WINDOW_PROVIDERS,
        ConnectApiService,
        ConnectFacade,
        TokenParserService,
        PermissionsService,
        FeatureService,
        OverlayService,
        PermissionAndFeatureAccessService,
        RedirectService
    ],

    exports: [AppComponent, BootstrapComponent, RouterModule]
})
export class ConnectLibModule {}
