import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {UploadEventType, UploadOperation} from '../../../enum/uploads.enum';
import {IUploadDetails, IUploadEvent} from '../../../models/upload';
import {getErrors as getDeleteErrors, getWarnings as getDeleteWarnings} from '../../../services/delete-file-utility';
import {getErrors as getImportErrors, getWarnings as getImportWarnings} from '../../../services/import-file-utility';

@Component({
    selector: 'rc-upload-details',
    templateUrl: './upload-details.component.html',
    styleUrls: ['./upload-details.component.scss']
})
export class UploadDetailsComponent implements OnInit {
    public errors: IUploadEvent[];
    public warnings: IUploadEvent[];
    public errorAndWarningCount: number;
    constructor(@Inject(MAT_DIALOG_DATA) public uploadDetails: IUploadDetails) {}

    ngOnInit() {
        let warningsFunction: (events: IUploadEvent[]) => IUploadEvent[];
        let errorsFunction: (events: IUploadEvent[]) => IUploadEvent[];
        switch (this.uploadDetails.operation) {
            case UploadOperation.Delete:
                warningsFunction = getDeleteWarnings;
                errorsFunction = getDeleteErrors;
                break;
            case UploadOperation.Import:
                warningsFunction = getImportWarnings;
                errorsFunction = getImportErrors;
                break;
            default:
                this.errorAndWarningCount = 1;
                throw new Error(`Invalid operation value for upload details: ${this.uploadDetails.operation}`);
        }

        this.warnings = warningsFunction(this.uploadDetails.events);

        this.errors = errorsFunction(this.uploadDetails.events);

        this.errorAndWarningCount = this.errors.length + this.warnings.length;
    }

    public getMessage(event: IUploadEvent) {
        switch (event.type) {
            case UploadEventType.AlreadyExists:
                return `Warning: User ${event.email} already exists`;
            case UploadEventType.AlreadyExistsReactivated:
                return `Warning: User ${event.email} already exists, user reactivated`;
            case UploadEventType.AlreadyExistsWithDifferentData:
                return `Warning: User ${event.email} already exists with different data`;
            case UploadEventType.AlreadyExistsWithDifferentDataReactivated:
                return `Warning: User ${event.email} already exists with different data, user reactivated`;
            case UploadEventType.DoesNotExist:
                return `Warning: User ${event.email} does not exist`;
            case UploadEventType.ErrorAuth0:
                return `Error: An error ocurred while adding ${event.email}`;
            case UploadEventType.ErrorUnknown:
                return `Error: An unknown error occurred while processing ${event.email}`;
        }
    }
}
