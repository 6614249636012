import {some as _some} from 'lodash';

import {Injectable} from '@angular/core';
import {AuthProvider} from '@clients/shared/auth';

import {RolesEnum} from '../enum/roles.enum';
import {TokenParserService} from './token-parser.service';

@Injectable({
    providedIn: 'root'
})
export class FeatureService {
    constructor(private authProvider: AuthProvider, private tokenParser: TokenParserService) {}

    public isAgent() {
        if (this.authProvider.isAuthenticated()) {
            const token = this.authProvider.getToken();
            const roles = this.tokenParser.getRoles(token);

            return _some(roles, (r) => r === RolesEnum.liveAgent || r === RolesEnum.revelAgent);
        }
        return false;
    }
}
