import {Component, Input} from '@angular/core';

import {ISidenavContents, sidenavContents} from '../sidenavContents';

@Component({
    selector: 'rc-sn-menu',
    template: `
        <div fxLayout="column" fxFlex fxLayoutAlign="space-between">
            <div>
                <rc-sn-logo *ngIf="!isMobile" [showText]="expanded"></rc-sn-logo>
                <div *ngFor="let section of sidenavContent.topSections">
                    <mat-nav-list class="list">
                        <rc-sn-list-item
                            *ngFor="let item of section"
                            [showText]="expanded"
                            [item]="item"
                        ></rc-sn-list-item>
                    </mat-nav-list>
                    <mat-divider></mat-divider>
                </div>
                <rc-sn-toggle-button [expanded]="expanded"></rc-sn-toggle-button>
            </div>
            <div>
                <div *ngFor="let section of sidenavContent.bottomSections">
                    <mat-divider></mat-divider>
                    <mat-nav-list>
                        <rc-sn-list-item
                            *ngFor="let item of section"
                            [showText]="expanded"
                            [item]="item"
                        ></rc-sn-list-item>
                    </mat-nav-list>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
    @Input()
    public expanded = true;

    @Input()
    public isMobile = false;
    public sidenavContent: ISidenavContents = sidenavContents;
    constructor() {}
}
