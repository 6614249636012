import {animate, state, style, transition, trigger} from '@angular/animations';

export const sideNavToggle = trigger('sideNavToggle', [
    state(
        'full',
        style({
            width: '200px'
        })
    ),
    state(
        'mobilefull',
        style({
            width: '0px'
        })
    ),
    state(
        'mini',
        style({
            width: '57px'
        })
    ),
    state(
        'closed',
        style({
            width: '0px'
        })
    ),

    transition('mini => full', [animate('100ms ease-in')]),
    transition('full => mini', [animate('100ms ease-in')]),
    transition('full => closed', [animate('100ms ease-in')]),
    transition('closed => full', [animate('100ms ease-in')]),
    transition('mini => closed', [animate('100ms ease-in')]),
    transition('closed => mini', [animate('100ms ease-in')]),
    transition('mobileFull => closed', [animate('100ms ease-in')]),
    transition('closed => mobileFull', [animate('100ms ease-in')])
]);

export const mainContentToggle = trigger('mainContentToggle', [
    state(
        'full',
        style({
            'margin-left': '200px'
        })
    ),
    state(
        'mobileFull',
        style({
            'margin-left': '0px'
        })
    ),
    state(
        'mini',
        style({
            'margin-left': '57px'
        })
    ),
    state(
        'closed',
        style({
            'margin-left': '0px'
        })
    ),
    transition('mini => full', [animate('100ms ease-in')]),
    transition('full => mini', [animate('100ms ease-in')]),
    transition('full => closed', [animate('100ms ease-in')]),
    transition('closed => full', [animate('100ms ease-in')]),
    transition('mini => closed', [animate('100ms ease-in')]),
    transition('closed => mini', [animate('100ms ease-in')]),
    transition('mobileFull => closed', [animate('100ms ease-in')]),
    transition('closed => mobileFull', [animate('100ms ease-in')])
]);
