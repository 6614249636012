import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {FIRST_LAST_NAME_REGEX, IUser, IUserData, IUserDto} from '@clients/connect/connect-common';
import {ConfirmDialogService} from '@clients/revel/confirm-dialog';
import {AuthProvider} from '@clients/shared/auth';
import {UntilComponentDestroyed} from '@clients/shared/common-forms';
import {EMAIL_REGEX} from '@clients/shared/utilities';

import {ConnectFacade} from '../../+state/connect.facade';
import {CONNECT_ROUTES_ENUM} from '../../enum/routes.enum';
import {ConnectApiService} from '../../services/connect-api.service';
import {TokenParserService} from '../../services/token-parser.service';

@Component({
    selector: 'rc-user',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends UntilComponentDestroyed implements OnInit {
    public formGroup: FormGroup;
    public roleOptions = ['agent', 'company-admin'];
    public errorMessage?: string = null;
    public successMessage?: string = null;
    public pageAction: string;
    public loading = false;
    public isEditable = false;
    public isNewUser = false;
    public userId: string;
    public cancelButtonText = ' Cancel ';

    constructor(
        private connectFacade: ConnectFacade,
        private connectApiService: ConnectApiService,
        private authProvider: AuthProvider,
        private tokenParserService: TokenParserService,
        private router: Router,
        private route: ActivatedRoute,
        private confirmDialogService: ConfirmDialogService
    ) {
        super();
        this.formGroup = new FormGroup({
            firstName: new FormControl('', [Validators.maxLength(100), Validators.pattern(FIRST_LAST_NAME_REGEX)]),
            lastName: new FormControl('', [Validators.maxLength(100), Validators.pattern(FIRST_LAST_NAME_REGEX)]),
            email: new FormControl('', [
                Validators.required,
                Validators.maxLength(100),
                Validators.pattern(EMAIL_REGEX)
            ]),
            externalIdentifier: new FormControl('', [Validators.maxLength(100)]),
            roles: new FormControl('', [Validators.required]),
            active: new FormControl(true)
        });
    }

    ngOnInit() {
        const {
            params: {userId}
        } = this.route.snapshot;
        this.userId = userId;

        const lastUrlSegment = this.route.snapshot.url.length - 1;
        this.pageAction = this.route.snapshot.url[lastUrlSegment].path;

        if (this.pageAction === 'edit') {
            this.isEditable = true;
            this.setTitle('Edit User');
            this.getUser(userId);
        } else if (this.pageAction === 'view') {
            this.setTitle('View User');
            this.cancelButtonText = ' Back ';
            this.getUser(userId);
        } else {
            this.isEditable = true;
            this.isNewUser = true;
            this.setTitle('Add User');
        }

        this.isEditable ? this.formGroup.get('active').enable() : this.formGroup.get('active').disable();
    }

    submit() {
        this.loading = true;
        this.errorMessage = '';
        this.successMessage = '';

        const companyId = this.connectFacade.getCurrentCompany().companyId;

        const userData: IUserData = {
            ...this.formGroup.value,
            companyId,
            custom: {}
        };

        let obs: Observable<Object>;
        if (this.pageAction === 'add') {
            const newUser: IUserDto = {
                data: userData,
                active: true,
                sendWelcomeEmail: true
            };
            obs = this.connectApiService.createUser(newUser);
        } else {
            const userUpdate: IUserDto = {
                data: userData,
                active: this.formGroup.value['active'],
                sendWelcomeEmail: false
            };
            obs = this.connectApiService.updateUser(this.userId, userUpdate);
        }
        obs.pipe(takeUntil(this.destroyed$)).subscribe(
            () => {
                this.loading = false;
                //kick render cycle to ensure loading is false, then navigate.
                setTimeout(() => {
                    this.router.navigate([CONNECT_ROUTES_ENUM.users]);
                });
            },
            (error) => {
                this.errorMessage = error.error;
                this.successMessage = null;
                this.loading = false;
            }
        );
    }

    cancel() {
        if (!this.formGroup.dirty && !this.formGroup.dirty) {
            this.router.navigate([CONNECT_ROUTES_ENUM.users]);
            return;
        }

        const dialogRef = this.confirmDialogService.openDialog({
            heading: 'Are you sure you want to leave this page?',
            message: 'By leaving this page, you will lose all information entered.',
            okMessage: 'Yes, leave page',
            cancelMessage: 'Stay on this page'
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.router.navigate([CONNECT_ROUTES_ENUM.users]);
            }
        });
    }

    populateForm(user: IUser) {
        this.formGroup.controls['firstName'].setValue(user.data.firstName);
        this.formGroup.controls['lastName'].setValue(user.data.lastName);
        this.formGroup.controls['email'].setValue(user.data.email);
        this.formGroup.controls['externalIdentifier'].setValue(user.data.externalIdentifier);
        this.formGroup.controls['roles'].setValue(user.data.roles);
        this.formGroup.controls['active'].setValue(!user.blocked);
    }

    setTitle(pageTitle: string) {
        this.connectFacade.setTitle({title: pageTitle});
    }

    getUser(userId: string) {
        this.connectApiService
            .getUser(userId)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((response: IUser) => {
                this.populateForm(response);
            });
    }
}
