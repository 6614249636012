/* eslint-disable @typescript-eslint/no-unused-vars */
import {BehaviorSubject, concat} from 'rxjs';

import {Injector} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Action} from '@clients/shared/models';
import {PersistenceService} from '@clients/shared/persistence';
import {Store} from '@ngrx/store';

import {AuthFailureAction, AuthSuccessAction} from '../+state/auth.actions';
import {AuthProviderError} from '../models/AuthProviderError.interface';

export abstract class AuthProvider {
    readonly persistenceAvailable: boolean;
    public errors: BehaviorSubject<AuthProviderError> = new BehaviorSubject<AuthProviderError>(null);

    protected store: Store<any>;
    protected accessTokenKey = 'access_token';
    protected jwtHelper: JwtHelperService = new JwtHelperService();
    protected persistence: PersistenceService;
    protected data: any = {};
    private _returnUrl: string;
    protected constructor(private injector: Injector) {
        this.store = injector.get(Store);
        try {
            this.persistence = this.injector.get<PersistenceService>(PersistenceService);
            localStorage.setItem('__test', 'true');
            this.persistenceAvailable = true;
        } catch (e) {
            this.persistenceAvailable = false;
        }
    }
    get returnUrl() {
        return this._returnUrl;
    }

    // persistence and fallback

    set returnUrl(value: string) {
        this._returnUrl = value;
    }

    /**
     * Some applications may need a public method to set the token manually. This is typically
     * used in the case of a token that's passed in via query params.
     */
    public setToken(token: string, actions: any = []): void {
        this.success(token, null, actions);
    }
    public getToken(): string | null {
        let token: string;
        if (this.persistenceAvailable) {
            token = this.persistence.get(this.accessTokenKey);
        } else {
            token = this.data[this.accessTokenKey];
        }

        return token || null;
    }

    public setSession(token: string): void {
        if (this.persistenceAvailable) {
            this.persistence.set(this.accessTokenKey, token);
        } else {
            this.data[this.accessTokenKey] = token;
        }
    }

    public revokeSession(params: any = null): void {
        if (this.persistenceAvailable) {
            this.persistence.remove(this.accessTokenKey);
        } else {
            this.data[this.accessTokenKey] = null;
        }
    }

    public decodeToken(token: string) {
        try {
            return this.jwtHelper.decodeToken(token);
        } catch (err) {
            return null;
        }
    }

    // must define in child class
    public logout(params: any = null) {
        throw new Error('Logout method in auth.provider.ts should be defined in your child auth provider class.');
    }

    public setError(error: AuthProviderError) {
        this.errors.next(error);
    }

    public handleAuthError(error) {
        const {status} = error;
        switch (status) {
            case 401:
                this.handle401Error();
                return;
        }
    }

    protected success(token: string, returnUrl?: string, actions: any = []): void {
        this.setSession(token);

        const defaultActions: any = [AuthSuccessAction({token, returnUrl})].concat(actions);
        if (this.returnUrl) {
            defaultActions.concat;
        }

        defaultActions.forEach((action: Action) => {
            this.store.dispatch(action);
        });
    }

    protected fail(err: AuthProviderError, actions: any = []): void {
        this.setError(err);

        const defaultActions: any = [AuthFailureAction({error: err})].concat(actions);

        defaultActions.forEach((action: Action) => {
            this.store.dispatch(action);
        });
    }

    protected failWithRetry(err: AuthProviderError, actions: any = []): void {
        this.setError(err);
        if (actions.length) {
            actions.forEach((action: Action) => {
                this.store.dispatch(action);
            });
        }
    }

    public abstract login(params?: any): void;

    public abstract isAuthenticated(): boolean;

    public abstract handleAuthentication(params?: any): void;

    public abstract renewSession(): Promise<string>;

    public abstract isTokenExpired(token: string): boolean;

    public abstract getTokenExpirationDate(): null | Date;

    public abstract handle401Error(): void;

    public abstract changePassword(params?: any): void;

    public abstract authorize(params?: any): void;
}
