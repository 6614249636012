import {take} from 'rxjs/operators';

import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {UploadOperation} from '../../../enum/uploads.enum';
import {IUploadDetails} from '../../../models/upload';
import {ConnectApiService} from '../../../services/connect-api.service';
import {UploadDetailsComponent} from '../upload-details/upload-details.component';
import {Observable} from 'rxjs';

@Component({
    selector: 'rc-upload-details-dialog',
    template: `<button type="button" color="primary" mat-button (click)="open()"><ng-content></ng-content></button>`,
    styleUrls: ['./upload-details-dialog.component.scss']
})
export class UploadDetailsDialogComponent {
    @Input()
    uploadDetails: IUploadDetails;

    @Input()
    operation: UploadOperation;

    @Input()
    uploadId: string;

    @Input()
    fileName: string;

    constructor(private dialog: MatDialog, private connectApi: ConnectApiService) {}

    public open() {
        if (!this.uploadDetails && this.uploadId) {
            let details: Observable<IUploadDetails>;

            switch (this.operation) {
                case UploadOperation.Delete:
                    details = this.connectApi.getDeleteUploadDetails(this.uploadId);
                    break;
                case UploadOperation.Import:
                    details = this.connectApi.getImportUploadDetails(this.uploadId);
                    break;
                default:
                    throw new Error(`Invalid operation value provided to dialog: ${this.operation}`);
            }

            //load uploadDetails if not present.
            details.pipe(take(1)).subscribe((uploadDetails) => {
                // populate details that are not part of the API response but have been provided as component properties
                uploadDetails.operation = this.operation;
                uploadDetails.fileName = this.fileName;
                this.uploadDetails = uploadDetails;
                this.openDialog();
            });
        } else {
            this.openDialog();
        }
    }

    private openDialog() {
        this.dialog.open(UploadDetailsComponent, {
            width: '90vw',
            height: '90vh',
            data: this.uploadDetails,
            disableClose: false
        });
    }
}
