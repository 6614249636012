const SumoLogger = require('sumo-logger'); // eslint-disable-line
import {APP_INITIALIZER, Inject, Injectable} from '@angular/core';
import {ENV_SERVICE, EnvService} from '@clients/shared/config';

import {Log} from '../models/Log.interface';
import {LogSeverity} from '../models/Log.type';
import {LoggingProvider} from './logging.provider';

@Injectable()
export class Sumo extends LoggingProvider {
    protected static SumoLogger: any;

    constructor(@Inject(ENV_SERVICE) envService: EnvService) {
        super();
        Sumo.SumoLogger = new SumoLogger(envService.sumo);
    }
    public static log(log: Log) {
        Sumo.SumoLogger.log({
            message: log.message,
            ...log.options
        });
    }

    public static handleError(error) {
        const log: Log = new Log(error.message, error.options, LogSeverity.ERROR, error.type);

        Sumo.log(log);
    }
}

export function sumoServiceFactory(envService: EnvService) {
    const sumo = new Sumo(envService);
    return () => sumo;
}

/**
APP_INITIALIZER provider to initialize Sumo at app start.
*/
export const SumoServiceInitializationProvider = {
    provide: APP_INITIALIZER,
    useFactory: sumoServiceFactory,
    deps: [ENV_SERVICE],
    multi: true
};
