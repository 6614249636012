import {Injectable} from '@angular/core';
import {getUrlParameters} from '@clients/shared/utilities';

@Injectable({
    providedIn: 'root'
})
export class RedirectService {
    constructor() {}

    buildRedirect(returnUrl: string, defaultRoute: string): IRedirect {
        if (this.redirectExists(returnUrl)) {
            const route = returnUrl.split('?')[0];
            const queryParams = getUrlParameters(returnUrl);
            return {
                route,
                queryParams
            };
        }

        return {
            route: defaultRoute
        };
    }

    private redirectExists(url) {
        return url && url !== '' && url !== '/';
    }
}

export interface IRedirect {
    route: string;
    queryParams?: any;
}
