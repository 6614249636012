import {Component, EventEmitter, Output} from '@angular/core';
import {BoldIcons} from '@clients/shared/custom-icons';

@Component({
    selector: 'rc-back-button',
    template: `
        <button mat-flat-button (click)="backClick.emit()" data-e2e="back" class="back-button revel-flat-button">
            <mat-icon class="back-icon" [svgIcon]="icons.ARROW_LEFT_1" inline></mat-icon> Back
        </button>
    `,
    styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {
    @Output()
    public backClick: EventEmitter<any> = new EventEmitter<any>();

    public icons = BoldIcons;

    constructor() {}
}
