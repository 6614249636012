import {filter as _filter, find as _find, head as _head} from 'lodash';
import moment from 'moment';
import {Observable} from 'rxjs';
import {map, startWith, take} from 'rxjs/operators';

import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import {ActivatedRoute, Router} from '@angular/router';
import {FIRST_LAST_NAME_REGEX} from '@clients/connect/connect-common';
import {ConfirmDialogService} from '@clients/revel/confirm-dialog';
import {
    DATE_PATTERN,
    dateMask,
    MBI_REGEX,
    mbiMask,
    PHONE_NUMBER_PATTERN,
    phoneMask,
    WINDOW
} from '@clients/shared/utilities';

import {ConnectFacade} from '../../../+state/connect.facade';
import {CONNECT_ROUTES_ENUM} from '../../../enum/routes.enum';
import {IEnrollmentRequest} from '../../../models/enrollment';
import {IState, states} from '../../../models/states';
import {ISurveyConfigSummary} from '../../../models/survey-config-summary';
import {ConnectApiService} from '../../../services/connect-api.service';
import {CustomValidators} from './custom.validators';

const DATE_FORMAT = 'MM-DD-YYYY';
const apiErrorMessage = 'Oops! Something went wrong retrieving your survey, please try again.';

@Component({
    selector: 'rc-new-enrollment-form',
    templateUrl: './new-enrollment-form.component.html',
    styleUrls: ['./new-enrollment-form.component.scss']
})
export class NewEnrollmentFormComponent implements OnInit {
    public filteredStates: Observable<IState[]>;
    public surveys: ISurveyConfigSummary[];
    public showSurveySelect: boolean;
    public enrolleeForm: FormGroup;
    public assessmentForm: FormGroup;
    public masks = {
        dateMask,
        phoneMask,
        mbiMask
    };
    public showForm = true;
    public errorMessage?: string = null;
    public enrollee?: {
        firstName: string;
        lastName: string;
        dateOfBirth: string;
        phoneNumber: string;
        state: string;
        mbi: string;
        surveyName: string;
    } = null;
    private surveyLink: string;

    constructor(
        private api: ConnectApiService,
        private connectFacade: ConnectFacade,
        @Inject(WINDOW) private window: Window,
        private router: Router,
        private confirmDialogService: ConfirmDialogService,
        private route: ActivatedRoute
    ) {
        this.enrolleeForm = new FormGroup({
            firstName: new FormControl('', [
                Validators.required,
                Validators.maxLength(100),
                Validators.pattern(FIRST_LAST_NAME_REGEX)
            ]),
            lastName: new FormControl('', [
                Validators.required,
                Validators.maxLength(100),
                Validators.pattern(FIRST_LAST_NAME_REGEX)
            ]),
            state: new FormControl('', [Validators.required, CustomValidators.state(states)]),
            dateOfBirth: new FormControl('', [
                Validators.required,
                Validators.pattern(DATE_PATTERN),
                CustomValidators.date(DATE_FORMAT),
                CustomValidators.dateMin(moment().add(-150, 'years').format(DATE_FORMAT), DATE_FORMAT),
                CustomValidators.dateMax(moment().format(DATE_FORMAT), DATE_FORMAT)
            ]),
            phoneNumber: new FormControl('', [Validators.required, Validators.pattern(PHONE_NUMBER_PATTERN)]),
            mbi: new FormControl('', [Validators.required, Validators.pattern(MBI_REGEX)])
        });

        this.assessmentForm = new FormGroup({
            configurationId: new FormControl('', [Validators.required])
        });

        this.filteredStates = this.stateControl.valueChanges.pipe(
            startWith(''),
            map((state) => (state ? this.filterStates(state) : states.slice()))
        );
    }

    get phoneNumberControl(): FormControl {
        return this.enrolleeForm.get('phoneNumber') as FormControl;
    }

    get dobControl(): FormControl {
        return this.enrolleeForm.get('dateOfBirth') as FormControl;
    }

    get stateControl(): FormControl {
        return this.enrolleeForm.get('state') as FormControl;
    }

    get configurationIdControl(): FormControl {
        return this.assessmentForm.get('configurationId') as FormControl;
    }

    ngOnInit(): void {
        this.connectFacade.setTitle({title: 'Add New Enrollee'});

        const {
            queryParams: {referenceKey}
        } = this.route.snapshot;

        if (referenceKey) {
            this.api
                .getMemberInfo(referenceKey)
                .pipe(take(1))
                .subscribe(
                    (member) => {
                        let memberState = null;
                        if (member.state) {
                            memberState = _head(_filter(states, (s) => s.value === member.state));
                        }
                        this.enrolleeForm.patchValue({...member, state: memberState ? memberState.name : null});
                        this.enrolleeForm.updateValueAndValidity();
                    },
                    () => {
                        this.errorMessage = 'Member not found. Please fill out the information above to proceed.';
                    }
                );
        }

        this.api
            .getAgentSurveySummaries()
            .pipe(take(1))
            .subscribe((s) => {
                this.surveys = s;
                if (s.length === 1) {
                    this.configurationIdControl.setValue(s[0].id);
                } else {
                    this.showSurveySelect = true;
                }
            });
    }

    back(stepper: MatStepper) {
        this.errorMessage = '';
        stepper.selected.completed = false;
        stepper.selected.editable = false;

        const lastStep = stepper.steps.toArray()[stepper.selectedIndex - 1];
        lastStep.completed = false;
        lastStep.editable = true;
        stepper.previous();
    }

    next(stepper: MatStepper) {
        this.errorMessage = '';
        stepper.selected.completed = true;
        stepper.selected.editable = false;
        stepper.next();
    }

    submit(stepper: MatStepper) {
        const dob = moment(this.dobControl.value, DATE_FORMAT);

        const state: IState = _find(states, (s) => s.name === this.stateControl.value);
        const enrollment: IEnrollmentRequest = {
            ...this.assessmentForm.value,
            ...this.enrolleeForm.value,
            dateOfBirth: dob.toISOString(),
            state: state.value,
            phoneNumber: this.phoneNumberControl.value.replace(/-/g, '')
        };

        this.surveyLink = '';
        this.api.postNewEnrollment(enrollment).subscribe(
            (response) => {
                this.enrollee = {
                    firstName: this.enrolleeForm.get('firstName').value,
                    lastName: this.enrolleeForm.get('lastName').value,
                    dateOfBirth: this.enrolleeForm.get('dateOfBirth').value,
                    phoneNumber: this.enrolleeForm.get('phoneNumber').value,
                    state: this.enrolleeForm.get('state').value,
                    mbi: this.enrolleeForm.get('mbi').value,
                    surveyName: _find(this.surveys, (s) => s.id === this.configurationIdControl.value).displayName
                };
                this.surveyLink = response.link;
                stepper.selected.completed = true; // this allows movement to the final step
                stepper.next();
            },
            () => {
                this.errorMessage = apiErrorMessage;
                // todo: log here?
            }
        );
    }

    cancel() {
        if (!this.enrolleeForm.dirty && !this.assessmentForm.dirty) {
            this.router.navigate([CONNECT_ROUTES_ENUM.agentDashboard]);
            return;
        }

        const dialogRef = this.confirmDialogService.openDialog({
            heading: 'Are you sure you want to leave this page?',
            message:
                'By leaving this page, you will lose all information entered. The enrollee will not be saved. You will need to enter the information again.',
            okMessage: 'YES, LEAVE PAGE',
            cancelMessage: 'STAY ON THIS PAGE'
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.router.navigate([CONNECT_ROUTES_ENUM.agentDashboard]);
            }
        });
    }

    public loadAssessment() {
        const survey = this.window.open(this.surveyLink, 'SURVEY_WINDOW');
        if (survey && survey.focus) {
            survey.focus();
        }

        this.router.navigate([CONNECT_ROUTES_ENUM.agentDashboard]);
    }

    private filterStates(value: string): IState[] {
        const filterValue = value.toLowerCase();
        return _filter(states, (state) => `${state.value}${state.name}`.toLowerCase().includes(filterValue));
    }
}
