import {CustomIcons} from '@clients/shared/custom-icons';

import {PERMISSIONS_ENUM} from '../../enum/permissions.enum';
import {CONNECT_ROUTES_ENUM} from '../../enum/routes.enum';
import {ISidenavListItem} from './ISidenavListItem';

export interface ISidenavContents {
    topSections: ISidenavListItem[][];
    bottomSections: ISidenavListItem[][];
}

export const sidenavContents: ISidenavContents = {
    topSections: [
        [
            {
                iconName: CustomIcons.LIVE_AGENT,
                text: 'Amplify',
                location: `/${CONNECT_ROUTES_ENUM.agentDashboard}`,
                isSvg: true
            },
            {
                iconName: 'person_add_alt',
                text: 'Manage Users',
                location: `/${CONNECT_ROUTES_ENUM.users}`,
                permissionAndFeature: {
                    permission: PERMISSIONS_ENUM.readUserManagement
                }
            },
            {
                iconName: 'cloud_upload',
                text: 'Uploads',
                location: `/${CONNECT_ROUTES_ENUM.uploads}`,
                permissionAndFeature: {
                    permission: PERMISSIONS_ENUM.readUserManagement
                }
            }
        ]
    ],
    bottomSections: [
        [
            {
                iconName: 'exit_to_app',
                text: 'Sign Out',
                location: `/${CONNECT_ROUTES_ENUM.signOut}`
            }
        ]
    ]
};
