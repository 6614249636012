import {size as _size} from 'lodash';

import {Inject, Injectable} from '@angular/core';
import {ENV_SERVICE, EnvService} from '@clients/shared/config';

import {AnalyticsProvider} from './analytics.provider';

declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService extends AnalyticsProvider {
    public static service = 'google';
    private googleKey: string;
    private mapping: any;

    private userId: string;

    private properties: any;
    private superProperties: any;

    private isCustomMapping: boolean;
    constructor(@Inject(ENV_SERVICE) envService: EnvService) {
        super();
        this.googleKey = envService.googleConfig.analytics.trackingCode;
        this.mapping = envService.googleConfig.analytics.customMapping;
        this.isCustomMapping = _size(this.mapping) > 0;
    }

    public pageTrack(path: string): void {
        this.sendData(path);
    }

    public eventTrack(eventName: string, properties: any = null): void {
        gtag('event', eventName, properties);
    }

    public setUsername(userId: string) {
        this.userId = userId;
    }

    public setUserProperties(properties: any): void {
        this.properties = properties;
    }

    public setSuperProperties(properties: any): void {
        this.superProperties = properties;
    }

    private sendData(path: string = null) {
        let config = {};

        if (path !== null) {
            config['page_path'] = path;
        }

        if (this.isCustomMapping) {
            config['custom_map'] = this.mapping;
        }
        if (this.userId) {
            config['user_id'] = this.userId;
        }
        if (this.properties) {
            config = {...config, ...this.properties};
        }
        if (this.superProperties) {
            config = {...config, ...this.superProperties};
        }

        gtag('config', this.googleKey, config);
    }
}
