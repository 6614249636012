import {Injectable} from '@angular/core';
import {PersistenceProvider} from './persistence.provider';

@Injectable()
export class PersistenceService {
    constructor(private storageProvider: PersistenceProvider) {}

    public get(key: string, json: boolean = false): any {
        const value = this.storageProvider.get(key);
        if (value === null || value === undefined) return null;

        return json ? JSON.parse(value) : value;
    }

    public set(key: string, value: any, json: boolean = false): void {
        let val;
        if (value === null || value === undefined) {
            val = null;
        } else {
            val = json ? JSON.stringify(value) : value;
        }

        this.storageProvider.set(key, val);
    }

    public remove(key: string): void {
        this.storageProvider.remove(key);
    }

    public removeAll(): void {
        this.storageProvider.removeAll();
    }
}
