import {copyEnvFileValuesTo, ENV_SERVICE, EnvService} from '@clients/shared/config';

import {ICompanyConfig} from '../models/connect-env';

export class ConnectEnvService extends EnvService {
    public companyServiceApi: string;
    public surveyServiceApi: string;
    public ingestServiceApi: string;
    public companyConfigs: ICompanyConfig[];
}

export function connectEnvServiceFactory() {
    // Create env
    const envService = new ConnectEnvService();

    copyEnvFileValuesTo(envService);

    return envService;
}

export const ConnectEnvServiceProvider = {
    provide: ENV_SERVICE,
    useFactory: connectEnvServiceFactory,
    deps: []
};
