import {ConfirmDialogService} from './confirm-dialog.service';

export const confirmDialogSerivceFactory = {
    getService: () => ({
        openDialog: () => {}
    })
};

export const CONFIRM_DIALOG_SERVICE_MOCK_PROVIDER = {
    provide: ConfirmDialogService,
    useValue: confirmDialogSerivceFactory.getService()
};
