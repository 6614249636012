import {Component, OnInit} from '@angular/core';
import {MatSelectChange} from '@angular/material/select';
import {ICompany, ICompanyData, ICompanyDetails} from '@clients/connect/connect-common';

import {ConnectFacade} from '../../../+state/connect.facade';
import {PermissionsService} from '../../../services/permissions.service';
import {ConnectApiService} from '../../../services/connect-api.service';
import {takeUntil} from 'rxjs/operators';
import {UntilComponentDestroyed} from '@clients/shared/common-forms';

@Component({
    selector: 'rc-company-select',
    template: `
        <div class="company-select">
            <mat-form-field *ngIf="showSelect">
                <mat-select class="revel-select" [value]="currentCompany" (selectionChange)="onSelectionChange($event)">
                    <mat-select-trigger>
                        <div class="selected-company-wrapper">
                            <img
                                class="selected-company-header-img"
                                [src]="currentCompany.logo"
                                [alt]="currentCompany.name"
                            />
                        </div>
                    </mat-select-trigger>
                    <mat-option *ngFor="let company of companies" [value]="company">
                        {{ company.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="currentCompany" class="company-name">
                <img class="selected-company-header-img" [src]="currentCompany.logo" [alt]="currentCompany.name" />
            </div>
        </div>
    `,
    styleUrls: ['./company-select.component.scss']
})
export class CompanySelectComponent extends UntilComponentDestroyed implements OnInit {
    public companies: ICompany[];
    public currentCompany: ICompanyData;

    public showSelect: boolean;

    constructor(
        private connectFacade: ConnectFacade,
        private permissionsService: PermissionsService,
        private connectApi: ConnectApiService
    ) {
        super();
    }

    ngOnInit() {
        this.showSelect = this.permissionsService.hasMultiCompany();
        this.connectFacade.getProfile().subscribe((p) => {
            this.companies = p.companies;
            this.currentCompany = p.currentCompany.data;
        });
    }

    onSelectionChange(event: MatSelectChange) {
        const company: ICompany = event.value;

        this.connectApi
            .getCompanyDetails(company.companyId)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((response: ICompanyDetails) => {
                this.connectFacade.changeCurrentCompany(response);
            });
    }
}
