import {Component, Input} from '@angular/core';

import {UploadStatus} from '../../../enum/uploads.enum';
import {IUploadSummary} from '../../../models/upload';

@Component({
    selector: 'rc-upload-status',
    template: `
        <span
            class="status"
            [ngClass]="{
                processing: upload.status === UploadStatus.Processing,
                complete: upload.status === UploadStatus.Complete
            }"
            >{{ upload.status }}</span
        >
    `,
    styleUrls: ['./upload-status.component.scss']
})
export class UploadStatusComponent {
    @Input()
    public upload: IUploadSummary;

    public UploadStatus = UploadStatus;

    constructor() {}
}
