import {R_BREAKPOINTS_ALIASES, R_CUSTOM_BREAKPOINTS} from '../enum/breakpoints.enum';

export const R_BREAKPOINTS = [
    {
        alias: R_BREAKPOINTS_ALIASES.XS,
        suffix: 'Xs',
        mediaQuery: `screen
         and (min-width: ${R_CUSTOM_BREAKPOINTS.PHONE_BASE}px)
         and (max-width: ${R_CUSTOM_BREAKPOINTS.PHONE_SMALL}px)`,
        overlapping: false
    },
    {
        alias: R_BREAKPOINTS_ALIASES.SM,
        suffix: 'Sm',
        mediaQuery: `screen
         and (min-width: ${R_CUSTOM_BREAKPOINTS.PHONE_SMALL + 1}px)
         and (max-width: ${R_CUSTOM_BREAKPOINTS.TABLET}px)`,
        overlapping: false
    },
    {
        alias: R_BREAKPOINTS_ALIASES.MD,
        suffix: 'Md',
        mediaQuery: `screen
         and (min-width: ${R_CUSTOM_BREAKPOINTS.TABLET + 1}px)
         and (max-width: ${R_CUSTOM_BREAKPOINTS.DESKTOP_SMALL}px)`,
        overlapping: false
    },
    {
        alias: R_BREAKPOINTS_ALIASES.LG,
        suffix: 'Lg',
        mediaQuery: `screen
         and (min-width: ${R_CUSTOM_BREAKPOINTS.DESKTOP_SMALL + 1}px)
         and (max-width: ${R_CUSTOM_BREAKPOINTS.DESKTOP_LARGE}px)`,
        overlapping: false
    },
    {
        alias: R_BREAKPOINTS_ALIASES.XLG,
        suffix: 'Xlg',
        mediaQuery: `screen
         and (min-width: ${R_CUSTOM_BREAKPOINTS.DESKTOP_LARGE + 1}px)
         and (max-width: ${R_CUSTOM_BREAKPOINTS.DESKTOP_X_LARGE}px)`,
        overlapping: false
    }
];
