import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthProvider} from '@clients/shared/auth';
import {WINDOW} from '@clients/shared/utilities';

import {CONNECT_ROUTES_ENUM} from '../../enum/routes.enum';

@Component({
    selector: 'rc-sign-out',
    template: ``,
    styles: []
})
export class SignOutComponent implements OnInit {
    constructor(private router: Router, private authProvider: AuthProvider, @Inject(WINDOW) private window: Window) {}

    ngOnInit() {
        localStorage.setItem('confirmedMigration', 'false');
        if (this.authProvider.isAuthenticated()) {
            const returnUrl = `${this.window.location.origin}/${CONNECT_ROUTES_ENUM.signIn}`;

            this.authProvider.revokeSession({
                returnTo: returnUrl
            });
            return;
        }
        this.router.navigate([CONNECT_ROUTES_ENUM.signIn]);
    }
}
