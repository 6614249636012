import {FormControl, ValidatorFn} from '@angular/forms';

export const searchValidator: ValidatorFn = (control: FormControl) => {
    const rawSearch = control.value;
    if (rawSearch.trim() === '') {
        return null;
    }

    const rawSearchTerms = control.value.split(' ');
    const searchTerms = [];
    for (let i = 0; i < rawSearchTerms.length; i++) {
        const term = rawSearchTerms[i].trim();
        if (term.length === 0) {
            continue;
        }
        if (term.length === 1) {
            return {search: 'Search terms must be at least two characters in length'};
        }
        searchTerms.push(term);
    }

    if (searchTerms.length > 4) {
        return {search: 'Search contains more than 4 search terms'};
    }

    return null;
};
