import {createFeatureSelector} from '@ngrx/store';
import {ActionReducerMap} from '@ngrx/store';

import {appReducer} from './app/app.reducer';
import {IAppState, initialAppState} from './app/IAppState';
import {initialProfileState, IProfileState} from './profile/IProfileState';
import {profileReducer} from './profile/profile.reducer';

export interface IConnectCombinedState {
    profile: IProfileState;
    appData: IAppState;
}

export const initialConnectState: IConnectCombinedState = {
    profile: initialProfileState,
    appData: initialAppState
};

export const connectTopLevelSelector = createFeatureSelector<IConnectCombinedState>('connect');

export const connectCombinedReducer: ActionReducerMap<IConnectCombinedState> = {
    profile: profileReducer,
    appData: appReducer
};
