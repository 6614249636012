/* istanbul ignore file */
import {tap} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {AnalyticsService} from '../services/analytics.service';
import {
    AnalyticsActionTypes,
    EventTrackAction,
    SetSuperPropertiesAction,
    SetUsernameAction,
    SetUserPropertiesAction
} from './analytics.actions';

@Injectable()
export class AnalyticsEffects {
    eventTrack = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AnalyticsActionTypes.EVENT_TRACK),
                tap((action: EventTrackAction) => {
                    this.analyticsService.eventTrack(action.payload.eventName, action.payload.properties);
                })
            ),
        {dispatch: false}
    );

    setUsername = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AnalyticsActionTypes.SET_USERNAME),
                tap((action: SetUsernameAction) => {
                    this.analyticsService.setUsername(action.payload);
                })
            ),
        {dispatch: false}
    );

    setUserProperties = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AnalyticsActionTypes.SET_USER_PROPERTIES),
                tap((action: SetUserPropertiesAction) => {
                    this.analyticsService.setUserProperties(action.payload);
                })
            ),
        {dispatch: false}
    );

    setSuperProperties = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AnalyticsActionTypes.SET_SUPER_PROPERTIES),
                tap((action: SetSuperPropertiesAction) => {
                    this.analyticsService.setSuperProperties(action.payload);
                })
            ),
        {dispatch: false}
    );

    enableTracking = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AnalyticsActionTypes.ENABLE_TRACKING),
                tap(() => {
                    this.analyticsService.enableTracking();
                })
            ),
        {dispatch: false}
    );

    disableTracking = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AnalyticsActionTypes.DISABLE_TRACKING),
                tap(() => {
                    this.analyticsService.disableTracking();
                })
            ),
        {dispatch: false}
    );

    constructor(private actions$: Actions, private analyticsService: AnalyticsService) {}
}
