import {from, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {WebAssetsUrl} from '@clients/shared/custom-icons';

import {AuthProvider} from '../services/auth.provider';

export const SKIP_AUTH_HEADER = 'x-skip-auth';
export const AUTHORIZATION_HEADER = 'Authorization';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
    private authProvider: AuthProvider;

    constructor(private injector: Injector) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.authProvider = this.injector.get<AuthProvider>(<any>AuthProvider);
        let request = req;

        if (request.url.startsWith(WebAssetsUrl.STREAMLINE)) {
            return next.handle(request);
        }

        if (request.headers.has(SKIP_AUTH_HEADER)) {
            request = req.clone({
                headers: req.headers.delete(SKIP_AUTH_HEADER)
            });

            return next.handle(request);
        } else if (this.authProvider.getToken()) {
            return from(this.handleAuthAccess(request, next)).pipe(tap(this.handleError));
        }

        return next.handle(request);
    }

    private handleAuthAccess(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authProvider.getToken();
        if (!token) throw new Error('Token not present');
        return next.handle(
            request.clone({
                headers: request.headers.set(AUTHORIZATION_HEADER, `Bearer ${token}`)
            })
        );
    }

    private handleError = (error) => {
        if (error instanceof HttpErrorResponse) {
            this.authProvider.handleAuthError(error);
        }
    };
}
