import {Component, Input} from '@angular/core';

@Component({
    selector: 'rc-sn-logo',
    template: `
        <mat-nav-list>
            <mat-list-item>
                <img
                    *ngIf="showText"
                    class="rc-sidenav-logo"
                    src="/assets/images/icario_2c_whiteTeal_RGB.png"
                    alt="Icario"
                />
                <img
                    *ngIf="!showText"
                    class="rc-sidenav-logo-collapsed"
                    src="/assets/images/icario_Omark_whiteTeal_CMYK.png"
                    alt="Icario"
                />
            </mat-list-item>
        </mat-nav-list>
    `,
    styleUrls: ['./logo.component.scss']
})
export class LogoComponent {
    @Input()
    public showText = true;
    constructor() {}
}
