import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {AuthModule} from '@clients/shared/auth';
import {CommonFormsModule} from '@clients/shared/common-forms';

import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {ConfirmDialogService} from './services/confirm-dialog.service';

@NgModule({
    imports: [CommonModule, MatDialogModule, MatButtonModule, CommonFormsModule, AuthModule],
    declarations: [ConfirmDialogComponent],
    exports: [ConfirmDialogComponent],
    providers: [ConfirmDialogService]
})
export class RevelConfirmDialogModule {}
