export function getUrlParameters(url) {
    const urlParts = url.split('?');
    const query = urlParts[1] || '';
    if (!query) return null;
    const queryParams = query
        .split('&')
        .map((param) => param.split('='))
        .reduce((values, [key, value]) => {
            values[key] = value;
            return values;
        }, {});

    return queryParams;
}
