import {ICompanyDetails} from '@clients/connect/connect-common';
import {AuthSuccessAction} from '@clients/shared/auth';
import {Action} from '@clients/shared/models';

import {initialProfileState, IProfileState} from './IProfileState';
import {ProfileActionTypes} from './profile.actions';

export function profileReducer(state = initialProfileState, action: Action<any>): IProfileState {
    const {type, payload} = action;

    switch (type) {
        case AuthSuccessAction.type:
            return handleAuthSuccess(state, payload);
        case ProfileActionTypes.PROFILE_LOADED:
            return handleProfileLoaded(state, payload);
        case ProfileActionTypes.CHANGE_CURRENT_COMPANY:
            return handleChangeCurrentCompany(state, payload);
        default:
            return state;
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function handleAuthSuccess(state: IProfileState, accessToken: string): IProfileState {
    return {
        ...state
    };
}

function handleProfileLoaded(state: IProfileState, profile: IProfileState): IProfileState {
    return {
        ...state,
        ...profile,
        currentCompany: {...profile.currentCompany},
        companies: [...profile.companies],
        isLoaded: true
    };
}

function handleChangeCurrentCompany(state: IProfileState, company: ICompanyDetails): IProfileState {
    return {
        ...state,
        companies: [...state.companies],
        currentCompany: company
    };
}
