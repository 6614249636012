import moment from 'moment';
import {takeUntil, tap} from 'rxjs/operators';

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatPaginator} from '@angular/material/paginator';
import {AuthProvider} from '@clients/shared/auth';
import {UntilComponentDestroyed} from '@clients/shared/common-forms';

import {DateRangePickerConfiguration} from '../../../../models/date-picker';
import {ConnectApiService} from '../../../../services/connect-api.service';
import {FileExportService} from '../../../../services/file-export.service';
import {TokenParserService} from '../../../../services/token-parser.service';
import {ProspectsDataSource} from './prospect-data-source';
import {searchValidator} from './search-term.validator';

export const MATERIAL_DATE_PICKER_FORMATS = {
    parse: {
        dateInput: 'MM/DD/YYYY'
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'LL',
        dateA11yLabel: 'MM/DD/YYYY',
        monthYearA11yLabel: 'LL'
    }
};

@Component({
    selector: 'rc-prospects-grid',
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATE_PICKER_FORMATS}
    ],
    template: `<div class="table-header">
            <div class="search" [formGroup]="searchForm" (keyup.enter)="search()">
                <mat-form-field class="form-field search-field" appearance="outline">
                    <mat-label>Search</mat-label>
                    <input data-e2e="search" autocomplete="none" matInput formControlName="search" type="input" />
                </mat-form-field>
                <button
                    mat-raised-button
                    class="revel-raised-button search-button"
                    color="primary"
                    (click)="search()"
                    [disabled]="!searchForm.valid"
                    data-e2e="search"
                >
                    Search
                </button>
            </div>
            <div class="export-data">
                <button
                    mat-raised-button
                    class="revel-raised-button export-button"
                    color="primary"
                    (click)="exportProspects()"
                    data-e2e="exportProspects"
                >
                    Export
                </button>
                <rc-date-picker
                    class="date-picker"
                    [startDate]="startDate"
                    [endDate]="endDate"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    (dateFilterChangeAction)="applyDateFilter($event)"
                ></rc-date-picker>
            </div>
        </div>
        <div *ngIf="dataSource.error$ | async" class="prospect-errors" data-e2e="prospect-error">
            Oops! Something when wrong. Please try again.
        </div>
        <rc-spinner
            [mode]="'indeterminate'"
            [value]="50"
            [backdropEnabled]="true"
            [positionGloballyCenter]="true"
            [displayProgressSpinner]="(dataSource.loading$ | async) || this.fileExportInProgress"
        ></rc-spinner>
        <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="firstName">
                <mat-header-cell *matHeaderCellDef> First name </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.firstName }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lastName">
                <mat-header-cell *matHeaderCellDef> Last name </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.lastName }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="assessmentDate">
                <mat-header-cell *matHeaderCellDef> Assessment Date </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.assessmentDate | date : 'medium' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="mbi">
                <mat-header-cell *matHeaderCellDef> MBI</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.mbi }} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="['firstName', 'lastName', 'assessmentDate', 'mbi']"></mat-header-row>
            <mat-row *matRowDef="let row; columns: ['firstName', 'lastName', 'assessmentDate', 'mbi']"></mat-row>
        </mat-table>
        <mat-paginator
            [length]="dataSource.prospectCount$ | async"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            aria-label="Select page"
        >
        </mat-paginator> `,
    styleUrls: ['./prospects-grid.component.scss']
})
export class ProspectsGridComponent extends UntilComponentDestroyed implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public startDate: Date;
    public endDate: Date;
    public minDate: Date;
    public maxDate: Date;
    public searchForm: FormGroup;
    public pageSize = 10;
    public pageSizeOptions = [10, 25, 50];
    public fileExportInProgress = false;
    public dataSource: ProspectsDataSource;

    constructor(private connectApi: ConnectApiService, private fileExportService: FileExportService) {
        super();
    }

    public ngOnInit() {
        this.searchForm = new FormGroup({
            search: new FormControl('', searchValidator)
        });

        const today = new Date();
        this.startDate = moment(today).subtract(60, 'days').toDate();
        this.endDate = today;
        this.minDate = moment(today).subtract(365, 'days').toDate();
        this.maxDate = today;

        this.dataSource = new ProspectsDataSource(this.connectApi);
        this.dataSource.loadProspects(this.startDate, this.endDate, 0, this.pageSize, '');
    }

    public ngAfterViewInit() {
        if (this.dataSource) {
            this.paginator.page
                .pipe(
                    takeUntil(this.destroyed$),
                    tap(() => this.loadProspects())
                )
                .subscribe();
        }
    }

    public search() {
        if (this.searchForm.valid) {
            this.loadProspects();
        }
    }

    public applyDateFilter($event: DateRangePickerConfiguration) {
        this.startDate = $event.start;
        this.endDate = $event.end;
        if (this.isValidDateRange()) {
            this.loadProspects();
        }
    }

    public exportProspects() {
        if (this.isValidDateRange()) {
            this.fileExportInProgress = true;
            const fileName = `prospects_${this.fileExportService.buildExportFileName()}`;

            this.connectApi
                .getProspectsMappedForExport(this.startDate, this.endDate)
                .pipe(takeUntil(this.destroyed$))
                .subscribe(
                    (response: any) => {
                        this.fileExportService.generateExportFile(response, fileName);
                        this.fileExportInProgress = false;
                    },
                    () => {
                        // todo gracefully handle error
                        this.fileExportInProgress = false;
                    }
                );
        }
    }

    public isValidDateRange() {
        return this.startDate && this.startDate >= this.minDate && this.endDate && this.endDate <= this.maxDate;
    }

    public loadProspects() {
        this.dataSource.loadProspects(
            this.startDate,
            this.endDate,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.searchForm.value.search
        );
    }
}
