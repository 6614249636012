import {Action, RouterNavigatePayload} from '@clients/shared/models';

import {IConnectTitle} from '../../models/connect-title';

export enum ConnectAppActionTypes {
    ROUTER_NAVIGATE = '[Connect App] Router navigate',
    NAV_TOGGLED = '[Connect App] Nav Toggled',
    MOBILE_VIEW_CHANGED = '[Connect App] Mobile view changed',
    SET_TITLE = '[Connect APP] Set title',
    LOAD_CONNECTIONS = '[Connect Bootstrap] Load Connections',
    CONNECTIONS_LOADED = '[Connect Bootstrap] Connections LOADED'
}

export class RouterNavigateAction implements Action<RouterNavigatePayload> {
    public readonly type = ConnectAppActionTypes.ROUTER_NAVIGATE;

    constructor(public payload: RouterNavigatePayload) {}
}

export class NavToggledAction implements Action {
    public readonly type = ConnectAppActionTypes.NAV_TOGGLED;
    constructor() {}
}

export class MobileViewChangedAction implements Action<boolean> {
    public readonly type = ConnectAppActionTypes.MOBILE_VIEW_CHANGED;
    constructor(public payload: boolean) {}
}

export class SetTitleAction implements Action<IConnectTitle> {
    public readonly type = ConnectAppActionTypes.SET_TITLE;
    constructor(public payload: IConnectTitle) {}
}

export class LoadConnectionsAction implements Action {
    public readonly type = ConnectAppActionTypes.LOAD_CONNECTIONS;
    constructor() {}
}
