import {sortBy as _sortBy} from 'lodash';
import {takeUntil} from 'rxjs/operators';

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ISignInOption} from '@clients/connect/connect-common';
import {AuthProvider} from '@clients/shared/auth';
import {UntilComponentDestroyed} from '@clients/shared/common-forms';
import {ConfirmDialogService} from '@clients/revel/confirm-dialog';
import {CONNECT_ROUTES_ENUM} from '../../enum/routes.enum';
import {ConnectApiService} from '../../services/connect-api.service';
import {RedirectService} from '../../services/redirect.service';

export enum SignInStep {
    username = 'username',
    connection = 'connection',
    password = 'password',
    forgotPassword = 'forgotPassword'
}

@Component({
    selector: 'rc-sign-in',
    template: `
        <div class="sign-in-background">
            <div class="sign-in" ngClass.xs="mobile">
                <img class="revel-logo" src="/assets/images/icario_2c_oceanTeal_RGB.png" alt="Icario" />
                <ng-container [ngSwitch]="currentStep">
                    <rc-username
                        *ngSwitchCase="steps.username"
                        [error]="error"
                        [initialUsername]="username"
                        (username)="submitUsername($event)"
                    ></rc-username>
                    <rc-connection
                        *ngSwitchCase="steps.connection"
                        [username]="username"
                        [error]="error"
                        [signInOptions]="signInOptions"
                        (backClick)="navigateTo(steps.username)"
                        (signInOption)="selectSignInOption($event)"
                    ></rc-connection>
                    <rc-password
                        *ngSwitchCase="steps.password"
                        [username]="username"
                        [error]="error"
                        [selectedSignIn]="selectedSignIn"
                        (backClick)="navigateTo(steps.connection)"
                        (forgotClick)="navigateTo(steps.forgotPassword)"
                        (password)="submitPassword($event)"
                    ></rc-password>
                    <rc-forgot-password
                        *ngSwitchCase="steps.forgotPassword"
                        [username]="username"
                        [error]="error"
                        [selectedSignIn]="selectedSignIn"
                        (backClick)="navigateTo(steps.password)"
                    ></rc-forgot-password>
                </ng-container>
            </div>
        </div>
    `,
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent extends UntilComponentDestroyed implements OnInit {
    public currentStep: SignInStep;
    public steps = SignInStep;
    public selectedSignIn: ISignInOption;
    public username: string;
    public error: string;
    public showResetMessage: boolean;
    public signInOptions: ISignInOption[];

    constructor(
        private authProvider: AuthProvider,
        private router: Router,
        private route: ActivatedRoute,
        private api: ConnectApiService,
        private redirectService: RedirectService,
        private confirmDialogService: ConfirmDialogService
    ) {
        super();
    }

    public ngOnInit() {
        localStorage.setItem('confirmedMigration', 'false');
        const {
            queryParams: {title, description, returnUrl}
        } = this.route.snapshot;

        if (this.authProvider.isAuthenticated()) {
            const location = this.redirectService.buildRedirect(returnUrl, CONNECT_ROUTES_ENUM.agentDashboard);
            this.router.navigate([location.route], location.queryParams ? {queryParams: location.queryParams} : {});
            return;
        }

        this.currentStep = SignInStep.username;

        this.authProvider.errors.pipe(takeUntil(this.destroyed$)).subscribe((e) => {
            this.error = e ? e.description : '';
        });

        if (returnUrl) {
            this.authProvider.returnUrl = returnUrl;
        }

        const hasError = !!title && !!description;
        if (hasError) {
            this.error = description;
        }
    }

    public async submitUsername(username: string) {
        this.error = '';
        this.username = username.toLowerCase();

        try {
            const result = await this.api.getSignInOptions(this.username).toPromise();

            this.signInOptions = _sortBy(result, 'name');

            if (this.signInOptions.length === 0) {
                this.error =
                    'This information does not match our records. If this problem persists, please contact customer.service@icariohealth.com.';

                return;
            }
            if (this.signInOptions.length === 1) {
                this.selectedSignIn = this.signInOptions[0];
                this.navigateTo(SignInStep.password);

                return;
            }
            this.navigateTo(SignInStep.connection);
        } catch {
            this.error =
                'An error occurred. If this problem persists, please contact customer.service@icariohealth.com.';
        }
    }

    public selectSignInOption(option: ISignInOption) {
        this.selectedSignIn = option;
        if (this.selectedSignIn.redirectUrl !== null) {
            const dialogRef = this.confirmDialogService.openDialog({
                heading: `${this.selectedSignIn.name} has upgraded this site.`,
                message: 'You should have received a Welcome Email to set up a username and account for the new site.',
                okMessage: 'Confirm'
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    window.location.assign(this.selectedSignIn.redirectUrl);
                }
            });
        }
        this.navigateTo(SignInStep.password);
    }

    public submitPassword(password) {
        this.authProvider.login({
            realm: this.selectedSignIn.realm,
            username: this.username,
            password: password
        });
    }

    public navigateTo(step: SignInStep) {
        this.error = '';
        // skip connection step
        if (
            step === SignInStep.connection &&
            this.currentStep === SignInStep.username &&
            this.signInOptions.length === 1
        ) {
            this.currentStep = SignInStep.password;
            return;
        }
        if (
            step === SignInStep.connection &&
            this.currentStep === SignInStep.password &&
            this.signInOptions.length === 1
        ) {
            this.currentStep = SignInStep.username;
            return;
        }

        if (step === SignInStep.forgotPassword) {
            this.showResetMessage = false;
        }

        this.currentStep = step;
    }
}
