import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {ICompany, ICompanyDetails} from '@clients/connect/connect-common';
import {EnableTrackingAction} from '@clients/shared/analytics';
import {select, Store} from '@ngrx/store';

import {FEATURES_ENUM} from '../enum/features.enum';
import {IConnectTitle} from '../models/connect-title';
import {LoadConnectionsAction, MobileViewChangedAction, NavToggledAction, SetTitleAction} from './app/app.actions';
import {selectAppData} from './app/app.selector';
import {IAppState, initialAppState} from './app/IAppState';
import {IConnectCombinedState} from './connectCombinedState';
import {initialProfileState, IProfileState} from './profile/IProfileState';
import {ChangeCurrentCompanyAction, LoadProfileAction} from './profile/profile.actions';
import {selectProfileState} from './profile/profile.selector';

@Injectable()
export class ConnectFacade {
    constructor(private store: Store<IConnectCombinedState>) {}

    public getCurrentProfile(): IProfileState {
        let profile: IProfileState = initialProfileState;

        this.store.pipe(select(selectProfileState), take(1)).subscribe((val) => (profile = val));

        return profile;
    }

    public getCurrentCompany(): ICompanyDetails {
        return this.getCurrentProfile().currentCompany;
    }

    public getCurrentFeatureEnabled(feature: FEATURES_ENUM): boolean {
        return this.getCurrentProfile().currentCompany.data.features[feature];
    }

    public getFeatureEnabled(feature: FEATURES_ENUM): Observable<boolean> {
        return this.getProfile().pipe(map((p) => !!p.currentCompany.data.features[feature]));
    }

    public getCurrentAppData(): IAppState {
        let appData: IAppState = initialAppState;

        this.store.pipe(select(selectAppData), take(1)).subscribe((val) => (appData = val));

        return appData;
    }

    public getAppData(): Observable<IAppState> {
        return this.store.pipe(select(selectAppData));
    }

    public getProfile(): Observable<IProfileState> {
        return this.store.pipe(select(selectProfileState));
    }

    public getCompanies(): Observable<ICompany[]> {
        return this.store.pipe(
            select(selectProfileState),
            map((profile) => profile.companies)
        );
    }

    public changeCurrentCompany(company: ICompanyDetails) {
        this.store.dispatch(new ChangeCurrentCompanyAction(company));
    }

    public toggleNav() {
        this.store.dispatch(new NavToggledAction());
    }

    public setTitle(title: IConnectTitle) {
        this.store.dispatch(new SetTitleAction(title));
    }

    public setIsMobile(isMobile: boolean) {
        this.store.dispatch(new MobileViewChangedAction(isMobile));
    }

    public loadProfile() {
        this.store.dispatch(new LoadProfileAction());
    }

    public loadConnections() {
        this.store.dispatch(new LoadConnectionsAction());
    }
    public enableTracking() {
        this.store.dispatch(new EnableTrackingAction());
    }
}
