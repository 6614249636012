import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ISignInOption} from '@clients/connect/connect-common';
import {RegularIcons} from '@clients/shared/custom-icons';

@Component({
    selector: 'rc-connection',
    template: `
        <div class="connection">
            <div class="user">
                <span class="user-label">Username</span>
                <span [attr.data-e2e]="'current_user'">{{ username }}</span>
                <span class="select-company">Select Company</span>
            </div>
            <div class="logo-list">
                <ng-container *ngFor="let option of signInOptions">
                    <div class="logo-tile" matRipple (click)="selectSignInOption(option)" [attr.data-e2e]="option.name">
                        <img [src]="option.signInLogo ? option.signInLogo : option.logo" [alt]="option.name" />
                    </div>
                </ng-container>
            </div>
            <rc-sign-in-error [error]="error"></rc-sign-in-error>
            <rc-sign-in-actions>
                <rc-back-button (backClick)="backClick.emit($event)"></rc-back-button>
            </rc-sign-in-actions>
        </div>
    `,
    styleUrls: ['./connection.component.scss']
})
export class ConnectionComponent {
    @Input()
    public username: string;

    @Input()
    public error: string;

    @Input()
    public signInOptions: ISignInOption[];

    @Output()
    public backClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public signInOption: EventEmitter<ISignInOption> = new EventEmitter<ISignInOption>();

    public regularIcons = RegularIcons;

    constructor() {}
    public selectSignInOption(option: ISignInOption) {
        this.signInOption.emit(option);
    }
}
