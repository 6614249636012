import {Component, Input} from '@angular/core';

@Component({
    selector: 'rc-sign-in-error',
    template: `
        <div *ngIf="error" class="error">
            <span>{{ error }}</span>
        </div>
    `,
    styleUrls: ['./sign-in-error.component.scss']
})
export class SignInErrorComponent {
    @Input()
    public error: string;
    constructor() {}
}
