import {BehaviorSubject, interval, of, Subject, Subscription} from 'rxjs';
import {catchError, filter, switchMap, takeWhile, tap} from 'rxjs/operators';

import {HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {IImportUserData} from '@clients/connect/connect-common';

import {UploadOperation, UploadStatus} from '../enum/uploads.enum';
import {IUploadDetails, IUploadStatus} from '../models/upload';
import {ConnectApiService} from './connect-api.service';
import {getErrors, getWarnings} from './import-file-utility';

@Injectable()
export class ImportFileService implements OnDestroy {
    public successMessage = '';
    public errorMessage = '';
    public warningMessage = '';
    private progressSubject = new BehaviorSubject<number>(0);
    private messageSubject = new BehaviorSubject<string>('');
    private statusSubject = new BehaviorSubject<ImportStatus>(ImportStatus.idle);
    private uploadSubject = new Subject<IUploadDetails>();
    public status$ = this.statusSubject.asObservable(); //eslint-disable-line
    public progress$ = this.progressSubject.asObservable(); //eslint-disable-line
    public uploadDetails$ = this.uploadSubject.asObservable(); //eslint-disable-line
    public message$ = this.messageSubject.asObservable(); //eslint-disable-line

    private pollingInterval = 2000;
    private uploadSubscription: Subscription;

    constructor(private connectApi: ConnectApiService) {}

    public ngOnDestroy(): void {
        this.progressSubject.complete();
        this.statusSubject.complete();
        this.messageSubject.complete();
        if (this.uploadSubscription) {
            this.uploadSubscription.unsubscribe();
        }
    }
    public importUsers(importUserData: IImportUserData): void {
        this.clear();
        let isProcessing = false;
        this.statusSubject.next(ImportStatus.processing);
        this.messageSubject.next(`Uploading ${importUserData.file.name}`);

        let uploadId = '';
        const upload$ = this.connectApi.bulkImportUsers(importUserData).pipe(
            tap((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        this.progressSubject.next(Math.round((event.loaded / event.total) * 100));
                        break;
                    case HttpEventType.Response:
                        isProcessing = true;
                        this.messageSubject.next(`Uploading ${importUserData.file.name} complete, processing users.`);
                }
            }),
            filter((event: HttpEvent<any>) => event.type === HttpEventType.Response),
            tap((response: HttpResponse<IBulkLoadResponse>) => {
                uploadId = response.body.jobId;
            })
        );

        const polling$ = () =>
            interval(this.pollingInterval).pipe(
                takeWhile(() => isProcessing),
                switchMap(() => this.connectApi.getImportUploadStatus(uploadId)),
                tap((summary: IUploadStatus) => {
                    this.messageSubject.next(`Processing ${summary.processedCount} of ${summary.jobSize} users`);
                    const progress = ((summary.processedCount / summary.jobSize) * 100).toFixed();
                    this.progressSubject.next(+progress);
                }),
                filter((summary: IUploadStatus) => summary.status === UploadStatus.Complete),
                tap(() => {
                    //stop the polling
                    isProcessing = false;
                })
            );

        const complete$ = () =>
            this.connectApi.getImportUploadDetails(uploadId).pipe(
                tap((uploadDetails: IUploadDetails) => {
                    uploadDetails.operation = UploadOperation.Import;

                    this.messageSubject.next('');
                    this.uploadSubject.next(uploadDetails);

                    const warnings = getWarnings(uploadDetails.events);
                    const errors = getErrors(uploadDetails.events);

                    let nextStatus = ImportStatus.success;
                    if (warnings.length) {
                        nextStatus = ImportStatus.warning;
                        this.warningMessage = `Warning: ${warnings.length} row${
                            warnings.length === 1 ? '' : 's'
                        } not uploaded.  ${uploadDetails.processedCount - (warnings.length + errors.length)} of ${
                            uploadDetails.jobSize
                        } successful`;
                    }
                    if (errors.length) {
                        nextStatus = ImportStatus.processingError;
                        this.errorMessage = `Error: ${errors.length} row${
                            errors.length === 1 ? '' : 's'
                        } not uploaded.  ${uploadDetails.processedCount - (warnings.length + errors.length)} of ${
                            uploadDetails.jobSize
                        } successful`;
                    }
                    if (errors.length && warnings.length) {
                        nextStatus = ImportStatus.errorAndWarning;
                    }
                    if (nextStatus === ImportStatus.success) {
                        this.successMessage = `Processing ${importUserData.file.name} complete!`;
                    }

                    this.statusSubject.next(nextStatus);

                    setTimeout(() => {
                        this.progressSubject.next(0);
                        this.statusSubject.next(ImportStatus.idle);
                        this.messageSubject.next('');
                    }, 5000);
                })
            );

        this.uploadSubscription = upload$
            .pipe(
                switchMap(polling$),
                switchMap(complete$),
                catchError((error) => {
                    let errorMessage = '';
                    if (error.error && error.error.message) {
                        errorMessage = error.error.message;
                    } else {
                        errorMessage = `Error Code: ${error.status} Message: ${error.message}`;
                    }

                    this.messageSubject.next('');
                    this.statusSubject.next(ImportStatus.apiError);
                    this.errorMessage = errorMessage;
                    this.progressSubject.next(0);
                    return of({});
                })
            )
            .subscribe(() => {
                //subscription needed to kick the observable chain.
            });
    }

    public clear() {
        this.errorMessage = '';
        this.warningMessage = '';
        this.successMessage = '';
        this.statusSubject.next(ImportStatus.idle);
        this.messageSubject.next('');
        this.progressSubject.next(0);
    }
}

export interface IBulkLoadResponse {
    jobId: string;
}

export enum ImportStatus {
    idle = 'idle',
    processing = 'processing',
    success = 'success',
    apiError = 'apiError',
    processingError = 'processingError',
    warning = 'warning',
    errorAndWarning = 'errorAndWarning'
}
