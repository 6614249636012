import {Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthProvider} from '@clients/shared/auth';
import {PersistenceProvider} from '@clients/shared/persistence';

import {CONNECT_ROUTES_ENUM} from '../enum/routes.enum';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authProvider: AuthProvider, private persistence: PersistenceProvider) {}

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.validateLogin(state.url);
    }

    public validateLogin(url: string): boolean {
        if (this.authProvider.isAuthenticated()) return true;

        this.authProvider.returnUrl = url;

        this.router.navigate([CONNECT_ROUTES_ENUM.signIn]);
        return false;
    }
}
