<form autocomplete="off">
    <mat-vertical-stepper linear="true" #stepper disableRipple="true">
        <mat-step editable="false" completed="false">
            <ng-template matStepLabel>
                <div class="step-text">
                    Enter Enrollee Information
                    <div class="instruction-text">Please fill out all fields below.</div>
                </div>
            </ng-template>
            <div class="step-content" [formGroup]="enrolleeForm">
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input
                        data-e2e="firstName"
                        autocomplete="none"
                        matInput
                        placeholder="First Name"
                        formControlName="firstName"
                        type="input"
                    />
                    <mat-hint>As it appears on their medicare card</mat-hint>
                </mat-form-field>

                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input
                        data-e2e="lastName"
                        autocomplete="none"
                        matInput
                        placeholder="Last Name"
                        formControlName="lastName"
                        type="input"
                    />
                    <mat-hint>As it appears on their medicare card</mat-hint>
                </mat-form-field>

                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Date of Birth</mat-label>
                    <input
                        data-e2e="dateOfBirth"
                        autocomplete="none"
                        matInput
                        placeholder="MM-DD-YYYY"
                        formControlName="dateOfBirth"
                        type="input"
                        mask="date"
                    />
                    <mat-hint>MM-DD-YYYY</mat-hint>
                </mat-form-field>

                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Phone Number</mat-label>
                    <input
                        data-e2e="phoneNumber"
                        autocomplete="none"
                        matInput
                        placeholder="555-555-5555"
                        formControlName="phoneNumber"
                        type="input"
                        mask="phone"
                    />
                    <mat-hint>555-555-5555</mat-hint>
                </mat-form-field>

                <mat-form-field class="form-field no-hint" appearance="outline">
                    <mat-label>State</mat-label>
                    <input
                        data-e2e="stateControl"
                        autocomplete="none"
                        matInput
                        placeholder="State"
                        aria-label="State"
                        [matAutocomplete]="auto"
                        formControlName="state"
                    />
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let state of filteredStates | async" [value]="state.name">
                            <span>{{ state.name }}</span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>MBI (Medicare Number)</mat-label>
                    <input
                        data-e2e="mbi"
                        autocomplete="none"
                        matInput
                        placeholder="MBI (Medicare Number)"
                        formControlName="mbi"
                        type="input"
                        mask="mbiMask"
                    />
                    <mat-hint>11 digit code e.g. 1EG4-TE5-MK73</mat-hint>
                </mat-form-field>
            </div>
            <div *ngIf="errorMessage" class="new-enrollee-errors" data-e2e="select-assessment-error">
                {{ errorMessage }}
            </div>
            <div>
                <button mat-button color="primary" type="button" (click)="cancel()" data-e2e="enrollee-cancel-btn">
                    Cancel
                </button>

                <button
                    mat-raised-button
                    color="primary"
                    [disabled]="!enrolleeForm.valid"
                    data-e2e="enrollee-next-btn"
                    (click)="next(stepper)"
                >
                    Next
                </button>
            </div>
        </mat-step>
        <mat-step editable="false" completed="false">
            <ng-template matStepLabel><div class="step-text">Select Assessment</div></ng-template>

            <div class="step-content assessment-step-content" [formGroup]="assessmentForm">
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Select Assessment</mat-label>
                    <mat-select class="revel-select" formControlName="configurationId" data-e2e="configurationId">
                        <mat-option *ngFor="let survey of surveys" [value]="survey.id">{{
                            survey.displayName
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="errorMessage" class="new-enrollee-errors" data-e2e="select-assessment-error">
                {{ errorMessage }}
            </div>
            <div>
                <button mat-button type="button" color="primary" data-e2e="assessment-back-btn" (click)="back(stepper)">
                    Back
                </button>

                <button
                    type="button"
                    mat-raised-button
                    color="primary"
                    [disabled]="!assessmentForm.valid"
                    data-e2e="assessment-next-btn"
                    (click)="submit(stepper)"
                >
                    Next
                </button>
            </div>
        </mat-step>
        <mat-step editable="false" completed="false">
            <ng-template matStepLabel><div class="step-text">Confirmation & Take Assessment</div></ng-template>

            <div *ngIf="enrollee" class="step-content step-text">
                <h4>Enrollee Information</h4>
                <div class="summary">
                    <div>{{ enrollee.firstName }}</div>
                    <div>{{ enrollee.lastName }}</div>
                    <div>{{ enrollee.dateOfBirth }}</div>
                    <div>{{ enrollee.phoneNumber }}</div>
                    <div>{{ enrollee.state }}</div>
                    <div>{{ enrollee.mbi }}</div>
                </div>
                <h4>Assessment</h4>
                <div class="assessment-summary">{{ enrollee.surveyName }}</div>
                <div class="instruction-text">
                    This will open in a new browser tab and take approximately 5-10 minutes to complete.
                </div>
            </div>
            <div class="take-assessment-buttons">
                <button
                    mat-button
                    color="primary"
                    type="button"
                    data-e2e="confirmation-back-btn"
                    (click)="back(stepper)"
                >
                    Back
                </button>
                <button
                    mat-raised-button
                    type="button"
                    color="primary"
                    data-e2e="take-new-enrollee-assessment"
                    [disabled]="!enrolleeForm.valid || !assessmentForm.valid"
                    (click)="loadAssessment()"
                >
                    <div class="new-enrollee-submit-content">
                        <div>Take assessment &nbsp;</div>
                        <mat-icon inline="true" aria-hidden="false" aria-label="open external tab">launch</mat-icon>
                    </div>
                </button>
            </div>
        </mat-step>
    </mat-vertical-stepper>
</form>
