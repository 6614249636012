import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthProvider} from '@clients/shared/auth';
import {UntilComponentDestroyed} from '@clients/shared/common-forms';

import {CONNECT_ROUTES_ENUM} from '../../enum/routes.enum';
import {RedirectService} from '../../services/redirect.service';

@Component({
    selector: 'rc-sso-sign-in',
    template: `
        <div class="sign-in-background">
            <ng-container *ngIf="isLoading">
                <rc-spinner
                    [color]="color"
                    [mode]="spinnerMode"
                    [value]="50"
                    [backdropEnabled]="true"
                    [positionGloballyCenter]="true"
                    [displayProgressSpinner]="true"
                ></rc-spinner>
            </ng-container>
        </div>
    `,
    styleUrls: ['./sign-in.component.scss']
})
export class SSOSignInComponent extends UntilComponentDestroyed implements OnInit {
    public color = 'accent';
    public spinnerMode = 'indeterminate';
    public isLoading = true;

    constructor(
        private authProvider: AuthProvider,
        private router: Router,
        private route: ActivatedRoute,
        private redirectService: RedirectService
    ) {
        super();
    }

    public ngOnInit() {
        const {
            params: {connection},
            queryParams: {returnUrl}
        } = this.route.snapshot;

        if (this.authProvider.isAuthenticated()) {
            const location = this.redirectService.buildRedirect(returnUrl, CONNECT_ROUTES_ENUM.agentDashboard);
            this.router.navigate([location.route], location.queryParams ? {queryParams: location.queryParams} : {});
            this.isLoading = false;
        } else {
            this.authProvider.returnUrl = returnUrl; //set the redirect url
            this.authProvider.authorize({connection});
        }
    }
}
