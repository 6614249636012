import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

import {DataSource} from '@angular/cdk/collections';
import {IUser, IUsers} from '@clients/connect/connect-common';

import {ConnectApiService} from '../../../services/connect-api.service';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class UserDataSource implements DataSource<IUser> {
    private userSubject = new BehaviorSubject<IUser[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private errorSubject = new BehaviorSubject<boolean>(false);
    private userCountSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable(); // eslint-disable-line

    public error$ = this.errorSubject.asObservable(); // eslint-disable-line

    public userCount$ = this.userCountSubject.asObservable(); // eslint-disable-line

    constructor(private connectApi: ConnectApiService) {}

    public connect(): Observable<IUser[]> {
        return this.userSubject.asObservable();
    }

    public disconnect(): void {
        this.userSubject.complete();
        this.loadingSubject.complete();
        this.errorSubject.complete();
        this.userCountSubject.complete();
    }

    public loadUsers(companyId, filterText, pageNumber: number, pageSize: number): void {
        this.loadingSubject.next(true);
        this.errorSubject.next(false);

        this.connectApi
            .getUsers(companyId, filterText, pageNumber, pageSize)
            .pipe(
                catchError(() => {
                    this.userCountSubject.next(0);
                    this.errorSubject.next(true);
                    return of([]);
                }),
                finalize(() => {
                    this.loadingSubject.next(false);
                })
            )
            .subscribe((userResponse: IUsers) => {
                this.userSubject.next(userResponse.users);
                this.userCountSubject.next(userResponse.total);
            });
    }
}
