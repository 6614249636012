export enum PERMISSIONS_ENUM {
    //Company
    multiCompany = 'multi-company',
    createCompany = 'create:company',
    readCompany = 'read:company',
    updateCompany = 'update:company',
    deleteCompany = 'delete:company',

    //User
    createUser = 'create:user',
    readUser = 'read:user',
    updateUser = 'update:user',
    deleteUser = 'delete:user',
    readUserManagement = 'read:user-management',
    writeUserManagement = 'write:user-management',

    //Agent
    createSurveyAgentLink = 'create:survey-agent-link',

    //Survey
    readSurveySurveyConfig = 'read:survey-config',

    //Prospect
    readProspect = 'read:prospect'
}
