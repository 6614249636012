import {Action} from '@clients/shared/models';
import {type} from '@clients/shared/utilities';

export const AnalyticsActionTypes = {
    EVENT_TRACK: type('[analytics] event track'),
    SET_USERNAME: type('[analytics] set username'),
    SET_USER_PROPERTIES: type('[analytics] set user properties'),
    SET_USER_PROPERTIES_ONCE: type('[analytics] set user properties once'),
    SET_SUPER_PROPERTIES: type('[analytics] set super properties'),
    SET_SUPER_PROPERTIES_ONCE: type('[analytics] set super properties once'),
    ENABLE_TRACKING: type('[analytics] enable tracking'),
    DISABLE_TRACKING: type('[analytics] disable tracking')
};

export interface EventTrackPayload {
    eventName: string;
    properties?: any;
}

export class EnableTrackingAction implements Action {
    public readonly type = AnalyticsActionTypes.ENABLE_TRACKING;
}

export class DisableTrackingAction implements Action {
    public readonly type = AnalyticsActionTypes.DISABLE_TRACKING;
}

export class EventTrackAction implements Action<EventTrackPayload> {
    public readonly type = AnalyticsActionTypes.EVENT_TRACK;

    constructor(public payload: EventTrackPayload) {}
}

export class SetUsernameAction implements Action<string | number> {
    public readonly type = AnalyticsActionTypes.SET_USERNAME;

    constructor(public payload: string | number) {}
}

export class SetUserPropertiesAction implements Action<any> {
    public readonly type = AnalyticsActionTypes.SET_USER_PROPERTIES;

    constructor(public payload: any) {}
}

export class SetSuperPropertiesAction implements Action<any> {
    public readonly type = AnalyticsActionTypes.SET_SUPER_PROPERTIES;

    constructor(public payload: any) {}
}
