import {tap} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {ConnectApiService} from '../../services/connect-api.service';
import {ConnectAppActionTypes, RouterNavigateAction} from './app.actions';

@Injectable()
export class AppEffects {
    routerNavigate = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ConnectAppActionTypes.ROUTER_NAVIGATE),
                tap((action: RouterNavigateAction) =>
                    this.router.navigate([action.payload.route], action.payload.options || {})
                )
            ),
        {dispatch: false}
    );

    constructor(private actions$: Actions, private router: Router, private connectApi: ConnectApiService) {}
}
