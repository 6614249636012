import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

import {CustomIconService} from './services/custom-icon.service';

@NgModule({
    imports: [CommonModule, MatIconModule],
    providers: [CustomIconService]
})
export class CustomIconsModule {
    static forRoot(): ModuleWithProviders<CustomIconsModule> {
        return {
            ngModule: CustomIconsModule,
            providers: [{provide: CustomIconService, useClass: CustomIconService}]
        };
    }
}
