import {Component, Input} from '@angular/core';

import {ConnectFacade} from '../../../+state/connect.facade';

@Component({
    selector: 'rc-sn-toggle-button',
    template: `
        <button mat-icon-button class="rc-sidenav-toggle" (click)="toggleClicked()">
            <mat-icon class="icon" *ngIf="expanded">arrow_left</mat-icon>
            <mat-icon class="icon" *ngIf="!expanded">arrow_right</mat-icon>
            {{ text() }}
        </button>
    `,
    styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent {
    @Input()
    public expanded = true;

    constructor(private connectFacade: ConnectFacade) {}

    toggleClicked() {
        this.connectFacade.toggleNav();
    }

    text() {
        return this.expanded ? 'Collapse Menu' : '';
    }
}
