import {Component} from '@angular/core';

@Component({
    selector: 'rc-access-denied',
    template: `
        <div class="error-box">
            <div class="image"><img src="/assets/images/bandage.svg" /></div>
            <div class="meta">
                <h1>Access Denied</h1>
                <h2 class="message">You are not authorized</h2>
            </div>
        </div>
    `,
    styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent {
    constructor() {}
}
