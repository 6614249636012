import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {ConnectEnvServiceProvider} from '@clients/connect/connect-common';
import {ConnectLibModule} from '@clients/connect/connect-lib';
import {Auth0Service, AuthModule} from '@clients/shared/auth';
import {CustomIconsModule} from '@clients/shared/custom-icons';
// import {NewRelicServiceInitializationProvider} from '@clients/shared/error-handling';
import {LoggingModule, SumoServiceInitializationProvider} from '@clients/shared/logging';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {MatNativeDateModule} from '@angular/material/core';

import {loggingConfig} from '../config/logging.config';
import {AppComponent} from './app.component';

// AOT doesn't like calling functions. Figure out a better way to do this,
const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        CustomIconsModule.forRoot(),
        RouterModule.forRoot([]),
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            maxAge: 100,
            logOnly: browserWindowEnv.production
        }),
        ConnectLibModule,
        AuthModule.forRoot(Auth0Service, {}),
        LoggingModule.forRoot(loggingConfig),
        MatNativeDateModule
    ],
    providers: [ConnectEnvServiceProvider, SumoServiceInitializationProvider, RouterModule],
    bootstrap: [AppComponent]
})
export class AppModule {}
