import {Subject} from 'rxjs';

import {Directive, OnDestroy} from '@angular/core';

// todo return type https://healthtel.atlassian.net/browse/SB-979
/* istanbul ignore next */
export function untilComponentDestroyed<T extends {new (...args: any[])}>(constructor: T): any {
    /* istanbul ignore next */
    return class extends constructor {
        destroyed$ = new Subject<boolean>();

        ngOnDestroy(...args) {
            this.destroyed$.next(true);
            this.destroyed$.complete();
            constructor.prototype.ngOnDestroy.apply(this, args);
        }
    };
}

@Directive()
export abstract class UntilComponentDestroyed implements OnDestroy {
    protected destroyed$: Subject<boolean> = new Subject<boolean>();

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
