import {Observable} from 'rxjs';
import {map, skipWhile} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';

import {ConnectFacade} from '../+state';
import {CONNECT_ROUTES_ENUM} from '../enum/routes.enum';
import {
    IPermissionAndFeature,
    PermissionAndFeatureAccessService
} from '../services/permission-and-feature-access.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
    constructor(
        private router: Router,
        private service: PermissionAndFeatureAccessService,
        private facade: ConnectFacade
    ) {}

    public canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.facade.getCurrentProfile().isLoaded) {
            return this.validatePermissionAndFeature(next.data.permissionAndFeature);
        }

        this.facade.loadProfile();

        //get the permission from the route data
        return this.facade.getProfile().pipe(
            skipWhile((profile) => !profile.isLoaded),
            map(() => this.validatePermissionAndFeature(next.data.permissionAndFeature))
        );
    }

    private validatePermissionAndFeature(permissionAndFeature?: IPermissionAndFeature): boolean {
        if (this.service.canAccessNow(permissionAndFeature)) return true;
        this.router.navigate([CONNECT_ROUTES_ENUM.forbidden]);
        return false;
    }
}
