import {ErrorHandler, Inject, Injectable} from '@angular/core';
import {ENV_SERVICE, EnvService} from '@clients/shared/config';

import {LOGGING_PROVIDERS} from '../logging.providers';
import {LoggingProvider} from '../models/LoggingConfig.interface';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
    private developmentMode: boolean;

    constructor(@Inject(LOGGING_PROVIDERS) private providers, @Inject(ENV_SERVICE) envService: EnvService) {
        this.developmentMode = !envService.production;
    }

    public handleError(error: any): void {
        /* istanbul ignore next */
        const err = error && error.rejection ? error.rejection : error;

        this.providers.forEach((p: LoggingProvider) => {
            const errorIsIncluded = this.errorIsIncluded(p, err);
            const errorIsExcluded = this.errorIsExcluded(p, err);
            const providerHandlesUnhandledExceptions = this.providerHandlesUnhandledExceptions(p);
            const shouldProcess = !errorIsExcluded && (errorIsIncluded || providerHandlesUnhandledExceptions);

            if (shouldProcess && p.provider.handleError) p.provider.handleError(err);
        });
    }

    private errorIsIncluded(provider: LoggingProvider, error: any) {
        if (!provider.include || !provider.include.length) return false;

        let included = false;
        provider.include.forEach((errorType) => {
            /* istanbul ignore if  */
            if (included) return;

            included = this.is(error, errorType);
        });

        return included;
    }

    private errorIsExcluded(provider: LoggingProvider, error: any): boolean {
        if (!provider.exclude || !provider.exclude.length) return false;

        let excluded = false;
        provider.exclude.forEach((errorType) => {
            /* istanbul ignore if  */
            if (excluded) return;

            excluded = this.is(error, errorType);
        });

        return excluded;
    }

    private providerHandlesUnhandledExceptions(provider: LoggingProvider): boolean {
        return provider.processUnhandledExceptions;
    }

    private is(err, type): boolean {
        return err instanceof type;
    }
}
