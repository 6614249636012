import {map} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {AuthFailureAction, AuthSuccessAction} from '@clients/shared/auth';
import {RouterNavigatePayload} from '@clients/shared/models';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {CONNECT_ROUTES_ENUM} from '../../enum/routes.enum';
import {FeatureService} from '../../services/feature.service';
import {RedirectService} from '../../services/redirect.service';
import {RouterNavigateAction} from '../app/app.actions';

@Injectable()
export class ConnectAuthEffects {
    signInSuccess = createEffect(() =>
        this.actions.pipe(
            ofType(AuthSuccessAction),
            map((action) => {
                const location = this.redirectService.buildRedirect(
                    action.returnUrl,
                    this.featureService.isAgent() ? CONNECT_ROUTES_ENUM.agentDashboard : CONNECT_ROUTES_ENUM.dashboard
                );

                return new RouterNavigateAction({
                    route: location.route,
                    options: location.queryParams ? {queryParams: location.queryParams} : null
                } as RouterNavigatePayload);
            })
        )
    );

    signInError = createEffect(() =>
        this.actions.pipe(
            ofType(AuthFailureAction),
            map((action) => {
                const route = CONNECT_ROUTES_ENUM.signIn;
                const queryParams = {...action.error};

                return new RouterNavigateAction({
                    route: route,
                    options: {queryParams: queryParams}
                });
            })
        )
    );
    constructor(
        private actions: Actions,
        private featureService: FeatureService,
        private redirectService: RedirectService
    ) {}
}
