import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

import {DataSource} from '@angular/cdk/collections';
import {IProspect, IProspects} from '@clients/connect/connect-common';

import {ConnectApiService} from '../../../../services/connect-api.service';

export class ProspectsDataSource implements DataSource<IProspect> {
    public filter: string = null;
    private prospectsSubject = new BehaviorSubject<IProspect[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private errorSubject = new BehaviorSubject<boolean>(false);
    private prospectCountSubject = new BehaviorSubject<number>(0);
    public loading$ = this.loadingSubject.asObservable(); //eslint-disable-line
    public error$ = this.errorSubject.asObservable(); //eslint-disable-line
    public prospectCount$ = this.prospectCountSubject.asObservable(); //eslint-disable-line

    constructor(private connectApi: ConnectApiService) {}

    public connect(): Observable<IProspect[]> {
        return this.prospectsSubject.asObservable();
    }

    public disconnect(): void {
        this.prospectsSubject.complete();
        this.loadingSubject.complete();
        this.errorSubject.complete();
        this.prospectCountSubject.complete();
    }

    public loadProspects(startDate, endDate, pageNumber: number, pageSize: number, filter: string): void {
        this.loadingSubject.next(true);
        this.errorSubject.next(false);

        this.connectApi
            .getProspectsMapped(startDate, endDate, pageNumber, pageSize, filter)
            .pipe(
                catchError(() => {
                    this.prospectCountSubject.next(0);
                    this.errorSubject.next(true);
                    return of([]);
                }),
                finalize(() => {
                    this.loadingSubject.next(false);
                })
            )
            .subscribe((prospectResponse: IProspects) => {
                this.prospectsSubject.next(prospectResponse.prospects);
                this.prospectCountSubject.next(prospectResponse.totalCount);
            });
    }
}
