import {takeUntil} from 'rxjs/operators';

import {Component, Input, OnInit} from '@angular/core';
import {UntilComponentDestroyed} from '@clients/shared/common-forms';

import {UploadOperation} from '../../../enum/uploads.enum';
import {IUploadDetails} from '../../../models/upload';
import {DeleteFileService, DeleteStatus} from '../../../services/delete-file.service';

@Component({
    selector: 'rc-delete-progress',
    template: `
        <div
            *ngIf="isNotIdle(fileService.status$ | async)"
            class="progress-container"
            [ngClass]="{
                complete: isComplete,
                error: isError(fileService.status$ | async),
                warning: isOnlyWarning(fileService.status$ | async),
                success: isSuccess(fileService.status$ | async)
            }"
        >
            <div class="upload-message">
                <div>
                    <span class="message">{{ fileService.message$ | async }}</span>
                    <ng-container *ngIf="(fileService.status$ | async) === DeleteStatus.success">
                        <mat-icon class="complete-icon">check_circle_outline</mat-icon>
                        <span class="message">{{ fileService.successMessage }}</span>
                    </ng-container>
                    <ng-container *ngIf="isError(fileService.status$ | async)">
                        <mat-icon class="error-message-icon">error_outline</mat-icon>
                        <span class="error-message">{{ fileService.errorMessage }}</span>
                    </ng-container>
                    <ng-container *ngIf="isWarning(fileService.status$ | async)">
                        <mat-icon class="warning-message-icon">warning</mat-icon>
                        <span class="warning-message">{{ fileService.warningMessage }} </span>
                    </ng-container>
                </div>

                <div class="close-container">
                    <rc-upload-details-dialog
                        *ngIf="isComplete"
                        [uploadDetails]="uploadDetails"
                        operation="${UploadOperation.Delete}"
                        >View</rc-upload-details-dialog
                    >
                    <div class="close" (click)="clear()"><mat-icon class="close-icon">close</mat-icon></div>
                </div>
            </div>
            <mat-progress-bar
                *ngIf="fileService.progress$ | async"
                mode="determinate"
                [value]="fileService.progress$ | async"
            ></mat-progress-bar>
        </div>
    `,
    styleUrls: ['./delete-progress.component.scss']
})
export class DeleteProgressComponent extends UntilComponentDestroyed implements OnInit {
    @Input()
    public fileService: DeleteFileService;
    public fileName: string;
    public uploadDetails: IUploadDetails;
    public isComplete: boolean;
    public DeleteStatus = DeleteStatus;
    constructor() {
        super();
    }

    ngOnInit(): void {
        if (!this.fileService) {
            throw new Error('DeleteFileService required');
        }

        this.fileService.uploadDetails$.pipe(takeUntil(this.destroyed$)).subscribe((u) => {
            this.uploadDetails = u;
            this.isComplete = true;
        });
    }

    public clear() {
        this.uploadDetails = null;
        this.isComplete = false;
        this.fileService.clear();
    }

    public isNotIdle(status: DeleteStatus) {
        return status !== DeleteStatus.idle;
    }

    public isError(status: DeleteStatus) {
        return (
            status === DeleteStatus.apiError ||
            status === DeleteStatus.processingError ||
            status === DeleteStatus.errorAndWarning
        );
    }

    public isOnlyWarning(status: DeleteStatus) {
        return status === DeleteStatus.warning;
    }

    public isWarning(status: DeleteStatus) {
        return status === DeleteStatus.warning || status === DeleteStatus.errorAndWarning;
    }

    public isSuccess(status: DeleteStatus) {
        return status === DeleteStatus.success;
    }
}
