import {Component, OnInit} from '@angular/core';

import {ConnectFacade} from '../../+state';
import {PERMISSIONS_ENUM} from '../../enum/permissions.enum';

@Component({
    selector: 'rc-users',
    template: ` <div>
        <rc-user-grid></rc-user-grid>
    </div>`,
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
    public readUserPermission = PERMISSIONS_ENUM.readUserManagement;

    constructor(private connectFacade: ConnectFacade) {}

    ngOnInit() {
        this.connectFacade.setTitle({title: 'Manage Users', secondaryTitle: ''});
    }
}
