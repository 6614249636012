import {ICompanyDetails} from '@clients/connect/connect-common';
import {Action} from '@clients/shared/models';

import {IProfileState} from './IProfileState';

export enum ProfileActionTypes {
    LOAD_PROFILE = '[Profile Effect] Load profile on app entry',
    PROFILE_LOADED = '[Profile Effect] Profile has been loaded from the api',
    PROFILE_LOAD_FAILED = '[Profile Effect] Profile has failed to load',
    CHANGE_CURRENT_COMPANY = '[Company Select] Change the current company'
}

export class LoadProfileAction implements Action {
    public readonly type = ProfileActionTypes.LOAD_PROFILE;
}

export class ProfileLoadedAction implements Action<IProfileState> {
    public readonly type = ProfileActionTypes.PROFILE_LOADED;
    constructor(public payload: IProfileState) {}
}

export class ProfileLoadFailedAction implements Action {
    public readonly type = ProfileActionTypes.PROFILE_LOAD_FAILED;
}

export class ChangeCurrentCompanyAction implements Action<ICompanyDetails> {
    public readonly type = ProfileActionTypes.CHANGE_CURRENT_COMPANY;
    constructor(public payload: ICompanyDetails) {}
}
