import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

import {BoldIcons} from '../enums/bold-icon-types';
import {CustomIcons} from '../enums/custom-icon-types';
import {RegularIcons} from '../enums/regular-icon-types';
import {ThinIcons} from '../enums/thin-icon-types';
import {WebAssetsUrl} from '../enums/web-assets-url-types';

const streamlineUrl = `${WebAssetsUrl.STREAMLINE}/icons/streamline-3-0`;

@Injectable({
    providedIn: 'root'
})
export class CustomIconService {
    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

    public registerIcons(): void {
        this.loadIcons(Object.values(BoldIcons), 'bold', `${streamlineUrl}/bold`);
        this.loadIcons(Object.values(RegularIcons), 'regular', `${streamlineUrl}/regular`);
        this.loadIcons(Object.values(ThinIcons), 'thin', `${streamlineUrl}/thin`);
        this.loadIcons(Object.values(CustomIcons), '', '../assets/svg/custom');
    }

    private loadIcons(iconKeys: string[], type: string, iconUrl: string): void {
        iconKeys.forEach((key) => {
            const keyWithNoType = type ? key.replace(`${type}-`, '') : key;

            this.matIconRegistry.addSvgIcon(
                key,
                this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${keyWithNoType}.svg`)
            );
        });
    }
}
