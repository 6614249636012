import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {Injectable} from '@angular/core';

import {ConnectFacade} from '../+state';
import {FEATURES_ENUM} from '../enum/features.enum';
import {PERMISSIONS_ENUM} from '../enum/permissions.enum';
import {PermissionsService} from './permissions.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionAndFeatureAccessService {
    constructor(private connectFacade: ConnectFacade, private permissionsService: PermissionsService) {}

    // @ts-ignore
    canAccessNow(permissionAndFeature?: IPermissionAndFeature): boolean {
        if (!permissionAndFeature) {
            return true;
        }

        const {feature, permission} = permissionAndFeature;
        const canViewFeature = !feature || this.connectFacade.getCurrentFeatureEnabled(feature);
        const hasPermission = !permission || this.permissionsService.hasPermission(permission);

        return canViewFeature && hasPermission;
    }

    canAccess(permissionAndFeature?: IPermissionAndFeature): Observable<boolean> {
        if (!permissionAndFeature) {
            return of(true);
        }
        const {feature, permission} = permissionAndFeature;

        return this.connectFacade.getFeatureEnabled(feature).pipe(
            map((featureEnabled) => {
                const canViewFeature = !feature || featureEnabled;
                const hasPermission = !permission || this.permissionsService.hasPermission(permission);

                return canViewFeature && hasPermission;
            })
        );
    }
}

export interface IPermissionAndFeature {
    permission?: PERMISSIONS_ENUM;
    feature?: FEATURES_ENUM;
}
