/* istanbul ignore file */

import {LogSeverity} from './Log.type';

export class Log {
    public message: string;
    public options: any;

    constructor(message: string, options: any, public severity: LogSeverity = LogSeverity.INFO, type: string = null) {
        this.message = this.parseMessage(message, severity, type);
        this.options = {
            ...options,
            type
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private parseMessage(message: string, severity: LogSeverity, type: string = '') {
        let prefix: string;
        switch (severity) {
            case LogSeverity.INFO:
                prefix = '[info]';
                break;
            case LogSeverity.WARNING:
                prefix = '[warning]';
                break;
            case LogSeverity.ERROR:
                prefix = '[error]';
                break;
        }

        return `${prefix} ${message}`;
    }
}
