import {type} from '@clients/shared/utilities';
import {Action} from '@clients/shared/models';
import {LogPayload} from '../models/LogPayload.interface';

export const LoggingActionTypes = {
    LOG_INFO: type('[logging] log info'),
    LOG_WARNING: type('[logging] log warning'),
    LOG_ERROR: type('[logging] log error')
};

export class LogInfoAction implements Action<LogPayload> {
    readonly type = LoggingActionTypes.LOG_INFO;

    constructor(public payload: LogPayload) {}
}

export class LogWarningAction implements Action<LogPayload> {
    readonly type = LoggingActionTypes.LOG_WARNING;

    constructor(public payload: LogPayload) {}
}

export class LogErrorAction implements Action<LogPayload> {
    readonly type = LoggingActionTypes.LOG_ERROR;

    constructor(public payload: LogPayload) {}
}
