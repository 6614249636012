import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

import {DeleteFileService, DeleteStatus} from '../../../services/delete-file.service';

@Component({
    selector: 'rc-delete-button',
    template: `
        <button
            [disabled]="(fileService.status$ | async) === processing"
            type="button"
            mat-button
            color="primary"
            (click)="fileInput.click()"
        >
            Upload Delete Users CSV
        </button>
        <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file" />
    `,
    styleUrls: ['./delete-button.component.scss']
})
export class DeleteButtonComponent implements OnInit {
    @Input()
    public fileService: DeleteFileService;

    @Input()
    public companyId: string;

    @ViewChild('fileInput') fileInput: ElementRef;

    public fileName: string;
    public fileForm: FormGroup;
    public processing = DeleteStatus.processing;
    constructor() {}

    public ngOnInit() {
        if (!this.fileService) {
            throw new Error('DeleteFileService required');
        }

        this.fileForm = new FormGroup({
            file: new FormControl(''),
            companyId: new FormControl(''),
            sendWelcomeEmails: new FormControl(true)
        });
    }

    public onFileSelected($event) {
        this.fileForm.setValue({file: $event.target.files[0], companyId: this.companyId, sendWelcomeEmails: true});
        this.fileService.deleteUsers(this.fileForm.value);

        //reset form
        this.fileInput.nativeElement.value = '';
    }
}
