import moment from 'moment';
import {takeUntil, tap} from 'rxjs/operators';

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {UntilComponentDestroyed} from '@clients/shared/common-forms';

import {ConnectFacade} from '../../../+state/connect.facade';
import {PERMISSIONS_ENUM} from '../../../enum/permissions.enum';
import {CONNECT_ROUTES_ENUM} from '../../../enum/routes.enum';
import {DateRangePickerConfiguration} from '../../../models/date-picker';
import {ConnectApiService} from '../../../services/connect-api.service';
import {DeleteFileService} from '../../../services/delete-file.service';
import {ImportFileService} from '../../../services/import-file.service';
import {IPermissionAndFeature} from '../../../services/permission-and-feature-access.service';
import {UserExportService} from '../../../services/user-export.service';
import {UserDataSource} from './user-data-source';
import {IUploadDetails} from '../../../models/upload';
import {IUser} from '@clients/connect/connect-common';
import {UploadEventType} from '../../../enum/uploads.enum';

@Component({
    selector: 'rc-user-grid',
    template: `
        <div class="table-header">
            <div class="search" [formGroup]="searchForm" (keyup.enter)="search()">
                <ng-container *applyPermissionAndFeature="createPermissionAndFeature">
                    <button
                        mat-raised-button
                        class="revel-raised-button add-user-button"
                        color="primary"
                        (click)="addUser()"
                        data-e2e="addUser"
                    >
                        Add user
                    </button>
                </ng-container>
                <mat-form-field class="form-field search-field" appearance="outline">
                    <mat-label>Search</mat-label>
                    <input data-e2e="search" autocomplete="none" matInput formControlName="search" type="input" />
                </mat-form-field>
                <button
                    mat-raised-button
                    class="revel-raised-button search-button"
                    color="primary"
                    (click)="search()"
                    data-e2e="search"
                >
                    Search
                </button>
            </div>
            <div>
                <rc-import-button [fileService]="importFileService" [companyId]="companyId"></rc-import-button>
                <rc-delete-button [fileService]="deleteFileService" [companyId]="companyId"></rc-delete-button>
                <rc-export-button [userExportService]="userExportService" [companyId]="companyId"></rc-export-button>
            </div>
        </div>
        <div *ngIf="dataSource.error$ | async" class="user-errors" data-e2e="user-error">
            Oops! Something when wrong. Please try again.
        </div>
        <rc-spinner
            [mode]="'indeterminate'"
            [value]="50"
            [backdropEnabled]="true"
            [positionGloballyCenter]="true"
            [displayProgressSpinner]="dataSource.loading$ | async"
        ></rc-spinner>
        <rc-import-progress [fileService]="importFileService"></rc-import-progress>
        <rc-delete-progress [fileService]="deleteFileService"></rc-delete-progress>
        <rc-export-progress [userExportService]="userExportService"></rc-export-progress>
        <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div class="name">{{ row.data.firstName }} {{ row.data.lastName }}</div>
                    <div>{{ row.data.email }}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="externalIdentifier">
                <mat-header-cell *matHeaderCellDef> External identifier </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.data.externalIdentifier }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lastLogin">
                <mat-header-cell *matHeaderCellDef> Last sign in </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.lastLogin | date : 'short' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="roles">
                <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.data.roles.join(', ') }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="blocked">
                <mat-header-cell *matHeaderCellDef> Access </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.blocked ? 'deactivated' : 'active' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <ng-container *ngIf="row.sso">
                        <a routerLink="/user/{{ row.id }}/view" routerLinkActive="active" class="link"> View </a>
                    </ng-container>
                    <ng-container *ngIf="!row.sso">
                        <a routerLink="/user/{{ row.id }}/view" routerLinkActive="active" class="link"> View </a>
                        <ng-container *applyPermissionAndFeature="updatePermissionAndFeature">
                            <div class="mat-column-spacer">|</div>
                            <a routerLink="/user/{{ row.id }}/edit" routerLinkActive="active" class="link"> Edit </a>
                        </ng-container>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <mat-header-row
                *matHeaderRowDef="['name', 'externalIdentifier', 'lastLogin', 'roles', 'blocked', 'action']"
            ></mat-header-row>
            <mat-row
                *matRowDef="let row; columns: ['name', 'externalIdentifier', 'lastLogin', 'roles', 'blocked', 'action']"
            ></mat-row>
        </mat-table>
        <mat-paginator
            [length]="dataSource.userCount$ | async"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            aria-label="Select page"
        >
        </mat-paginator>
        <div *ngIf="showSizeLimitMessage(dataSource.userCount$ | async)" class="size-limit-message">
            <strong>Note:&nbsp;</strong> The page results shown yield only 1000 users at a time. Please refine your
            search by adding more criteria to the search bar or export all users.
        </div>
    `,
    styleUrls: ['./user-grid.component.scss']
})
export class UserGridComponent extends UntilComponentDestroyed implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public startDate: Date;
    public endDate: Date;
    public searchForm: FormGroup;
    public pageSize = 10;
    public pageSizeOptions = [10, 25, 50];
    public createPermissionAndFeature: IPermissionAndFeature = {
        permission: PERMISSIONS_ENUM.createUser
    };
    public updatePermissionAndFeature: IPermissionAndFeature = {
        permission: PERMISSIONS_ENUM.updateUser
    };

    public companyId: string;
    public dataSource: UserDataSource;
    public importFileService: ImportFileService;
    public deleteFileService: DeleteFileService;
    public userExportService: UserExportService;
    private users: IUser[];

    constructor(private connectApi: ConnectApiService, private facade: ConnectFacade, private router: Router) {
        super();
    }

    public ngOnInit() {
        this.companyId = this.facade.getCurrentCompany().companyId;

        this.searchForm = new FormGroup({
            search: new FormControl('', null)
        });

        const today = new Date();
        this.startDate = moment(today).subtract(60, 'days').toDate();
        this.endDate = today;

        this.importFileService = new ImportFileService(this.connectApi);
        this.deleteFileService = new DeleteFileService(this.connectApi);
        this.userExportService = new UserExportService(this.connectApi);

        this.dataSource = new UserDataSource(this.connectApi);
        this.dataSource.connect().subscribe((users) => (this.users = users));
        this.dataSource.loadUsers(this.companyId, '', 0, this.pageSize);

        this.importFileService.uploadDetails$.subscribe((details) => this.refreshUsers(details));
        this.deleteFileService.uploadDetails$.subscribe((details) => this.refreshUsers(details));
    }

    public ngAfterViewInit() {
        if (this.dataSource) {
            this.paginator.page
                .pipe(
                    takeUntil(this.destroyed$),
                    tap(() => this.loadUsers())
                )
                .subscribe();
        }
    }

    public search() {
        this.loadUsers();
    }

    public addUser() {
        this.router.navigate([CONNECT_ROUTES_ENUM.addUser]);
    }

    public applyDateFilter($event: DateRangePickerConfiguration) {
        this.startDate = $event.start;
        this.endDate = $event.end;
        if (this.startDate && this.endDate) {
            this.loadUsers();
        }
    }

    public loadUsers() {
        this.dataSource.loadUsers(
            this.companyId,
            this.searchForm.value.search,
            this.paginator.pageIndex,
            this.paginator.pageSize
        );
    }

    public showSizeLimitMessage(size: number) {
        return size >= 1000;
    }

    /**
     * Refresh user table if changes have been made to a user that is currently displayed.
     * @param details Details from bulk operation.
     */
    public refreshUsers(details: IUploadDetails) {
        const successEventEmails = details.events
            .filter((event) =>
                [
                    UploadEventType.AlreadyExistsReactivated,
                    UploadEventType.AlreadyExistsWithDifferentData,
                    UploadEventType.AlreadyExistsWithDifferentDataReactivated,
                    UploadEventType.Created,
                    UploadEventType.Deleted
                ].includes(event.type)
            )
            .map((event) => event.email);
        const gridEmails = this.users.map((user) => user.data.email);

        if (successEventEmails.filter((email: string) => gridEmails.includes(email)).length) {
            this.loadUsers();
        }
    }
}
