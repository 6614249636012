export enum R_CUSTOM_BREAKPOINTS {
    PHONE_BASE = 320,
    PHONE_SMALL = 600,
    TABLET = 768,
    DESKTOP_SMALL = 1024,
    DESKTOP = 1280,
    DESKTOP_LARGE = 1920,
    DESKTOP_X_LARGE = 5000
}

export enum R_BREAKPOINTS_ALIASES {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XLG = 'xlg'
}
