import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule, Provider} from '@angular/core';
import {RouterModule} from '@angular/router';
import {EffectsModule} from '@ngrx/effects';

import {AnalyticsEffects} from './+state/analytics.effects';
import {ANALYTICS_TOKEN} from './const/analytics-config.token';
import {AnalyticsConfig} from './models/AnalyticsConfig.interface';
import {AnalyticsService} from './services/analytics.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([]),

        // enable analytics via Redux
        EffectsModule.forFeature([AnalyticsEffects])
    ]
})
export class AnalyticsModule {
    public static forRoot(providers: Provider[], config: AnalyticsConfig): ModuleWithProviders<AnalyticsModule> {
        return {
            ngModule: AnalyticsModule,
            providers: [
                AnalyticsService,
                {
                    provide: ANALYTICS_TOKEN,
                    useValue: {
                        providers,
                        config
                    }
                },
                ...providers
            ]
        };
    }
}
