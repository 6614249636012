import {Inject, Injectable, Injector} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';

import {ANALYTICS_TOKEN} from '../const/analytics-config.token';
import {AnalyticsConfig} from '../models/AnalyticsConfig.interface';
import {AnalyticsProvider} from './analytics.provider';

@Injectable()
export class AnalyticsService extends AnalyticsProvider {
    private providers = {};
    private defaultConfig: AnalyticsConfig = {
        developerMode: false,
        pageViews: {
            disable: false
        }
    };
    private config: AnalyticsConfig;
    private isTrackingEnabled = false;
    private trackEventQueue: Function[] = [];

    constructor(
        @Inject(ANALYTICS_TOKEN)
        private analytics: {
            providers: AnalyticsProvider[];
            config: AnalyticsConfig;
        },
        private injector: Injector,
        private router: Router,
        private route: ActivatedRoute
    ) {
        super();

        this.analytics.providers.forEach((provider: any) => {
            this.providers[provider.service] = this.injector.get<any>(provider);
        });

        this.config = {
            ...this.defaultConfig,
            ...this.analytics.config
        };

        if (!this.config.pageViews.disable) this.beginPageTracking();
    }

    public enableTracking(): void {
        this.isTrackingEnabled = true;

        this.trackEventQueue.forEach((f: Function) => f());
        this.trackEventQueue = [];
    }

    public disableTracking(): void {
        this.isTrackingEnabled = false;
    }

    public pageTrack(path: string): void {
        if (!this.isTrackingEnabled) {
            this.trackEventQueue.push(() => this.pageTrack(path));
            return;
        }

        Object.values(this.providers).forEach((provider: AnalyticsProvider) => provider.pageTrack(path));
    }

    public eventTrack(eventName: string, properties: any = null): void {
        if (!this.isTrackingEnabled) {
            this.trackEventQueue.push(() => this.eventTrack(eventName, properties));
            return;
        }

        Object.values(this.providers).forEach((provider: AnalyticsProvider) =>
            provider.eventTrack(eventName, properties)
        );
    }

    public setUsername(userId: string | number): void {
        Object.values(this.providers).forEach((provider: AnalyticsProvider) => provider.setUsername(userId));
    }

    public setUserProperties(properties: any): void {
        Object.values(this.providers).forEach((provider: AnalyticsProvider) => provider.setUserProperties(properties));
    }

    public setSuperProperties(properties: any): void {
        Object.values(this.providers).forEach((provider: AnalyticsProvider) => provider.setSuperProperties(properties));
    }

    private beginPageTracking(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.pageTrack(this.route.snapshot['_routerState'].url);
            }
        });
    }
}
