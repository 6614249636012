import emailMask from 'text-mask-addons/dist/emailMask';
import {onlyNumbers} from '@clients/shared/utilities';

// generic masks
export const threeDigitMask = [/\d/, /\d/, /\d/];
export const twoDigitMask = [/\d/, /\d/];
export const oneDigitMask = [/\d/];

export function weightMask(value: string) {
    const numbersOnly = onlyNumbers(value);
    if (numbersOnly && numbersOnly.length === 1) return oneDigitMask;
    if (numbersOnly && numbersOnly.length === 2) return twoDigitMask;

    return threeDigitMask;
}

export enum INPUT_MASKS {
    PHONE = 'PHONE',
    SSN = 'SSN',
    ZIP = 'ZIP',
    EMAIL = 'EMAIL',
    DATE = 'DATE'
}

const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const ssnMask = [/[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/];
const zipMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

export const inputMasks = {
    // misc
    phone: phoneMask,
    ssn: ssnMask,
    zip: zipMask,
    email: emailMask,

    [INPUT_MASKS.PHONE]: phoneMask,
    [INPUT_MASKS.SSN]: ssnMask,
    [INPUT_MASKS.ZIP]: zipMask,
    [INPUT_MASKS.EMAIL]: emailMask,

    // dates
    date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    year: [/\d/, /\d/, /\d/, /\d/],
    month: [/\d/, /\d/],

    // height and weight
    heightInFeet: [/[0-7]/],
    heightInInches: [/\d/, /\d/],
    weight: weightMask,

    // generic
    oneDigitMask,
    twoDigitMask,
    threeDigitMask
};

// todo | combine to make padded number mask with min and max value https://healthtel.atlassian.net/browse/SB-985
export function heightInInchesPipe(conformedValue) {
    let valueAsNumbers;
    let valueAsInt;

    try {
        valueAsNumbers = onlyNumbers(conformedValue);
        valueAsInt = parseInt(valueAsNumbers, 10);
    } catch (e) {
        return false;
    }

    if (valueAsInt > 11) return false;

    if (valueAsNumbers[0] !== '0' && valueAsInt > 1 && valueAsInt < 10) {
        return {
            value: `0${valueAsNumbers}`
        };
    }

    return {
        value: valueAsNumbers
    };
}

// todo | combine to make padded number mask with min and max value https://healthtel.atlassian.net/browse/SB-985
export function monthPipe(conformedValue) {
    let valueAsNumbers;
    let valueAsInt;

    try {
        valueAsNumbers = onlyNumbers(conformedValue);
        valueAsInt = parseInt(valueAsNumbers, 10);
    } catch (e) {
        return false;
    }

    if (valueAsInt > 12) return false;

    if (valueAsNumbers[0] !== '0' && valueAsInt > 1 && valueAsInt < 10) {
        return {
            value: `0${valueAsNumbers}`
        };
    }

    return {
        value: valueAsNumbers
    };
}
