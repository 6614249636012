import {EnvService} from './env.service';

export const ENV_SERVICE = 'ENV_SERVICE';

export function envServiceFactory() {
    // Create env
    const envService = new EnvService();

    copyEnvFileValuesTo(envService);

    return envService;
}

export const EnvServiceProvider = {
    provide: ENV_SERVICE,
    useFactory: envServiceFactory,
    deps: []
};

export function copyEnvFileValuesTo(destination) {
    // Read environment variables from browser window
    const browserWindow = window || {};
    const browserWindowEnv = browserWindow['__env'] || {};

    // Assign environment variables from browser window to env
    // In the current implementation, properties from env.js overwrite defaults from the EnvService.
    // If needed, a deep merge can be performed here to merge properties instead of overwriting them.
    for (const key in browserWindowEnv) {
        // eslint-disable-next-line no-prototype-builtins
        if (browserWindowEnv.hasOwnProperty(key)) {
            destination[key] = window['__env'][key];
        }
    }
}
