import {Subscription} from 'rxjs';

import {Directive, OnDestroy} from '@angular/core';
import {MediaChange, MediaObserver} from '@angular/flex-layout';

@Directive()
export class WithMediaComponent implements OnDestroy {
    public activeMediaQuery: string;
    private mediaWatcher: Subscription;

    constructor() {}

    setupMediaWatcher(media: MediaObserver, callback: (value: MediaChange[]) => void) {
        this.mediaWatcher = media.asObservable().subscribe(callback);
    }

    ngOnDestroy() {
        this.mediaWatcher.unsubscribe();
    }
}
