import {Inject, Injectable} from '@angular/core';
import {ENV_SERVICE, EnvService} from '@clients/shared/config';

import {LOGGING_PROVIDERS} from '../logging.providers';
import {Log} from '../models/Log.interface';
import {LogSeverity} from '../models/Log.type';
import {LoggingProvider} from '../models/LoggingConfig.interface';

@Injectable()
export class Logger {
    private developmentMode: boolean;

    constructor(@Inject(LOGGING_PROVIDERS) private providers, @Inject(ENV_SERVICE) envService: EnvService) {
        this.developmentMode = !envService.production;
    }

    public log(message: string, options: any = null, category: LogSeverity = LogSeverity.INFO, type: string = null) {
        const log = new Log(message, options, category, type);

        if (this.developmentMode) {
            switch (category) {
                case LogSeverity.INFO:
                    console.log(log.message, log.options);
                    break;
                case LogSeverity.WARNING:
                    console.warn(log.message, log.options);
                    break;
                case LogSeverity.ERROR:
                    console.error(log.message, log.options);
                    break;
            }
        }

        this.providers.forEach((p: LoggingProvider) => {
            if (!p.processLogs) return;

            p.provider.log(log);
        });
    }
}
