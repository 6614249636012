import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

import {DateRangePickerConfiguration} from '../../../../models/date-picker';

@Component({
    selector: 'rc-date-picker',
    template: `
        <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input
                [formGroup]="dateRangeFormGroup"
                [rangePicker]="picker"
                [min]="minDate"
                [max]="maxDate"
            >
                <input
                    matStartDate
                    formControlName="start"
                    placeholder="Start date"
                    (dateChange)="filterOnAssessmentDate()"
                />
                <input
                    matEndDate
                    formControlName="end"
                    placeholder="End date"
                    (dateChange)="filterOnAssessmentDate()"
                />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="dateRangeFormGroup.controls.start.hasError('matStartDateInvalid')"
                >Invalid start date
            </mat-error>
            <mat-error *ngIf="dateRangeFormGroup.controls.end.hasError('matEndDateInvalid')"
                >Invalid end date
            </mat-error>
        </mat-form-field>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
    @Input()
    public startDate: Date;
    @Input()
    public endDate: Date;
    @Input()
    public minDate: Date;
    @Input()
    public maxDate: Date;
    @Output()
    public dateFilterChangeAction: EventEmitter<DateRangePickerConfiguration> =
        new EventEmitter<DateRangePickerConfiguration>();

    dateRangeFormGroup: FormGroup;

    ngOnInit() {
        this.dateRangeFormGroup = new FormGroup({
            start: new FormControl(this.startDate),
            end: new FormControl(this.endDate)
        });
    }

    filterOnAssessmentDate() {
        if (this.dateRangeFormGroup.controls['start'].dirty || this.dateRangeFormGroup.controls['end'].dirty) {
            this.dateFilterChangeAction.emit(this.dateRangeFormGroup.value);
        }
    }
}
