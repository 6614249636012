import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RegularIcons} from '@clients/shared/custom-icons';

import {ConnectFacade} from '../../../+state/connect.facade';
import {PERMISSIONS_ENUM} from '../../../enum/permissions.enum';
import {CONNECT_ROUTES_ENUM} from '../../../enum/routes.enum';
import {IPermissionAndFeature} from '../../../services/permission-and-feature-access.service';

@Component({
    selector: 'rc-agent-dashboard-home',
    template: `
        <button
            data-e2e="add-enrollee-btn"
            mat-stroked-button
            class="new-enrollment-button revel-stroked-button"
            mat-raised-button
            color="primary"
            (click)="showEnrollmentForm()"
        >
            <mat-icon class="add-icon" [svgIcon]="icons.ACTIONS_ADD" inline></mat-icon>
            <span> Add enrollee</span>
        </button>
        <rc-prospects-grid class="data-grid" *applyPermissionAndFeature="permissionAndFeature"></rc-prospects-grid>
    `,
    styleUrls: ['./agent-dashboard.component.scss']
})
export class AgentDashboardComponent implements OnInit {
    public icons = RegularIcons;

    public permissionAndFeature: IPermissionAndFeature = {
        permission: PERMISSIONS_ENUM.readProspect
    };
    constructor(private connectFacade: ConnectFacade, private router: Router) {}

    ngOnInit() {
        this.connectFacade.setTitle({title: 'Enrollment Management', secondaryTitle: ''});
    }

    showEnrollmentForm() {
        this.router.navigate([CONNECT_ROUTES_ENUM.newEnrollment]);
    }
}
