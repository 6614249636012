import {Component, OnInit} from '@angular/core';

import {ConnectFacade} from '../../+state/connect.facade';

@Component({
    selector: 'rc-dashboard',
    template: ` <div fxFlex fxLayoutAlign="space-between"></div> `,
    styles: []
})
export class DashboardComponent implements OnInit {
    constructor(private connectFacade: ConnectFacade) {}

    ngOnInit() {
        this.connectFacade.setTitle({title: 'Dashboard'});
    }
}
