import {CookiesService} from '../services/cookies.service';
import {PersistenceProvider} from '../services/persistence.provider';
import {MockProviderService} from '../testing/mock-provider.service';
import {LocalStorageService} from '../services/local-storage.service';

export const PERSISTENCE_COOKIES = {
    provide: PersistenceProvider,
    useClass: CookiesService
};

export const PERSISTENCE_LOCAL_STORAGE = {
    provide: PersistenceProvider,
    useClass: LocalStorageService
};

export const PERSISTENCE_TESTING = {
    provide: PersistenceProvider,
    useClass: MockProviderService
};
