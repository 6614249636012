import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LayoutComponent} from './components/layout/layout.component';
import {ContainerComponent} from './components/container/container.component';
import {ElementComponent} from './components/element/element.component';
import {R_BREAKPOINTS} from './config/breakpoints';

@NgModule({
    imports: [CommonModule, FlexLayoutModule.withConfig({disableDefaultBps: true}, R_BREAKPOINTS)],
    declarations: [ContainerComponent, LayoutComponent, ElementComponent],
    exports: [ContainerComponent, LayoutComponent, ElementComponent, FlexLayoutModule]
})
export class LayoutGridModule {}
