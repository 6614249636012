import {some as _some} from 'lodash';

import {Injectable} from '@angular/core';
import {AuthProvider} from '@clients/shared/auth';

import {PERMISSIONS_ENUM} from '../enum/permissions.enum';
import {TokenParserService} from './token-parser.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {
    constructor(private authProvider: AuthProvider, private tokenParser: TokenParserService) {}

    /**
     * @deprecated
     */
    public hasCreateAgentLink(): boolean {
        return _some(this.getPermissions(), (p) => p === PERMISSIONS_ENUM.createSurveyAgentLink);
    }

    /**
     * @deprecated
     */
    public hasMultiCompany(): boolean {
        return _some(this.getPermissions(), (p) => p === PERMISSIONS_ENUM.multiCompany);
    }

    public hasPermission(permission: PERMISSIONS_ENUM) {
        return _some(this.getPermissions(), (p) => p === permission);
    }

    private getPermissions(): string[] {
        return this.tokenParser.getPermissions(this.authProvider.getToken());
    }
}
