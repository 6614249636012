import {Component} from '@angular/core';

@Component({
    selector: 'rc-not-found',
    template: `
        <div class="error-box">
            <div class="image"><img src="/assets/images/bandage.svg" /></div>
            <div class="meta">
                <h1>Not Found.</h1>
                <h2 class="message">Please try again.</h2>
            </div>
        </div>
    `,
    styles: []
})
export class NotFoundComponent {
    constructor() {}
}
