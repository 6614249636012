import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EMAIL_REGEX} from '@clients/shared/utilities';

@Component({
    selector: 'rc-username',
    template: `
        <div class="username" [formGroup]="usernameGroup" (keyup.enter)="submitUsername()">
            <div class="form">
                <mat-form-field appearance="outline">
                    <mat-label>Username</mat-label>
                    <input
                        formControlName="username"
                        data-e2e="username"
                        autocomplete="username"
                        matInput
                        placeholder="example@email.com"
                        type="text"
                    />
                    <mat-hint>example@email.com</mat-hint>
                </mat-form-field>
                <rc-sign-in-error [error]="error"></rc-sign-in-error>
            </div>
            <rc-sign-in-actions>
                <button
                    mat-raised-button
                    class="revel-raised-button"
                    color="primary"
                    (click)="submitUsername()"
                    data-e2e="next"
                >
                    Next
                </button>
            </rc-sign-in-actions>
        </div>
    `,
    styleUrls: ['./username.component.scss']
})
export class UsernameComponent implements OnInit, OnChanges {
    @Input()
    public initialUsername: string;

    @Input()
    public error: string;

    @Output()
    public username: EventEmitter<string> = new EventEmitter<string>();
    public usernameGroup: FormGroup;

    constructor() {
        this.usernameGroup = new FormGroup({
            username: new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)])
        });
    }
    get usernameControl(): FormControl {
        return this.usernameGroup.get('username') as FormControl;
    }

    public ngOnInit(): void {
        if (this.initialUsername) {
            this.usernameControl.setValue(this.initialUsername);
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.error && changes.error.currentValue) {
            this.usernameControl.setErrors({incorrect: true});
            this.usernameGroup.updateValueAndValidity();
        }
    }

    public async submitUsername() {
        if (this.usernameGroup.valid) {
            this.username.emit(this.usernameControl.value);
        }
    }
}
