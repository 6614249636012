import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {Injector, ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {authRoutes} from './auth.routes';
import {CallbacksAuth0Component} from './components/callbacks-auth0/callbacks-auth0.component';
import {AUTH_PROVIDERS_ENUM} from './enum/authProviders.enum';
import {AuthTokenInterceptor} from './interceptors/auth-token.interceptor';
import {AuthProvider} from './services/auth.provider';

@NgModule({
    imports: [CommonModule, RouterModule.forChild(authRoutes), HttpClientModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptor,
            deps: [Injector],
            multi: true
        }
    ],
    declarations: [CallbacksAuth0Component]
})
export class AuthModule {
    static forRoot(authProvider: any, config: Auth0LockConstructorOptions): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                {provide: AuthProvider, useClass: authProvider},
                {provide: AUTH_PROVIDERS_ENUM.AuthLockOptions, useValue: config}
            ]
        };
    }
}
