import moment from 'moment';
import {Observable} from 'rxjs';

import {HttpClient, HttpEvent, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {
    ConnectEnvService,
    ICompany,
    ICompanyDetails,
    IImportUserData,
    IDeleteUserData,
    IProspects,
    ISignInOption,
    IUser,
    IUserDto,
    IUsers
} from '@clients/connect/connect-common';
import {AuthProvider, SKIP_AUTH_HEADER} from '@clients/shared/auth';
import {ENV_SERVICE} from '@clients/shared/config';

import {IEnrollmentRequest, IEnrollmentResponse, IMemberInfoResponse} from '../models/enrollment';
import {IExportJobResponse} from '../models/export';
import {ISurveyConfigSummary} from '../models/survey-config-summary';
import {IUploadDetails, IUploadStatus, IUploadSummary} from '../models/upload';

export const API_DATE_FORMAT = 'MM/DD/YYYY';

@Injectable()
export class ConnectApiService {
    private readonly companyServiceApi: string;
    private readonly surveyServiceApi: string;
    private readonly ingestServiceApi: string;

    constructor(
        @Inject(ENV_SERVICE) private envService: ConnectEnvService,
        private http: HttpClient,
        private authProvider: AuthProvider
    ) {
        this.companyServiceApi = this.envService.companyServiceApi;
        this.surveyServiceApi = this.envService.surveyServiceApi;
        this.ingestServiceApi = this.envService.ingestServiceApi;
    }

    public getCompanies(): Observable<ICompany[]> {
        return this.http.get<ICompany[]>(`${this.companyServiceApi}/company-summaries`);
    }

    public getCompanyDetails(companyId: string): Observable<ICompanyDetails> {
        return this.http.get<ICompanyDetails>(`${this.companyServiceApi}/companies/${companyId}`);
    }

    public getSignInOptions(username: string): Observable<ISignInOption[]> {
        const headers = new HttpHeaders({
            [SKIP_AUTH_HEADER]: ''
        });
        return this.http.get<ISignInOption[]>(`${this.companyServiceApi}/sign-in-options`, {
            headers: headers,
            params: {email: encodeURIComponent(username)}
        });
    }

    public getUser(userId: string): Observable<IUser> {
        return this.http.get<IUser>(`${this.companyServiceApi}/users/${userId}`);
    }

    public createUser(payload: IUserDto): Observable<Object> {
        return this.http.post(`${this.companyServiceApi}/users`, payload);
    }

    public updateUser(userId: string, payload: IUserDto): Observable<Object> {
        return this.http.put(`${this.companyServiceApi}/users/${userId}`, payload);
    }

    public postNewEnrollment(enrollment: IEnrollmentRequest): Observable<IEnrollmentResponse> {
        return this.http.post<IEnrollmentResponse>(`${this.surveyServiceApi}/agent-links`, enrollment);
    }

    public getAgentSurveySummaries(): Observable<ISurveyConfigSummary[]> {
        return this.http.get<ISurveyConfigSummary[]>(`${this.surveyServiceApi}/config-summaries`, {
            params: {type: 'agent'}
        });
    }

    public getProspects(
        startDate: Date,
        endDate: Date,
        pageNumber: number,
        itemsPerPage: number,
        filter?: string
    ): Observable<IProspects> {
        return this.http.get<IProspects>(`${this.surveyServiceApi}/prospects`, {
            params: {
                startDate: moment(startDate).format(API_DATE_FORMAT),
                endDate: moment(endDate).format(API_DATE_FORMAT),
                pageNumber: pageNumber.toString(),
                itemsPerPage: itemsPerPage.toString(),
                advancedSearchTerm: filter ? filter : ''
            }
        });
    }

    public getProspectsMapped(
        startDate: Date,
        endDate: Date,
        pageNumber: number,
        itemsPerPage: number,
        filter?: string
    ): Observable<IProspects> {
        return this.http.get<IProspects>(`${this.surveyServiceApi}/prospects/mapped`, {
            params: {
                startDate: moment(startDate).format(API_DATE_FORMAT),
                endDate: moment(endDate).format(API_DATE_FORMAT),
                pageNumber: pageNumber.toString(),
                itemsPerPage: itemsPerPage.toString(),
                advancedSearchTerm: filter ? filter : ''
            }
        });
    }

    public getProspectsForExport(startDate: Date, endDate: Date): Observable<Blob> {
        return this.http.get(`${this.surveyServiceApi}/prospects/export`, {
            params: {
                startDate: moment(startDate).format(API_DATE_FORMAT),
                endDate: moment(endDate).format(API_DATE_FORMAT)
            },
            responseType: 'blob'
        });
    }

    public getProspectsMappedForExport(startDate: Date, endDate: Date): Observable<Blob> {
        return this.http.get(`${this.surveyServiceApi}/prospects/exportmapped`, {
            params: {
                startDate: moment(startDate).format(API_DATE_FORMAT),
                endDate: moment(endDate).format(API_DATE_FORMAT)
            },
            responseType: 'blob'
        });
    }

    public getUsers(companyId, filterText: string, pageNumber: number, itemsPerPage: number): Observable<IUsers> {
        return this.http.get<IUsers>(`${this.companyServiceApi}/users`, {
            params: {
                companyId,
                filterText,
                pageNumber: pageNumber.toString(),
                itemsPerPage: itemsPerPage.toString()
            }
        });
    }

    public bulkImportUsers(payload: IImportUserData): Observable<HttpEvent<unknown>> {
        const fileData: any = new FormData();
        fileData.append('file', payload.file);
        return this.http.post(`${this.companyServiceApi}/jobs/bulk-import`, fileData, {
            params: {
                companyId: payload.companyId,
                sendWelcomeEmails: payload.sendWelcomeEmails.toString()
            },
            reportProgress: true,
            observe: 'events'
        });
    }

    public getImportUploadStatus(uploadId): Observable<IUploadStatus> {
        return this.http.get<IUploadStatus>(`${this.companyServiceApi}/jobs/bulk-import/${uploadId}/status`);
    }

    public getImportUploadDetails(uploadId): Observable<IUploadDetails> {
        return this.http.get<IUploadDetails>(`${this.companyServiceApi}/jobs/bulk-import/${uploadId}`);
    }

    public getImportUploads(companyId: string) {
        return this.http.get<Array<IUploadSummary>>(`${this.companyServiceApi}/jobs/bulk-import/summaries`, {
            params: {
                companyId
            }
        });
    }

    public bulkDeleteUsers(payload: IDeleteUserData): Observable<HttpEvent<unknown>> {
        const fileData: any = new FormData();
        fileData.append('file', payload.file);
        return this.http.post(`${this.companyServiceApi}/jobs/bulk-delete`, fileData, {
            params: {
                companyId: payload.companyId
            },
            reportProgress: true,
            observe: 'events'
        });
    }

    public getDeleteUploadStatus(uploadId): Observable<IUploadStatus> {
        return this.http.get<IUploadStatus>(`${this.companyServiceApi}/jobs/bulk-delete/${uploadId}/status`);
    }

    public getDeleteUploadDetails(uploadId): Observable<IUploadDetails> {
        return this.http.get<IUploadDetails>(`${this.companyServiceApi}/jobs/bulk-delete/${uploadId}`);
    }

    public getDeleteUploads(companyId: string) {
        return this.http.get<Array<IUploadSummary>>(`${this.companyServiceApi}/jobs/bulk-delete/summaries`, {
            params: {
                companyId
            }
        });
    }

    public startUserExport(companyId: string): Observable<IExportJobResponse> {
        return this.http.post<IExportJobResponse>(`${this.companyServiceApi}/jobs/bulk-export/${companyId}`, '');
    }

    public getUserExportJobStatus(jobId: string): Observable<IExportJobResponse> {
        return this.http.get<IExportJobResponse>(`${this.companyServiceApi}/jobs/bulk-export/status/${jobId}`);
    }

    public getExportedUsers(jobLocation: string, companyId: string): Observable<Blob> {
        return this.http.get(`${this.companyServiceApi}/jobs/bulk-export/users/${companyId}`, {
            headers: {
                location: jobLocation
            },
            responseType: 'blob'
        });
    }

    public getMemberInfo(key): Observable<IMemberInfoResponse> {
        return this.http.get<IMemberInfoResponse>(`${this.ingestServiceApi}/member-info/${key}`);
    }
}
