import {get as _get} from 'lodash';

import {Component, Input, OnInit} from '@angular/core';

import {ISidenavListItem} from '../ISidenavListItem';
import {IPermissionAndFeature} from '../../../services/permission-and-feature-access.service';

@Component({
    selector: 'rc-sn-list-item',
    template: `
        <a
            mat-list-item
            [routerLink]="[location]"
            routerLinkActive="is-active"
            class="list-item"
            *applyPermissionAndFeature="permissionAndFeature"
        >
            <mat-icon *ngIf="!isSvg">{{ iconName }}</mat-icon>
            <mat-icon *ngIf="isSvg" [svgIcon]="iconName"></mat-icon>
            <span *ngIf="showText" class="rc-sidenav-item">{{ text }}</span>
        </a>
    `,
    styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit {
    @Input()
    public item: ISidenavListItem;
    @Input()
    public showText = true;

    public iconName: string;
    public text: string;
    public location: string;
    public isSvg: boolean;
    public permissionAndFeature?: IPermissionAndFeature;
    constructor() {}

    ngOnInit() {
        this.iconName = this.item.iconName;
        this.text = this.item.text;
        this.location = _get(this.item, 'location', '#');
        this.isSvg = !!this.item.isSvg;
        this.permissionAndFeature = this.item.permissionAndFeature;
    }
}
