import * as auth0 from 'auth0-js';
import {Guid} from 'guid-typescript';

import {Inject, Injectable, Injector} from '@angular/core';
import {ENV_SERVICE, EnvService} from '@clients/shared/config';

import {Auth0AuthError} from '../models/Auth0AuthError.interface';
import {Auth0AuthResult} from '../models/Auth0AuthResult.interface';
import {Auth0Logout} from '../models/Auth0Logout.interface';
import {AuthLocalState} from '../models/AuthLocalState';
import {AuthProvider} from './auth.provider';

@Injectable()
export class Auth0Service extends AuthProvider {
    readonly webAuth: auth0.WebAuth;

    private auth0State: string;

    constructor(injector: Injector, @Inject(ENV_SERVICE) envService: EnvService) {
        super(injector);

        this.auth0State = Guid.create().toString();
        this.webAuth = new auth0.WebAuth({
            clientID: envService.auth0.clientId,
            domain: envService.auth0.domain,
            responseType: envService.auth0.responseType, // move this into EnvService too?
            audience: envService.auth0.audience,
            redirectUri: envService.auth0.redirectUrl,
            scope: 'openid profile'
        });
    }

    public isAuthenticated(): boolean {
        const token = this.getToken();
        return !!(token && !this.isTokenExpired());
    }

    /* istanbul ignore next */
    public login(params: auth0.CrossOriginLoginOptions) {
        this.setAuthState(params);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.webAuth.login(params, (err, result: auth0.Auth0DecodedHash) => {
            if (err) {
                this.fail({
                    title: err.error,
                    description: err.description
                });
            }
        });
    }

    public authorize(params?: any) {
        this.setAuthState(params);
        this.webAuth.authorize(params);
    }

    public handleAuthentication() {
        this.webAuth.parseHash(this.handleAuthenticationCallback);
    }

    public setSession(token): void {
        super.setSession(token);
    }

    public renewSession(): Promise<string> {
        return new Promise((resolve, reject) =>
            this.webAuth.checkSession({}, (err, result: auth0.Auth0DecodedHash) => {
                if (err) {
                    reject(err);
                    return;
                }
                this.setSession(result.accessToken);
                resolve(result.accessToken);
            })
        );
    }

    public revokeSession(params: Auth0Logout = {}) {
        super.revokeSession();
        if (this.persistenceAvailable) {
            this.persistence.removeAll();
        } else {
            this.data = {};
        }
        this.logout(params);
    }

    public getTokenExpirationDate(): null | Date {
        const token = this.getToken();
        if (!token) return null;
        return this.jwtHelper.getTokenExpirationDate(token);
    }

    public isTokenExpired(): boolean {
        const token = this.getToken();
        if (!token) return true;
        return this.jwtHelper.isTokenExpired(token);
    }

    public logout(params: Auth0Logout = {}) {
        this.webAuth.logout(params);
    }

    public handle401Error() {
        this.revokeSession();
    }

    public changePassword(params: auth0.ChangePasswordOptions) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.webAuth.changePassword(params, (err, result: auth0.Auth0DecodedHash) => {
            if (err) {
                this.fail({
                    title: err.error,
                    description: err.description
                });
            }
        });
    }

    private setAuthState(params?: any) {
        if (params) {
            params['state'] = this.auth0State;
        } else {
            params = {state: this.auth0State};
        }

        const auth0LocalState: AuthLocalState = {returnUrl: this.returnUrl};
        if (this.persistenceAvailable) {
            this.persistence.set(this.auth0State, JSON.stringify(auth0LocalState));
        } else {
            this.data[this.auth0State] = auth0LocalState;
        }
    }
    private getAuthState(state: string) {
        this.auth0State = state;
        let url: string = null;
        if (this.persistenceAvailable) {
            url = (JSON.parse(this.persistence.get(state)) as AuthLocalState)?.returnUrl;
        } else {
            url = (this.data[state] as AuthLocalState)?.returnUrl;
        }
        this.returnUrl = url;
    }

    private handleAuthenticationCallback = (err: Auth0AuthError, authResult: Auth0AuthResult) => {
        if (authResult && authResult.accessToken) {
            this.getAuthState(authResult.state);
            this.success(authResult.accessToken, this.returnUrl);
        } else if (err) {
            this.fail({
                title: err.error,
                description: err.errorDescription,
                state: err.state
            });
        }
    };
}
