export interface IExportJobResponse {
    id: string;
    email: string;
    type: string;
    status: string;
    connection_id: string;
    connection: string;
    format?: string;
    fields: FieldFormat[];
    location?: string;
}

export interface FieldFormat {
    name: string;
}

export enum ExportJobStatus {
    pending = 'pending',
    completed = 'completed'
}
