import {Observable, Subscription} from 'rxjs';
import {delay} from 'rxjs/operators';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import {R_BREAKPOINTS_ALIASES, WithMediaComponent} from '@clients/shared/layout-grid';
import {or} from '@clients/shared/utilities';
import {ConfirmDialogService} from '@clients/revel/confirm-dialog';
import {IAppState} from '../../+state/app/IAppState';
import {ConnectFacade} from '../../+state/connect.facade';
import {IConnectTitle} from '../../models/connect-title';
import {mainContentToggle, sideNavToggle} from './sidenav.animations';

@Component({
    selector: 'rc-shell',
    template: `
        <div class="rc-shell">
            <div *ngIf="isMobile" class="rc-app-mobile-header">
                <button mat-icon-button (click)="toggleSideNav()" class="menu">
                    <mat-icon>menu</mat-icon>
                </button>
                <rc-sn-logo [showText]="true"></rc-sn-logo>
            </div>
            <mat-sidenav-container [hasBackdrop]="false">
                <mat-sidenav
                    #rcSideNav
                    [mode]="getMode()"
                    [opened]="getOpened()"
                    class="rc-app-sidenav"
                    [@sideNavToggle]="getSideNavState()"
                >
                    <rc-sn-menu
                        class="rc-app-menu"
                        [expanded]="isNavExpanded"
                        [isMobile]="isMobile"
                        fxFlex
                    ></rc-sn-menu>
                </mat-sidenav>
                <mat-sidenav-content fxLayout="column" class="rc-app-content" [@mainContentToggle]="getSideNavState()">
                    <div
                        fxLayoutAlign="space-between"
                        fxLayout="row"
                        fxLayout.xs="column"
                        fxLayout.sm="column"
                        class="headerContent"
                    >
                        <h1>
                            {{ title.title }} <span class="secondary-title">{{ title.secondaryTitle }}</span>
                        </h1>
                        <rc-company-select></rc-company-select>
                    </div>
                    <div class="mainContent">
                        <router-outlet></router-outlet>
                    </div>
                </mat-sidenav-content>
            </mat-sidenav-container>
        </div>
    `,
    styleUrls: ['./shell.component.scss'],
    animations: [mainContentToggle, sideNavToggle]
})
export class ShellComponent extends WithMediaComponent implements OnInit, OnDestroy {
    public isMobile = true;
    public title: IConnectTitle = {title: 'Revel Connect'};
    public isNavExpanded = false;
    public mode = 'side';
    public $media: Observable<MediaChange[]>;
    public $appData: Observable<IAppState>;
    public appDataSubscription: Subscription;
    public confirmedMigration = localStorage.getItem('confirmedMigration');
    constructor(
        public media: MediaObserver,
        private connectFacade: ConnectFacade,
        private confirmDialogService: ConfirmDialogService
    ) {
        super();
        this.setupMediaWatcher(media, this.handleMediaQueryUpdates.bind(this));
        this.$media = this.media.asObservable();
        this.$appData = this.connectFacade.getAppData();
    }
    public ngOnInit() {
        if (!this.isMobile && this.isNavExpanded) {
            this.connectFacade.toggleNav();
        }
        this.appDataSubscription = this.$appData.pipe(delay(0)).subscribe((appData) => {
            this.title = appData.title;
            this.isNavExpanded = appData.isNavExpanded;
        });
        if (this.connectFacade.getCurrentCompany().data.isMigrating) {
            this.isMigrating();
        }
    }
    public ngOnDestroy() {
        this.appDataSubscription.unsubscribe();
    }
    public toggleSideNav() {
        this.connectFacade.toggleNav();
    }
    public getSideNavState() {
        if (this.isMobile && this.isNavExpanded) {
            return 'mobileFull';
        }
        if (this.isMobile && !this.isNavExpanded) {
            return 'closed';
        }
        return this.isNavExpanded ? 'full' : 'mini';
    }
    public getMode() {
        return this.isMobile ? 'over' : 'side';
    }
    public getOpened() {
        if (!this.isNavExpanded && this.isMobile) {
            return false;
        }
        return true;
    }
    public handleMediaQueryUpdates(changes: MediaChange[]): void {
        this.activeMediaQuery = this.getActiveMediaQuery(changes);
        this.isMobile = this.getIsMobile(this.activeMediaQuery);
        this.connectFacade.setIsMobile(this.isMobile);
    }
    public isMigrating() {
        if (this.confirmedMigration === 'false') {
            const dialogRef = this.confirmDialogService.openDialog({
                heading: 'This website will be upgraded within the next few weeks. ',
                message:
                    'New features will include more flexible search options, improved accessibility, and new branding. You will receive a new user guide prior to the upgrade. Be on the lookout for an email invitation to establish your user account in the new website.',
                okMessage: 'Confirm'
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    localStorage.setItem('confirmedMigration', 'true');
                }
            });
        } else {
            return;
        }
    }
    private getActiveMediaQuery(changes: MediaChange[]) {
        return changes && changes[0] ? changes[0].mqAlias : '';
    }
    private getIsMobile(activeMediaQuery) {
        return or(activeMediaQuery === R_BREAKPOINTS_ALIASES.XS, activeMediaQuery === R_BREAKPOINTS_ALIASES.SM);
    }
}
