import {Subscription} from 'rxjs';

import {Directive, Input, OnDestroy, TemplateRef, ViewContainerRef} from '@angular/core';

import {
    IPermissionAndFeature,
    PermissionAndFeatureAccessService
} from '../../../services/permission-and-feature-access.service';

@Directive({
    selector: '[applyPermissionAndFeature]'
})
export class ApplyPermissionAndFeatureDirective implements OnDestroy {
    public canAccessSubscription: Subscription;

    constructor(
        private service: PermissionAndFeatureAccessService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {}

    @Input()
    // @ts-ignore
    set applyPermissionAndFeature(permissionAndFeature?: IPermissionAndFeature) {
        this.canAccessSubscription = this.service.canAccess(permissionAndFeature).subscribe((isAccessible) => {
            if (isAccessible) {
                this.viewContainer.clear();
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        });
    }

    public ngOnDestroy() {
        if (this.canAccessSubscription) {
            this.canAccessSubscription.unsubscribe();
        }
    }
}
