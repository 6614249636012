// import {NewRelic} from '@clients/shared/error-handling';
import {LoggingConfig, Sumo} from '@clients/shared/logging';

export const loggingConfig: LoggingConfig = {
    providers: [
        // {
        //     name: 'New Relic',
        //     provider: NewRelic,
        //     exclude: [],
        //     processUnhandledExceptions: true
        // },
        {
            name: 'Sumo',
            provider: Sumo,
            processUnhandledExceptions: false,
            processLogs: true,
            include: []
        }
    ]
};
