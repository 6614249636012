<rc-spinner
    [mode]="'indeterminate'"
    [value]="50"
    [backdropEnabled]="true"
    [positionGloballyCenter]="true"
    [displayProgressSpinner]="loading"
></rc-spinner>
<form [formGroup]="formGroup" autocomplete="off">
    <div class="form-content">
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>First Name</mat-label>
            <input
                data-e2e="firstName"
                autocomplete="none"
                matInput
                [readonly]="!isEditable"
                placeholder="First Name"
                formControlName="firstName"
                type="input"
            />
        </mat-form-field>
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>Last Name</mat-label>
            <input
                data-e2e="lastName"
                autocomplete="none"
                matInput
                [readonly]="!isEditable"
                placeholder="Last Name"
                formControlName="lastName"
                type="input"
            />
        </mat-form-field>
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>Email</mat-label>
            <input
                data-e2e="email"
                autocomplete="none"
                matInput
                [readonly]="!isEditable"
                placeholder="agentname@email.com"
                formControlName="email"
                type="input"
            />
        </mat-form-field>
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>External identifier</mat-label>
            <input
                data-e2e="externalIdentifier"
                autocomplete="none"
                matInput
                [readonly]="!isEditable"
                placeholder="External identifier"
                formControlName="externalIdentifier"
                type="input"
            />
        </mat-form-field>
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>Role(s)</mat-label>
            <ng-container *ngIf="isEditable">
                <mat-select class="revel-select" data-e2e="role" formControlName="roles" multiple>
                    <mat-option *ngFor="let roleOption of roleOptions" [value]="roleOption">{{
                        roleOption
                    }}</mat-option>
                </mat-select>
            </ng-container>
            <ng-container *ngIf="!isEditable">
                <input [readonly]="true" data-e2e="role" formControlName="roles" matInput type="input" />
            </ng-container>
        </mat-form-field>
        <div *ngIf="!isNewUser">
            <mat-label class="form-field-label"> Active </mat-label>
            <mat-slide-toggle formControlName="active" data-e2e="active"></mat-slide-toggle>
        </div>
    </div>
    <div>
        <button mat-button color="primary" type="button" (click)="cancel()" data-e2e="user-cancel-btn">
            {{ cancelButtonText }}
        </button>

        <ng-container *ngIf="isEditable">
            <button
                mat-raised-button
                color="primary"
                [disabled]="!formGroup.valid"
                data-e2e="user-submit-btn"
                (click)="submit()"
            >
                Submit
            </button>
        </ng-container>
    </div>
    <div *ngIf="errorMessage" class="user-errors" data-e2e="user-error">
        {{ errorMessage }}
    </div>
    <div *ngIf="successMessage" class="user-success" data-e2e="user-success">
        {{ successMessage }}
    </div>
</form>
