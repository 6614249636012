import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ISignInOption} from '@clients/connect/connect-common';
import {AuthProvider} from '@clients/shared/auth';
import {CustomIcons} from '@clients/shared/custom-icons';

@Component({
    selector: 'rc-forgot-password',
    template: `
        <div class="forgot-password">
            <div class="message" *ngIf="!showSuccess">
                <span>A password reset email will be sent to:</span>
                <span class="user">{{ username }}</span>
            </div>
            <div *ngIf="showSuccess" class="success">
                <mat-icon class="check" [svgIcon]="customIcons.COMPLETE_CHECK"></mat-icon>
                <span
                    >Password reset email sent!<br /><br />
                    If you don't receive the email within the next few minutes, please make sure to check your spam
                    folder.
                </span>
            </div>
            <rc-sign-in-error *ngIf="error" [error]="error"></rc-sign-in-error>
            <rc-sign-in-actions>
                <rc-back-button (backClick)="backClick.emit($event)"></rc-back-button>
                <button
                    *ngIf="!showSuccess"
                    mat-raised-button
                    class="revel-raised-button"
                    color="primary"
                    (click)="resetPassword()"
                    data-e2e="resetPassword"
                >
                    Reset Password
                </button>
            </rc-sign-in-actions>
        </div>
    `,
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
    @Input()
    public username: string;

    @Input()
    public error: string;

    @Input()
    public selectedSignIn: ISignInOption;

    @Output()
    public backClick: EventEmitter<any> = new EventEmitter<any>();

    public showSuccess: boolean;
    public customIcons = CustomIcons;

    constructor(private authProvider: AuthProvider) {}
    public resetPassword() {
        this.authProvider.changePassword({
            connection: this.selectedSignIn.realm,
            email: this.username
        });

        this.showSuccess = true;
    }
}
