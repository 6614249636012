import {IConnectTitle} from '../../models/connect-title';

export interface IAppState {
    title: IConnectTitle;
    isNavExpanded: boolean;
    isMobile: boolean;
    isLoading: boolean;
}

export const initialAppState: IAppState = {
    title: {title: '', secondaryTitle: ''},
    isNavExpanded: false,
    isMobile: false,
    isLoading: false
};
