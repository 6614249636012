<div class="header">
    <h2>CSV upload details:</h2>
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
</div>
<div><label>File name:</label> {{ uploadDetails.fileName }}</div>
<div><label>Upload Type:</label> {{ uploadDetails.operation }}</div>
<div>
    <label>Status:</label>
    <span
        [ngClass]="{complete: uploadDetails.status === 'Complete', processing: uploadDetails.status === 'Processing'}"
    >
        {{ uploadDetails.status }}</span
    >
</div>
<div><label>Rows processed:</label> {{ uploadDetails.processedCount }} of {{ uploadDetails.jobSize }}</div>
<div><label>Date:</label> {{ uploadDetails.createdOn | date : 'short' }}</div>
<div><label>Total Errors and Warnings:</label> {{ errorAndWarningCount }}</div>

<div *ngIf="errors.length" class="errors">
    <ng-container *ngFor="let error of errors">
        <p>{{ getMessage(error) }}</p>
    </ng-container>
</div>
<div *ngIf="warnings.length" class="warnings">
    <ng-container *ngFor="let warning of warnings">
        <p>{{ getMessage(warning) }}</p>
    </ng-container>
</div>
