import {CommonModule} from '@angular/common';
import {ErrorHandler, ModuleWithProviders, NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';

import {LoggingEffects} from './+state/logging.effects';
import {LOGGING_CONFIG, LOGGING_PROVIDERS} from './logging.providers';
import {LoggingConfig} from './models/LoggingConfig.interface';
import {ErrorHandlerService} from './services/error-handler.service';
import {Logger} from './services/logger.service';

@NgModule({
    imports: [CommonModule, EffectsModule.forFeature([LoggingEffects])]
})
export class LoggingModule {
    public static forRoot(config?: LoggingConfig): ModuleWithProviders<LoggingModule> {
        return {
            ngModule: LoggingModule,
            providers: [
                Logger,
                {provide: ErrorHandler, useClass: ErrorHandlerService},
                {provide: LOGGING_CONFIG, useValue: config},
                {provide: LOGGING_PROVIDERS, useValue: config ? config.providers : []}
            ]
        };
    }
}
