import {Component} from '@angular/core';
import {CustomIconService} from '@clients/shared/custom-icons';

@Component({
    selector: 'connect-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'connect';

    constructor(private customIconService: CustomIconService) {
        this.customIconService.registerIcons();
    }
}
