import {Component, Input, OnInit} from '@angular/core';

import {UserExportService} from '../../../services/user-export.service';

@Component({
    selector: 'rc-export-progress',
    template: `
        <div
            class="upload-container"
            *ngIf="(userExportService.userProcessingMessage$ | async) && (userExportService.error$ | async) === false"
        >
            <div class="upload-message">
                <div>{{ userExportService.userProcessingMessage$ | async }}</div>
            </div>
        </div>
        <div *ngIf="userExportService.error$ | async" class="error-container">
            <mat-icon>error_outline</mat-icon>
            <div class="message">{{ userExportService.errorMessage }}</div>
            <div class="close" (click)="userExportService.closeUserProcessingError()">
                <mat-icon>close</mat-icon>
            </div>
        </div>
    `,
    styleUrls: ['./export-progress.component.scss']
})
export class ExportProgressComponent implements OnInit {
    @Input()
    public userExportService: UserExportService;
    public fileName: string;
    constructor() {}

    ngOnInit(): void {
        if (!this.userExportService) {
            throw new Error('FileExportService required');
        }
    }
}
