import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ISignInOption} from '@clients/connect/connect-common';

@Component({
    selector: 'rc-password',
    template: `
        <div class="password" [formGroup]="passwordGroup" (keyup.enter)="submitPassword()">
            <img class="selected-sign-in" [src]="selectedSignIn.logo" [alt]="selectedSignIn.name" />
            <div class="user">
                <span class="user-label">Username</span>
                <span [attr.data-e2e]="'current_user'">{{ username }}</span>
            </div>
            <div class="form">
                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input
                        formControlName="password"
                        data-e2e="password"
                        autocomplete="none"
                        matInput
                        placeholder="Password"
                        type="password"
                    />
                </mat-form-field>
                <rc-sign-in-error [error]="error"></rc-sign-in-error>
            </div>
            <div class="forgot-link">
                <button
                    mat-flat-button
                    (click)="forgotClick.emit()"
                    data-e2e="forgot-password"
                    class="revel-flat-button"
                >
                    Forgot Your Password?
                </button>
            </div>
            <rc-sign-in-actions>
                <rc-back-button (backClick)="backClick.emit($event)"></rc-back-button>
                <button
                    mat-raised-button
                    class="revel-raised-button"
                    color="primary"
                    (click)="submitPassword()"
                    data-e2e="sign-in"
                >
                    Sign In
                </button>
            </rc-sign-in-actions>
        </div>
    `,
    styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnChanges, OnInit {
    @Input()
    public username: string;

    @Input()
    public error: string;

    @Input()
    public selectedSignIn: ISignInOption;

    @Output()
    public backClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public forgotClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public password: EventEmitter<string> = new EventEmitter<string>();

    public passwordGroup: FormGroup;

    public customMessage: string;

    constructor() {
        this.passwordGroup = new FormGroup({
            password: new FormControl('', [Validators.required])
        });
    }

    public get passwordControl(): FormControl {
        return this.passwordGroup.get('password') as FormControl;
    }

    public ngOnInit() {
        if (this.selectedSignIn && this.selectedSignIn.hasCustomMessage) {
            this.error = this.selectedSignIn.customMessage;
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.error && changes.error.currentValue) {
            this.passwordControl.setErrors({incorrect: true});
            this.passwordGroup.updateValueAndValidity();
        }
    }
    public submitPassword() {
        if (this.passwordGroup.valid) {
            this.password.emit(this.passwordControl.value);
        }
    }
}
