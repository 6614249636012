import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {CONNECT_ROUTES_ENUM} from '../../../enum/routes.enum';

@Component({
    selector: 'rc-redirect',
    template: ``,
    styles: []
})
/***********************************************
 * This component is meant to catch redirects from auth0 that use /login.
 */
export class RedirectComponent implements OnInit {
    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        const queryParams = {
            ...this.route.snapshot.queryParams
        };

        this.router.navigate([CONNECT_ROUTES_ENUM.signIn], {queryParams: queryParams});
        return;
    }
}
