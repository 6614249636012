import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

import {ConfirmDialogComponent, IConfirmDialogData} from '../components/confirm-dialog/confirm-dialog.component';

@Injectable()
export class ConfirmDialogService {
    constructor(private dialog: MatDialog) {}
    public openDialog(
        data: IConfirmDialogData,
        config?: MatDialogConfig
    ): MatDialogRef<ConfirmDialogComponent, boolean> {
        const options = config
            ? config
            : {
                  width: 'auto',
                  height: 'auto',
                  data: data,
                  panelClass: 'revel-dialog-panel'
              };

        return this.dialog.open(ConfirmDialogComponent, options);
    }
}
