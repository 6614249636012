import {Action} from '@clients/shared/models';

import {IConnectTitle} from '../../models/connect-title';
import {ProfileActionTypes} from '../profile/profile.actions';
import {ConnectAppActionTypes} from './app.actions';
import {IAppState, initialAppState} from './IAppState';

export function appReducer(state = initialAppState, action: Action<any>): IAppState {
    switch (action.type) {
        case ConnectAppActionTypes.NAV_TOGGLED:
            return navToggledHandler(state);
        case ConnectAppActionTypes.MOBILE_VIEW_CHANGED:
            return mobileViewChangedHandler(state, action.payload);
        case ConnectAppActionTypes.SET_TITLE:
            return setTitleHandler(state, action.payload);
        case ProfileActionTypes.LOAD_PROFILE:
            return handleLoadProfile(state);
        case ProfileActionTypes.PROFILE_LOADED:
            return handleProfileLoaded(state);
        default:
            return state;
    }
}

function navToggledHandler(state: IAppState) {
    return {
        ...state,
        isNavExpanded: !state.isNavExpanded
    };
}

function mobileViewChangedHandler(state: IAppState, isMobile: boolean) {
    return {
        ...state,
        isMobile: isMobile
    };
}

function setTitleHandler(state: IAppState, title: IConnectTitle) {
    return {
        ...state,
        title: {...title}
    };
}

function handleLoadProfile(state: IAppState) {
    return {
        ...state,
        isLoading: true
    };
}

function handleProfileLoaded(state: IAppState) {
    return {
        ...state,
        isLoading: false
    };
}
