export enum RegularIcons {
    CLOSE = 'regular-01-Interface Essential/33-Form-Validation/close',
    MESSAGES_PEOPLE_MESSAGES_PEOPLE_USER_BUBBLE_CIRCLE = 'regular-21-Messages-Chat-Smileys/01-Messages-People/messages-people-user-bubble-circle',
    MULTIMEDIA_CONTROLS_BUTTON_NEXT = 'regular-01-Interface Essential/42-Multimedia-Controls/button-previous', // these are labeled wrong
    MULTIMEDIA_CONTROLS_BUTTON_PAUSE = 'regular-01-Interface Essential/42-Multimedia-Controls/button-pause',
    MULTIMEDIA_CONTROLS_BUTTON_PLAY = 'regular-01-Interface Essential/42-Multimedia-Controls/button-play',
    MULTIMEDIA_CONTROLS_BUTTON_PREVIOUS = 'regular-01-Interface Essential/42-Multimedia-Controls/button-next', // these are labeled wrong
    PHONE_ACTIONS_PHONE_ACTIONS_FORWARD_1 = 'regular-20-Phones-Mobile-Devices/03-Phone-Actions/phone-actions-forward-1',
    PHONE_ACTIONS_PHONE_ACTIONS_QUESTION_1 = 'regular-20-Phones-Mobile-Devices/03-Phone-Actions/phone-actions-question-1',
    PHONE_ACTIONS_PHONE_ACTIONS_RINGING = 'regular-20-Phones-Mobile-Devices/03-Phone-Actions/phone-actions-ringing',
    PHONE_PHONE_DIAL = 'regular-20-Phones-Mobile-Devices/01-Phone/phone-dial',
    PLAYLISTS_PLAYLIST_SONGS = 'regular-14-Music-Audio/16-Playlists/playlist-songs',
    SHARE_MEGAPHONE = 'regular-01-Interface Essential/28-Share/megaphone',
    VOLUME_CONTROLS_FULL = 'regular-14-Music-Audio/17-Volume-Controls/volume-control-full',
    VOLUME_CONTROLS_LOW = 'regular-14-Music-Audio/17-Volume-Controls/volume-control-low',
    VOLUME_CONTROLS_MEDIUM = 'regular-14-Music-Audio/17-Volume-Controls/volume-control-medium',
    ACTIONS_ADD = '17-Users/05-Geometric-Full Body-Single User Actions-Neutral/single-neutral-actions-add'
}
