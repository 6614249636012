import {combineLatest, Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {AuthProvider} from '@clients/shared/auth';
import {RouterNavigatePayload} from '@clients/shared/models';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {CONNECT_ROUTES_ENUM} from '../../enum/routes.enum';
import {ConnectApiService} from '../../services/connect-api.service';
import {TokenParserService} from '../../services/token-parser.service';
import {RouterNavigateAction} from '../app/app.actions';
import {initialProfileState, IProfileState} from './IProfileState';
import {ProfileActionTypes, ProfileLoadedAction} from './profile.actions';

@Injectable()
export class ProfileEffects {
    loadProfile = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfileActionTypes.LOAD_PROFILE),
            mergeMap(() => this.loadProfileHandler())
        )
    );

    changeCurrentCompany = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfileActionTypes.CHANGE_CURRENT_COMPANY),
            map(
                () =>
                    new RouterNavigateAction({
                        route: CONNECT_ROUTES_ENUM.dashboard,
                        options: null
                    } as RouterNavigatePayload)
            )
        )
    );

    constructor(
        private actions$: Actions,
        private connectApi: ConnectApiService,
        private authProvider: AuthProvider,
        private tokenParser: TokenParserService
    ) {}

    private loadProfileHandler(): Observable<ProfileLoadedAction> {
        const profile: IProfileState = {
            ...initialProfileState
        };
        const token = this.authProvider.getToken();
        profile.companyId = this.tokenParser.getCompanyId(token);
        profile.userId = this.tokenParser.getUserId(token);
        profile.roles = this.tokenParser.getRoles(token);
        profile.permissions = this.tokenParser.getPermissions(token);

        return combineLatest(this.connectApi.getCompanies(), this.connectApi.getCompanyDetails(profile.companyId)).pipe(
            map((responses) => {
                profile.companies = responses[0];
                profile.currentCompany = responses[1];
                return new ProfileLoadedAction(profile);
            })
        );
    }
}
