import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthProvider} from '../../services/auth.provider';

@Component({
    selector: 'clients-callbacks-auth0',
    template: ''
})
export class CallbacksAuth0Component implements OnInit {
    constructor(private route: ActivatedRoute, private authProvider: AuthProvider) {}

    ngOnInit() {
        this.parseAuth0Response();
    }

    private parseAuth0Response() {
        this.authProvider.handleAuthentication();
    }
}
