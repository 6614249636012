// todo change to use JobStatus enum
export enum UploadStatus {
    Processing = 'Processing',
    Complete = 'Complete'
}

export enum UploadEventType {
    Created = 'Created',
    Deleted = 'Deleted',
    AlreadyExists = 'AlreadyExists',
    AlreadyExistsReactivated = 'AlreadyExistsReactivated',
    AlreadyExistsWithDifferentData = 'AlreadyExistsWithDifferentData',
    AlreadyExistsWithDifferentDataReactivated = 'AlreadyExistsWithDifferentDataReactivated',
    DoesNotExist = 'DoesNotExist',
    ErrorUnknown = 'ErrorUnknown',
    ErrorAuth0 = 'ErrorAuth0'
}

export enum UploadOperation {
    Import = 'Import',
    Delete = 'Delete'
}
