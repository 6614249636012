import {orderBy as _orderBy} from 'lodash';
import {BehaviorSubject, Observable, of, zip} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';

import {DataSource} from '@angular/cdk/collections';

import {IUploadSummary} from '../../../models/upload';
import {ConnectApiService} from '../../../services/connect-api.service';
import {UploadOperation} from '../../../enum/uploads.enum';

export class UploadDataSource implements DataSource<IUploadSummary> {
    private uploadSubject = new BehaviorSubject<IUploadSummary[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private errorSubject = new BehaviorSubject<boolean>(false);
    private uploadCountSubject = new BehaviorSubject<number>(0);
    public loading$ = this.loadingSubject.asObservable(); //eslint-disable-line
    public error$ = this.errorSubject.asObservable(); //eslint-disable-line
    public uploadCount$ = this.uploadCountSubject.asObservable(); //eslint-disable-line

    constructor(private connectApi: ConnectApiService) {}

    public connect(): Observable<IUploadSummary[]> {
        return this.uploadSubject.asObservable();
    }

    public disconnect(): void {
        this.uploadSubject.complete();
        this.loadingSubject.complete();
        this.errorSubject.complete();
        this.uploadCountSubject.complete();
    }

    public loadUploads(companyId): void {
        this.loadingSubject.next(true);
        this.errorSubject.next(false);

        // retrieve uploads for both import and delete operations and populate each result object with which type of operation it relates to
        zip(
            this.connectApi
                .getImportUploads(companyId)
                .pipe(map((uploads) => uploads.map((upload) => ({...upload, operation: UploadOperation.Import})))),
            this.connectApi
                .getDeleteUploads(companyId)
                .pipe(map((uploads) => uploads.map((upload) => ({...upload, operation: UploadOperation.Delete}))))
        )
            // combine both imports and deletes into a single array
            .pipe(map((uploads) => uploads[0].concat(uploads[1])))
            // handle any errors
            .pipe(
                catchError(() => {
                    this.uploadCountSubject.next(0);
                    this.errorSubject.next(true);
                    return of([]);
                }),
                finalize(() => {
                    this.loadingSubject.next(false);
                })
            )
            // process single array of result objects
            .subscribe((uploadsResponse: IUploadSummary[]) => {
                const sorted = _orderBy(uploadsResponse, ['createdOn', 'status'], ['desc', 'asc']);
                this.uploadSubject.next(sorted);
                this.uploadCountSubject.next(uploadsResponse.length);
            });
    }
}
