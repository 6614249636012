import {debounceTime, takeUntil} from 'rxjs/operators';

import {Directive} from '@angular/core';
import {AuthProvider} from '@clients/shared/auth';
import {UntilComponentDestroyed} from '@clients/shared/common-forms';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';

import {ConfirmDialogService} from '../../services/confirm-dialog.service';

const idleTime = 60 * 18;
const timeoutSeconds = 60 * 2;
const renewWindow = 60 * 20;

@Directive()
export abstract class IdleDialog extends UntilComponentDestroyed {
    public color = 'primary';
    public spinnerMode = 'indeterminate';
    public value = 50;
    public displayProgressSpinner = false;

    constructor(
        private idle: Idle,
        private confirmDialogService: ConfirmDialogService,
        public authProvider: AuthProvider
    ) {
        super();

        // use ng2-idle to monitor user activity.
        // ng2-idle-example https://github.com/moribvndvs/ng2-idle-example

        this.checkExpirationAndRenew();

        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.setIdle(idleTime); // how long can they be inactive before considered idle, in seconds
        this.idle.setTimeout(timeoutSeconds); // how long can they be idle before considered timed out, in seconds

        this.idle.onIdleStart.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.idle.setInterrupts([]); // disable interrupts to force user to take action
            const dialogRef = this.confirmDialogService.openDialog({
                heading: 'Session Expiring',
                message: `Your session is about to expire. You will be signed out in 2 minutes due to inactivity`,
                okMessage: 'Stay signed in',
                cancelMessage: 'Sign out now'
            });

            let dialogActionTaken = false;

            dialogRef.afterClosed().subscribe((result) => {
                dialogActionTaken = true;
                if (result) {
                    // reset
                    this.resetSessionAndIdle();
                    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
                    this.idle.watch();
                } else {
                    //sign them out.
                    this.initiateSignOut();
                }
            });

            // force a signout if an action is not taken. This is redundant and heavy handed, but ng-idle seems to miss this.
            setTimeout(() => {
                if (!dialogActionTaken) {
                    this.initiateSignOut();
                }
            }, timeoutSeconds * 1000);
        });

        this.idle.onIdleEnd.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.checkExpirationAndRenew();
        });

        // keeps the user signed in when they are not idle.
        idle.onInterrupt.pipe(takeUntil(this.destroyed$), debounceTime(2000)).subscribe(() => {
            this.checkExpirationAndRenew();
        });

        idle.onTimeout.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            // user timed out, sign them out.
            this.initiateSignOut();
        });

        this.idle.watch();
    }

    /* Override to change sign out options */
    public initiateSignOut() {
        this.authProvider.revokeSession();
        return;
    }

    private resetSessionAndIdle() {
        let secondsRemaining = this.calculateSecondsRemaining();
        if (secondsRemaining <= 0) {
            this.initiateSignOut();
            return;
        }

        this.authProvider
            .renewSession()
            .then(() => {
                //set idle
                secondsRemaining = this.calculateSecondsRemaining();
                this.idle.setIdle(idleTime);
            })
            .catch(() => {
                this.initiateSignOut();
            });
    }

    private checkExpirationAndRenew() {
        const secondsRemaining = this.calculateSecondsRemaining();

        if (secondsRemaining <= 0) {
            this.initiateSignOut();
        }

        if (secondsRemaining - timeoutSeconds < renewWindow) {
            this.resetSessionAndIdle();
        }
    }

    private calculateSecondsRemaining(): number {
        const expirationDate = this.authProvider.getTokenExpirationDate();
        if (!expirationDate) {
            return 0;
        }
        const expiration = expirationDate.getTime();

        const now = Date.now();

        const millisecondsLeft = expiration - now;

        return millisecondsLeft / 1000;
    }
}
