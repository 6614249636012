import {filter as _filter} from 'lodash';

import {UploadEventType} from '../enum/uploads.enum';
import {IUploadEvent} from '../models/upload';

export const getErrors = (events: IUploadEvent[]): IUploadEvent[] =>
    _filter(events, (e) => e.type === UploadEventType.ErrorAuth0 || e.type === UploadEventType.ErrorUnknown);

export const getWarnings = (events: IUploadEvent[]): IUploadEvent[] =>
    _filter(events, (e) =>
        [
            UploadEventType.AlreadyExists,
            UploadEventType.AlreadyExistsReactivated,
            UploadEventType.AlreadyExistsWithDifferentData,
            UploadEventType.AlreadyExistsWithDifferentDataReactivated
        ].includes(e.type)
    );
