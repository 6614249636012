import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CookieModule} from 'ngx-cookie';
import {PersistenceService} from './services/persistence.service';

@NgModule({
    imports: [CommonModule, CookieModule],
    providers: [PersistenceService]
})
export class PersistenceModule {}
