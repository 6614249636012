import {filter as _filter} from 'lodash';
import moment from 'moment';

import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

import {IState} from '../../../models/states';

export class CustomValidators {
    static dateMax(maxDate: string, format: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value == null) {
                return null;
            }

            const controlDate = moment(control.value, format);

            if (!controlDate.isValid()) {
                return null;
            }

            const validationDate = moment(maxDate, format);

            return controlDate.isBefore(validationDate)
                ? null
                : {
                      'date-max': {
                          'date-max': validationDate.format(format),
                          actual: controlDate.format(format)
                      }
                  };
        };
    }

    static dateMin(minDate: string, format: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value == null) {
                return null;
            }

            const controlDate = moment(control.value, format);

            if (!controlDate.isValid()) {
                return null;
            }

            const validationDate = moment(minDate, format);

            return controlDate.isAfter(validationDate)
                ? null
                : {
                      'date-min': {
                          'date-min': validationDate.format(format),
                          actual: controlDate.format(format)
                      }
                  };
        };
    }

    static date(format: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }

            const controlDate = moment(control.value, format);

            if (!controlDate.isValid()) {
                return {date: 'Date is not valid'};
            }
            return null;
        };
    }

    static state(states: IState[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const matchingState = _filter(states, (state) => state.name === control.value);
            if (!matchingState.length) {
                return {state: 'State is not valid'};
            }
            return null;
        };
    }
}
