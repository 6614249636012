import {get as _get} from 'lodash';

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface IConfirmDialogData {
    heading?: string;
    message?: string;
    okMessage?: string;
    cancelMessage?: string;
}

@Component({
    selector: 'r-confirm-dialog',
    template: `
        <div class="confirm-dialog">
            <h3>{{ data.heading }}</h3>
            <div *ngIf="message">{{ data.message }}</div>
            <div class="actions">
                <button mat-flat-button class="revel-flat-button" data-e2e="confirmCancelButton" (click)="onCancel()">
                    {{ cancelMessage }}
                </button>
                <button
                    mat-raised-button
                    class="revel-raised-button"
                    color="primary"
                    data-e2e="confirmOkButton"
                    (click)="onOk()"
                >
                    {{ okMessage }}
                </button>
            </div>
        </div>
    `,
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    public heading;
    public message;
    public okMessage;
    public cancelMessage;
    constructor(
        private dialogRef: MatDialogRef<ConfirmDialogComponent, boolean>,
        @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData
    ) {
        this.okMessage = _get(data, 'okMessage', 'OK');
        this.cancelMessage = _get(data, 'cancelMessage', 'CANCEL');
        this.heading = _get(data, 'heading', 'Are you sure?');
        this.message = _get(data, 'message', null);
    }

    onOk() {
        this.dialogRef.close(true);
    }

    onCancel() {
        this.dialogRef.close(false);
    }
}
